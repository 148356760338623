import { assetTypeBuilding } from './type';

export const buildingFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Type',
    slug: 'type',
    choices: [
      { name: assetTypeBuilding.Armory, key: assetTypeBuilding.Armory },
      { name: assetTypeBuilding.Workshop, key: assetTypeBuilding.Workshop },
      {
        name: assetTypeBuilding.SiknarExtractor,
        key: assetTypeBuilding.SiknarExtractor,
      },
      {
        name: assetTypeBuilding.AlkarExtractor,
        key: assetTypeBuilding.AlkarExtractor,
      },
      {
        name: assetTypeBuilding.ElectriumExtractor,
        key: assetTypeBuilding.ElectriumExtractor,
      },
      {
        name: assetTypeBuilding.SteelvarExtractor,
        key: assetTypeBuilding.SteelvarExtractor,
      },
    ],
  },
  {
    name: 'Status',
    slug: 'status',
    choices: [
      { name: 'Available', key: 'available' },
      { name: 'Leveling', key: 'leveling' },
      { name: 'Crafting', key: 'crafting' },
      { name: 'Collaborative Crafting', key: 'collab' },
    ],
  },
];

export const collaborateFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Type',
    slug: 'type',
    choices: [
      { name: assetTypeBuilding.Armory, key: assetTypeBuilding.Armory },
      { name: assetTypeBuilding.Workshop, key: assetTypeBuilding.Workshop },
    ],
  },
];

export const extractorUpgradeFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Type',
    slug: 'type',
    choices: [
      {
        name: assetTypeBuilding.SiknarExtractor,
        key: assetTypeBuilding.SiknarExtractor,
      },
      {
        name: assetTypeBuilding.AlkarExtractor,
        key: assetTypeBuilding.AlkarExtractor,
      },
      {
        name: assetTypeBuilding.ElectriumExtractor,
        key: assetTypeBuilding.ElectriumExtractor,
      },
      {
        name: assetTypeBuilding.SteelvarExtractor,
        key: assetTypeBuilding.SteelvarExtractor,
      },
    ],
  },
];

export const landFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Planet',
    slug: 'planet',
    choices: [
      { name: 'Luxion', key: 'luxion' },
      { name: 'Naya', key: 'naya' },
      { name: 'Kryo', key: 'kryo' },
    ],
  },
  {
    name: 'Status',
    slug: 'status',
    choices: [
      { name: 'Available', key: 'available' },
      { name: 'Leveling', key: 'leveling' },
      { name: 'Crafting', key: 'crafting' },
    ],
  },
];

export const weaponsFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Type',
    slug: 'type',
    choices: [
      { name: 'Kryo Gun', key: 'Kryo Gun' },
      { name: 'Luxion Gun', key: 'Luxion Gun' },
      { name: 'Naya Gun', key: 'Naya Gun' },
    ],
  },
  {
    name: 'Status',
    slug: 'status',
    choices: [
      { name: 'Available', key: 'available' },
      { name: 'Leveling', key: 'leveling' },
    ],
  },
];

export const weaponsUpgradeFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Type',
    slug: 'type',
    choices: [
      { name: 'Kryo Gun', key: 'Kryo Gun' },
      { name: 'Luxion Gun', key: 'Luxion Gun' },
      { name: 'Naya Gun', key: 'Naya Gun' },
    ],
  },
];

export const robotsFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Planet',
    slug: 'planet',
    choices: [
      { name: 'Luxion', key: 'luxion' },
      { name: 'Naya', key: 'naya' },
      { name: 'Kryo', key: 'kryo' },
    ],
  },
];

export const marketplaceFilters = [
  {
    name: 'Level',
    slug: 'level',
    choices: [
      { name: 1, key: '1' },
      { name: 2, key: '2' },
      { name: 3, key: '3' },
      { name: 4, key: '4' },
      { name: 5, key: '5' },
    ],
  },
  {
    name: 'Type',
    slug: 'type',
    choices: [
      { name: assetTypeBuilding.Armory, key: assetTypeBuilding.Armory },
      { name: assetTypeBuilding.Workshop, key: assetTypeBuilding.Workshop },
      {
        name: assetTypeBuilding.SiknarExtractor,
        key: assetTypeBuilding.SiknarExtractor,
      },
      {
        name: assetTypeBuilding.AlkarExtractor,
        key: assetTypeBuilding.AlkarExtractor,
      },
      {
        name: assetTypeBuilding.ElectriumExtractor,
        key: assetTypeBuilding.ElectriumExtractor,
      },
      {
        name: assetTypeBuilding.SteelvarExtractor,
        key: assetTypeBuilding.SteelvarExtractor,
      },
    ],
  },
];
