export const chainsWalletToLink = [
  {
    key: 1,
    chain: 'evm',
  },
  {
    key: 2,
    chain: 'solana',
  },
  {
    key: 3,
    chain: 'ultra',
  },
  {
    key: 4,
    chain: 'wax',
  },
];
