import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import './assets/fonts/Montserrat-Regular.ttf';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import AppRoutes from './routes';
import theme from './themes/muiTheme';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Route component={AppRoutes} />
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
