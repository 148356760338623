import React from 'react';
import { makeStyles } from '@material-ui/core';
import Proptypes from 'prop-types';

import loadingLogo from '../../../assets/icons/loading_2.svg';

export default function Loader({ size }) {
  const classes = useStyle({ size });
  return <img src={loadingLogo} alt="loader" className={classes.loader} />;
}

const useStyle = makeStyles(() => ({
  '@keyframes rotate': {
    '100%': { transform: 'rotate(360deg)' },
  },
  loader: {
    animation: '$rotate 1s linear infinite',
    width: ({ size }) => size || 20,
    height: ({ size }) => size || 20,
  },
}));

Loader.propTypes = {
  size: Proptypes.number,
};

Loader.defaultProps = {
  size: 20,
};
