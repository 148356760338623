import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import ButtonPerso from '../ButtonPerso/button-perso';

import withModalCenter from './modal-center.style';

function ModalCenter({
  classes,
  className,
  classNameChildren,
  classNamePaper,
  open,
  onClose,
  titleTop,
  children,
  textConfirm,
  onClickConfirm,
}) {
  return (
    <Modal
      className={cx(classes.modal, className)}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={cx(classes.paper, classNamePaper)}>
          {titleTop && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              className={classes.top}
            >
              <Typography
                variant="h4"
                color="textPrimary"
                className={classes.titleTop}
              >
                {titleTop}
              </Typography>
              <Box ml={4} />
              {onClose && (
                <IconButton aria-label="close" onClick={onClose}>
                  <CloseIcon style={{ color: 'black' }} />
                </IconButton>
              )}
            </Box>
          )}
          <Box p={4} mb={1} className={classNameChildren}>
            {children}
          </Box>

          {onClickConfirm && (
            <div className={classes.footer}>
              <ButtonPerso shrink onClick={onClickConfirm}>
                {textConfirm}
              </ButtonPerso>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

ModalCenter.propTypes = {
  classes: Proptypes.objectOf(Proptypes.string).isRequired,
  open: Proptypes.bool,
  button: Proptypes.bool,
  onClose: Proptypes.func,
  onClickConfirm: Proptypes.func,
  titleTop: Proptypes.string,
  textConfirm: Proptypes.string,
  className: Proptypes.string,
  classNameChildren: Proptypes.string,
  classNamePaper: Proptypes.string,
  children: Proptypes.node,
};

export default withModalCenter(ModalCenter);
