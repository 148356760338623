export const sortArrayOfObj = (type) => (a, b) => {
  if (a[type] > b[type]) return 1;
  if (a[type] < b[type]) return -1;
  return 0;
};

export const isNotEmptyArray = (array) => {
  let result = false;
  if (Array.isArray(array)) {
    if (array.length > 0) result = true;
  }
  return result;
};
