import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';

const initialState = {
  actions: [],
};

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        actions: [
          ...state.actions,
          { actionName: action.actionName, tokenId: action.tokenId },
        ],
      };

    case STOP_LOADING:
      return {
        ...state,
        actions: state.actions.filter(
          (load) => load.actionName !== action.actionName,
        ),
      };

    default:
      return state;
  }
}
