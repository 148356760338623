import axios from 'axios';
import {
  FETCH_VALOR_CREST,
  FETCH_VALOR_CREST_FULFILLED,
  FETCH_VALOR_CREST_ERROR,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes';

import { tokenConfig } from '../../utils/configFetch';

export const getValorCrests = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_VALOR_CREST,
    type: START_LOADING,
  });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/valorcrests`,
      tokenConfig(),
    );
    dispatch({
      type: FETCH_VALOR_CREST_FULFILLED,
      items: res.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_VALOR_CREST_ERROR,
    });
  }
  dispatch({
    actionName: FETCH_VALOR_CREST,
    type: STOP_LOADING,
  });
};
