import { combineReducers } from 'redux';
import { LOGOUT } from '../constants/actionTypes';
import avatarReducer from './avatarReducer';

import errorReducer from './errorReducer';
import landReducer from './landReducer';
import leaderboardReducer from './leaderboardReducer';
import loadingReducer from './loadingReducer';
import modalsReducer from './modalsReducer';
import userReducer from './userReducer';
import successReducer from './successReducer';
import valorCrestReducer from './valorCrestReducer';

const appReducer = combineReducers({
  error: errorReducer,
  success: successReducer,
  user: userReducer,
  leaderboard: leaderboardReducer,
  // building: buildingReducer,
  land: landReducer,
  // rule: ruleReducer,
  avatar: avatarReducer,
  valorCrest: valorCrestReducer,
  // weapon: weaponReducer,
  // marketplace: marketplaceReducer,
  // collaborative: collaborativeReducer,
  modal: modalsReducer,
  loading: loadingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(
      { ethers: { ...state.ethers }, rule: { ...state.rule } },
      action,
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
