import React, { useEffect, useMemo } from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import MailIcon from '@material-ui/icons/Mail';
import { useHistory } from 'react-router-dom';
import BlueBox from '../../_atoms/BlueBox/blueBox';

import { sizeMobileForAccountPage } from '../../../constants/size';
import { getUserByToken, recoverPassword } from '../../../redux/actions/user';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

export default function RecoverPassword({ match }) {
  const classes = useStyle({});
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [email, setEmail] = React.useState('');

  useEffect(() => {
    const { emailToken } = match.params;
    dispatch(
      getUserByToken(
        emailToken,
        (res) => {
          if (res?.email) {
            setEmail(res.email);
          } else {
            history.push('/');
          }
        },
        () => {
          history.push('/');
        },
      ),
    );
  }, []);

  const handleResetPassword = () => {
    const { emailToken } = match.params;

    dispatch(
      recoverPassword(
        {
          emailToken,
          password,
          confirmPassword,
        },
        () => {
          history.push('/');
        },
      ),
    );
  };

  const noDisabledSaveChanges = useMemo(
    () => !!password && !!confirmPassword && email,
    [password, confirmPassword, email],
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className={classes.root}>
        <BlueBox className={classes.containerContent}>
          <Box>
            <Box className={classes.headerContent}>
              <Typography variant="h1">Reset Password</Typography>
            </Box>
          </Box>
          <Box className={classes.wrapperTextFields}>
            <TextField
              size="small"
              label="Email"
              value={email}
              disabled
              className={classes.textField}
              placeholder="Email"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.icon}>
                    <MailIcon />
                  </InputAdornment>
                ),
                classes: { input: classes.input },
              }}
            />
            <TextField
              size="small"
              label="Password"
              autoComplete="new-password"
              value={password}
              onChange={handleChangePassword}
              placeholder="****************"
              required
              className={classes.textField}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.icon} position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" className={classes.icon}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: { input: classes.input },
              }}
            />
            <TextField
              size="small"
              type={showPassword ? 'text' : 'password'}
              label="Confirm Password"
              autoComplete="confirm-new-password"
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              placeholder="****************"
              required
              className={classes.textField}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.icon} position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" className={classes.icon}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: { input: classes.input },
              }}
            />
          </Box>
          <ButtonPerso
            className={cx(classes.btnCreateAccount, classes.btnSave)}
            disabled={!noDisabledSaveChanges}
            onClick={handleResetPassword}
          >
            Save Changes
          </ButtonPerso>
        </BlueBox>
      </div>
    </>
  );
}

RecoverPassword.propTypes = {
  match: Proptypes.shape({
    isExact: Proptypes.bool,
    params: Proptypes.object,
    path: Proptypes.string,
    url: Proptypes.string,
  }),
};

const useStyle = makeStyles(({ breakpoints }) => ({
  root: {
    overflowX: 'hidden',
    fontSize: '1.4rem',
    color: 'white',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      padding: '1rem',
    },
  },
  containerContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: '45rem',
    margin: 'auto',
    padding: '5rem',
    maxWidth: '70rem',
    gap: '2rem',
    '& h2': {
      fontFamily: 'Akira',
      fontWeight: 600,
      textShadow: '0px 3px 9px rgba(0, 0, 0, 0.58)',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      whiteSpace: 'pre-wrap',
      fontSize: '7rem',
      color: 'white',
      [breakpoints.down('lg')]: {
        fontSize: '6rem',
      },
      [breakpoints.down('md')]: {
        fontSize: '4.8rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '3rem',
        textAlign: 'center',
      },
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      padding: '3rem',
    },
    '@media (max-height: 800px)': {
      marginTop: '4rem',
    },
  },
  headerContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      fontSize: '4rem',
      fontFamily: 'Akira',
      fontWeight: 600,
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      [breakpoints.down(sizeMobileForAccountPage)]: {
        fontSize: '2.8rem',
      },
    },
  },
  btnEdit: {
    position: 'relative',
    right: '10px',
    bottom: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnAvatar: {
    background: 'none',
    border: 'none',
    transition: '0.2s all ease-in-out',
    '& img': {
      width: '10rem',
      height: '10rem',
      borderRadius: '50%',
      [breakpoints.down(sizeMobileForAccountPage)]: {
        width: '80%',
      },
    },
    '& svg': {
      fill: '#ffffff',
    },
  },
  bodyContent: {
    marginTop: '2rem',
  },
  form: {},
  wrapperTextFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      marginTop: '2rem',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: '1.4rem',
    marginLeft: '1rem',
  },
  textField: {
    width: '100%',
    '& input': {
      paddingTop: '17.5px',
      position: 'relative',
      zIndex: 1,
    },
    '& label': {
      color: '#bbb7b7 !important',
    },
    '& .Mui-focused': {
      color: 'white',
    },
    '& fieldset': {
      borderRadius: '3rem',
      // background: "#0e1e2f",
      border: '1px solid #18A0FB !important',
    },
  },
  textFieldDisabled: {
    '& fieldset': {
      background: '#1B0F1D',
      borderColor: '#FC0001 !important',
    },
  },
  input: {
    color: 'white',
  },
  icon: {
    '& svg': {
      fill: '#393f4c',
    },
  },
  inputUsername: {
    left: '5rem',
  },
  inputEmail: {
    width: 'calc(100% - 133px)',
  },
  btnCreateAccount: {
    background:
      'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    width: '22rem',
    border: 'none',
    '& span': {
      textTransform: 'initial',
    },

    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  btnModal: {
    width: '100%',
  },
  wrapperUsername: {
    position: 'relative',
  },
  wrapperEmail: {
    position: 'relative',
  },
  chipUsername: {
    position: 'absolute',
    top: '10px',
    height: '45px',
    width: '5rem',
    background: '#071021',
    border: '1px solid #18A0FB',
    zIndex: 2,
    color: 'white',
    fontWeight: 700,
    fontSize: '1.4rem',
  },
  chip: {
    color: 'white',
    fontWeight: 700,
    fontSize: '1.4rem',
    height: '4rem',
    width: '185px',
    borderRadius: '2rem',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  chipEmailNotVerified: {
    background: '#1B0F1D',
    width: 'auto',
    border: '1px solid #FC0001',
    height: '45px',
    position: 'absolute',
    right: 0,
  },
  chipEmailVerified: {
    background: '#0F1D15',
    border: '1px solid #00FC0A',
  },
  wrapperBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      flexDirection: 'column',
      marginTop: '2rem',
      gap: '2rem',
    },
  },
  btnWalletConnect: {
    border: '1px solid #18A0FB',
    background: 'rgba(15, 190, 255, 0.078)',
    width: '185px',
    transition: '0.2s all ease-in-out',
    height: '4rem',
    '&:hover': {
      color: '#18A0FB',
      background: 'rgba(15, 190, 255, 0.078)',
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  chipWallet: {
    background: '#0F1D15',
    border: '1px solid #00FC0A',
    transition: '0.2s all ease-in-out',

    '&:hover': {
      background: '#0F1D15',
      '& span': {
        color: '#18A0FB',
      },
    },
  },
  chipUpdate: {
    background: '#071120',
    border: '1px solid #18A0FB',
    '&:hover': {
      backgroundColor: 'rgb(206 206 206 / 10%)',
    },
    '&:focus': {
      background: '#071120',
    },
  },
  btnUpdate: {
    boxShadow: 'none',
    width: '185px',
    '& span': {
      fontSize: '1.6rem',
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  btnSave: {
    margin: '0 auto',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      marginTop: '2rem',
    },
  },
}));
