import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Box, Typography, makeStyles } from '@material-ui/core';
import iconRocketToken from '../../../assets/icons/fusee-oxyatoken.svg';

import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

export default function ButtonOxyaToken({ className }) {
  const classes = useStyle();

  const handleClickBtnToken = () => {
    window.open('http://www.oxyaorigin.com', '_blank');
  };
  return (
    <ButtonPerso
      className={cx(classes.root, className)}
      onClick={handleClickBtnToken}
      withBorder={false}
    >
      <img width={28} height={49} alt="rocket" src={iconRocketToken} />
      <Box>
        <Typography variant="body1" className={classes.titleBtnToken}>
          Website
        </Typography>
        <Typography variant="body1" className={classes.textBtnToken}>
          More info
        </Typography>
      </Box>
    </ButtonPerso>
  );
}

const useStyle = makeStyles(() => ({
  root: {
    background: 'transparent',
    borderRadius: '2rem',
    minHeight: '10rem',
    margin: '0 auto',
    '& img': {
      filter:
        'brightness(0) invert(1) drop-shadow( 1px 1px 10px rgb(15, 190, 255))',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '2rem',
      padding: '2px',
      background: 'linear-gradient(360deg,#3a34cf,#50b6f6 60%)',
      '-webkit-mask':
        'linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0)',
      '-webkit-maskComposite': 'destination-out',
    },
    '&::after': {
      backgroundColor: '#fff',
      content: '""',
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: 0,
      borderRadius: '9999px',
      opacity: 0,
      padding: '1rem',
    },
  },
  titleBtnToken: {
    lineHeight: 0.5,
    fontWeight: 600,
    fontSize: '1.4rem',
    '& span': {
      fontFamily: 'Bebas',
      fontSize: '2.4rem',
      lineHeight: 1.5,
    },
  },
  textBtnToken: {
    marginTop: '0.5rem',
    textTransform: 'initial',
    fontWeight: 200,
    fontSize: '1rem',
  },
}));

ButtonOxyaToken.propTypes = {
  className: Proptypes.string,
};
