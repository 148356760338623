import axios from 'axios';
import {
  FETCH_AVATARS,
  FETCH_AVATARS_ERROR,
  FETCH_AVATARS_FULFILLED,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes';

import { tokenConfig } from '../../utils/configFetch';

export const getAvatars = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_AVATARS,
    type: START_LOADING,
  });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/avatars`,
      tokenConfig(),
    );
    dispatch({
      type: FETCH_AVATARS_FULFILLED,
      avatars: res.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_AVATARS_ERROR,
    });
  }
  dispatch({
    actionName: FETCH_AVATARS,
    type: STOP_LOADING,
  });
};
