import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';

function ButtonPerso({
  children,
  style,
  className,
  disabled,
  onClick,
  shrink,
  small,
  noBoxShadow,
  isLoading,
  withBorder,
  noUppercase,
}) {
  const classes = useStyle({
    small,
    noBoxShadow,
    withBorder,
    noUppercase,
  });
  return (
    <button
      className={cx(
        classes.root,
        disabled || isLoading ? classes.disabled : null,
        shrink ? classes.shrink : null,
        noUppercase ? classes.noUppercase : null,
        className,
      )}
      onClick={!disabled && !isLoading ? onClick : null}
      type="button"
      style={style}
    >
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            style={{
              color: 'white',
            }}
            size={25}
          />
        </div>
      )}
      <span className={isLoading ? classes.hidden : null}>{children}</span>
    </button>
  );
}

ButtonPerso.propTypes = {
  className: Proptypes.string,
  children: Proptypes.node,
  style: Proptypes.object,
  onClick: Proptypes.func,
  disabled: Proptypes.bool,
  shrink: Proptypes.bool,
  small: Proptypes.bool,
  isLoading: Proptypes.bool,
  noBoxShadow: Proptypes.bool,
  withBorder: Proptypes.bool,
  noUppercase: Proptypes.bool,
};

ButtonPerso.defaultProps = {
  children: null,
  onClick: () => {},
  disabled: false,
  shrink: false,
  small: false,
  isLoading: false,
  noBoxShadow: false,
  withBorder: true,
  noUppercase: false,
};

const useStyle = makeStyles(({ palette }) => ({
  root: {
    alignItems: 'center',
    background: palette.primary.dark,
    border: (props) =>
      props.withBorder ? `1px solid ${palette.primary.light}` : 'none',
    borderRadius: '5rem',
    boxShadow: (props) => (props.noBoxShadow ? 'none' : '0 0 30px #27c6f74d'),
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: (props) => (props.small ? '1.2rem' : '1.8rem'),
    fontWeight: 700,
    minHeight: '4rem',
    justifyContent: 'center',
    lineHeight: 1.2,
    padding: '1rem 2rem',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '0.2s opacity ease-in-out',
    width: 'auto',
    '&:hover': {
      '& p': {
        color: palette.primary.light,
      },
      '& span': {
        color: palette.primary.light,
      },
    },
  },
  hidden: {
    color: () => 'transparent !important',
  },

  disabled: {
    cursor: 'auto',
    color: `${palette.text.grey} !important`,
    borderColor: `${palette.text.grey} !important`,
    background: '#0F1822 !important',
    '&:hover': {
      opacity: '1',
      color: `${palette.text.grey} !important`,
      '& p': {
        color: `${palette.text.grey} !important`,
      },
      '& span': {
        color: `${palette.text.grey} !important`,
      },
    },
  },
  text: {
    fontSize: '1.4rem',
    textTransform: 'uppercase',
  },
  shrink: {
    width: 'auto',
  },
  noUppercase: {
    textTransform: 'capitalize',
  },
}));

export default ButtonPerso;
