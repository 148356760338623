import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import discord from '../../../assets/social/discord.svg';
import twitter from '../../../assets/social/twitter.svg';
import linkedIn from '../../../assets/social/linkedin.svg';
import instagram from '../../../assets/social/insta.svg';
import opensea from '../../../assets/social/opensea.svg';
import youtube from '../../../assets/social/youtube.svg';

import withNavSocialMedia from './nav-social-media.style';

function navSocialMedia({ classes, className }) {
  return (
    <Box mb={4} className={classes.root}>
      <Typography color="primary" variant="h6">
        Follow us
      </Typography>
      <Box mt={1} />
      <div className={cx(classes.root, className)}>
        <a
          href="https://opensea.io/collection/oxyaoriginproject"
          target="_blank"
          className={classes.linkMedia}
          rel="noreferrer"
        >
          <img
            src={opensea}
            loading="lazy"
            alt="Link of our collection in opensea"
            className={classes.imgMedia}
          />
        </a>
        <a
          href="https://www.instagram.com/oxyaorigin"
          target="_blank"
          className={classes.linkMedia}
          rel="noreferrer"
        >
          <img
            src={instagram}
            loading="lazy"
            alt="OxyaOrigin in instagram"
            className={classes.imgMedia}
          />
        </a>
        <a
          href="https://twitter.com/oxyaorigin"
          target="_blank"
          className={classes.linkMedia}
          rel="noreferrer"
        >
          <img
            src={twitter}
            loading="lazy"
            alt="OxyaOrigin in twitter"
            className={classes.imgMedia}
          />
        </a>
        <a
          href="https://discord.com/invite/oxyaorigin"
          target="_blank"
          className={classes.linkMedia}
          rel="noreferrer"
        >
          <img
            src={discord}
            loading="lazy"
            alt="OxyaOrigin in discord"
            className={classes.imgMedia}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/oxyaorigin/"
          target="_blank"
          className={classes.linkMedia}
          rel="noreferrer"
        >
          <img
            src={linkedIn}
            loading="lazy"
            alt="OxyaOrigin in linkedIn"
            className={classes.imgMedia}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCLv4c_vOHTDueESoMiIaDAg"
          target="_blank"
          className={classes.linkMedia}
          rel="noreferrer"
        >
          <img
            src={youtube}
            loading="lazy"
            alt="OxyaOrigin in Youtube"
            className={classes.imgMedia}
          />
        </a>
      </div>
    </Box>
  );
}

navSocialMedia.propTypes = {
  classes: Proptypes.objectOf(Proptypes.string).isRequired,
  className: Proptypes.string,
};

navSocialMedia.defaultProps = {
  className: '',
};

export default withNavSocialMedia(navSocialMedia);
