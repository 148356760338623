import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { Grid, Box, makeStyles } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';

import { useSelector, useDispatch } from 'react-redux';
import CollaborateCraftingIcon from '../../../assets/icons/collab_crafting.svg';

import { heightHeader } from '../../../constants/size';
import Leaderboard from '../../_organisms/Leaderboard/leaderboard';
import { isNotEmptyArray } from '../../../utils/array';
import {
  getLeaderboardCitizen,
  getLeaderboardCitizenUser,
  getLeaderboardGenesis,
  getLeaderboardGenesisUser,
  getLeaderboardKeys,
  getLeaderboardKeysUser,
} from '../../../redux/actions/leaderboard';

export default function Leaderboards() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const leaderboardGenesis = useSelector((state) => state.leaderboard.genesis);
  const leaderboardKeys = useSelector((state) => state.leaderboard.keys);
  const leaderboardCitizen = useSelector((state) => state.leaderboard.citizen);

  const fetchedLeaderboardGenesis = useSelector(
    (state) => state.leaderboard.fetchedGenesis,
  );

  const fetchedLeaderboardKeys = useSelector(
    (state) => state.leaderboard.fetchedKeys,
  );

  const fetchedLeaderboardCitizen = useSelector(
    (state) => state.leaderboard.fetchedCitizen,
  );

  const leaderboardGenesisUser = useSelector(
    (state) => state.leaderboard.scoreUserGenesis,
  );
  const leaderboardKeysUser = useSelector(
    (state) => state.leaderboard.scoreUserKeys,
  );

  const leaderboardCitizenUser = useSelector(
    (state) => state.leaderboard.scoreUserCitizen,
  );

  const fetchedLeaderboardGenesisUser = useSelector(
    (state) => state.leaderboard.fetchedUserGenesis,
  );

  const fetchedLeaderboardKeysUser = useSelector(
    (state) => state.leaderboard.fetchedUserKeys,
  );

  const fetchedLeaderboardCitizenUser = useSelector(
    (state) => state.leaderboard.fetchedUserCitizen,
  );

  useEffect(() => {
    if (accessToken && isNotEmptyArray(user?.otherWalletsLinked)) {
      if (!fetchedLeaderboardGenesisUser) {
        dispatch(getLeaderboardGenesisUser());
      }
      if (!fetchedLeaderboardKeysUser) {
        dispatch(getLeaderboardKeysUser());
      }
      if (!fetchedLeaderboardCitizenUser) {
        dispatch(getLeaderboardCitizenUser());
      }
    }
  }, [
    user,
    fetchedLeaderboardGenesisUser,
    fetchedLeaderboardKeysUser,
    fetchedLeaderboardCitizenUser,
  ]);

  useEffect(() => {
    if (!fetchedLeaderboardGenesis) {
      dispatch(getLeaderboardGenesis());
    }
  }, [fetchedLeaderboardGenesis]);

  useEffect(() => {
    if (!fetchedLeaderboardKeys) {
      dispatch(getLeaderboardKeys());
    }
  }, [fetchedLeaderboardKeys]);

  useEffect(() => {
    if (!fetchedLeaderboardCitizen) {
      dispatch(getLeaderboardCitizen());
    }
  }, [fetchedLeaderboardCitizen]);

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={2}>
              {fetchedLeaderboardGenesis ? (
                <Leaderboard
                  title="Leaderboard Genesis"
                  list={leaderboardGenesis}
                  description="Leaderboard for private for Genesis Players"
                  icon={CollaborateCraftingIcon}
                  rankUser={leaderboardGenesisUser.rank}
                  scoreUser={leaderboardGenesisUser.score}
                  user={user}
                  classNamePanelTitle={classes.panelTitleLeaderboard}
                />
              ) : (
                <Skeleton
                  sx={{ bgcolor: 'grey.900' }}
                  variant="rectangular"
                  width="100%"
                  height={400}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              {fetchedLeaderboardKeys ? (
                <Leaderboard
                  title="Leaderboard Valor Crests"
                  list={leaderboardKeys}
                  description="Leaderboard for private for Valor Crests Players"
                  icon={CollaborateCraftingIcon}
                  rankUser={leaderboardKeysUser.rank}
                  scoreUser={leaderboardKeysUser.score}
                  user={user}
                  classNamePanelTitle={classes.panelTitleLeaderboard}
                />
              ) : (
                <Skeleton
                  sx={{ bgcolor: 'grey.900' }}
                  variant="rectangular"
                  width="100%"
                  height={400}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              {fetchedLeaderboardCitizen ? (
                <Leaderboard
                  title="Leaderboard Citizens"
                  list={leaderboardCitizen}
                  description="Leaderboard for private for Citizens Players"
                  icon={CollaborateCraftingIcon}
                  rankUser={leaderboardCitizenUser.rank}
                  scoreUser={leaderboardCitizenUser.score}
                  user={user}
                  classNamePanelTitle={classes.panelTitleLeaderboard}
                />
              ) : (
                <Skeleton
                  sx={{ bgcolor: 'grey.900' }}
                  variant="rectangular"
                  width="100%"
                  height={400}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

// TODO: Change Proptypes object by the shape of the object
Leaderboards.propTypes = {
  location: Proptypes.shape({
    hash: Proptypes.string,
    key: Proptypes.string,
    search: Proptypes.string,
    state: Proptypes.object,
  }),
};

Leaderboards.defaultProps = {
  location: {},
};

const useStyle = makeStyles(({ palette, breakpoints, spacing }) => ({
  panelTitleLeaderboard: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalChildrenRegister: {},
  modalPaperRegister: {
    background: '#091E31',
    '& ul': {
      paddingLeft: '3rem',
      '& li': {
        fontWeight: 600,
      },
    },
  },
  titleModalRegister: {
    fontFamily: 'Akira',
    fontSize: '3.6rem',
    fontWeight: 800,
    lineHeight: '41px',
    whiteSpace: 'pre-wrap',
    [breakpoints.down(400)]: {
      fontSize: '3rem',
    },
  },
  detailsModalRegister: {
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '29px',
    maxWidth: '100%',
    margin: '1rem 0',
  },
  boxSocialRegister: {
    display: 'flex',
    gap: '2rem',
    margin: '2rem 0',
  },
  btnAccountPage: {
    width: '100%',
    background:
      'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '1rem',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '4rem',
    minHeight: `calc(100vh - 4rem - ${heightHeader})`,
  },
  initialHeight: {
    height: 'initial',
  },
  bg: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    height: `calc(100vh - 4rem - ${heightHeader})`,
  },
  wrapperNoConnected: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    color: palette.common.white,
    textShadow: '2px 9px 51px #10193A70',
    textStroke: '1px RGBA(0, 0, 0, 0.6)',
    fontSize: '6rem',
    [breakpoints.down(600)]: {
      fontSize: '3rem',
    },
  },
  btnConnect: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    padding: spacing(3),
    paddingTop: spacing(2.8),
    width: '100%',
    height: '100%',
  },
}));

/*
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

export default function MarketplaceBuildings() {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <Typography variant="h3" color="textSecondary">
        Coming soon
      </Typography>
    </div>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));
*/
