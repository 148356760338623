import { connect } from 'react-redux';
import { loadUser } from '../../redux/actions/user';
import DashboardContainer from './DashboardContainer';

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

export default connect(mapStateToProps, {
  loadUser,
})(DashboardContainer);
