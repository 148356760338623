import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';

export default function PanelTitle({
  className,
  iconPath,
  label,
  addBorder,
  addSpace,
  children,
}) {
  const classes = useStyle({ addBorder });
  return (
    <Typography
      style={{
        color: '#00DBFF',
      }}
      color="textSecondary"
      align="left"
      className={classes.line}
    >
      <Box
        className={className}
        sx={{ display: 'inline-flex' }}
        p={addSpace ? 2 : 0}
      >
        <img src={iconPath} alt="icon" />
        <Typography variant="h6" className={classes.space}>
          {label}
        </Typography>
        {children}
      </Box>
    </Typography>
  );
}

PanelTitle.propTypes = {
  iconPath: Proptypes.node,
  label: Proptypes.string,
  className: Proptypes.string,
  children: Proptypes.node,
  addBorder: Proptypes.bool,
  addSpace: Proptypes.bool,
};

PanelTitle.defaultProps = {
  addBorder: true,
  addSpace: true,
  children: null,
};

const useStyle = makeStyles((theme) => ({
  space: {
    paddingLeft: theme.spacing(1),
  },
  line: {
    borderBottom: (props) =>
      props.addBorder ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
}));
