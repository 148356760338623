import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import Proptypes from 'prop-types';

export default function Level({ activeLevel, maxLevel, column, small }) {
  const classes = useStyle({ column, small });
  return (
    <div className={classes.container}>
      <Typography variant={small ? 'h6' : 'h4'} color="primary">
        LV. {activeLevel}
      </Typography>
      <Box display="flex" ml={column ? 0 : 3} mt={column ? 1 : 0}>
        {Array(maxLevel)
          .fill(0)
          .map((_, index) => index)
          .map((i, index) => {
            const filled = index + 1 <= activeLevel;
            return (
              <div
                key={i}
                className={cx(classes.dot, filled ? classes.filled : null)}
              />
            );
          })}
      </Box>
    </div>
  );
}

Level.propTypes = {
  activeLevel: Proptypes.number,
  maxLevel: Proptypes.number,
  column: Proptypes.bool,
  small: Proptypes.bool,
};

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: ({ column }) => (column ? 'flex-start' : 'center'),
    flexDirection: ({ column }) => (column ? 'column' : 'row'),
  },

  dot: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: '#064586',
    marginRight: theme.spacing(0.5),
  },
  filled: {
    backgroundColor: theme.palette.primary.light,
  },
}));
