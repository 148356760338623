import {
  FETCH_LANDS_FULFILLED,
  FETCH_LANDS_ERROR,
  UPGRADE_LAND_FULFILLED,
  CLAIM_UPGRADE_LAND_FULFILLED,
  UPGRADE_LAND_ERROR,
  CLAIM_UPGRADE_LAND_ERROR,
  UPDATE_LAND,
} from '../constants/actionTypes';

const initialState = {
  lands: [],
  fetched: false,
};

export default function landReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LANDS_FULFILLED:
      return {
        ...state,
        lands: action.lands,
        fetched: true,
      };
    case FETCH_LANDS_ERROR:
    case UPGRADE_LAND_ERROR:
    case CLAIM_UPGRADE_LAND_ERROR:
      return {
        ...state,
        fetched: true,
      };

    case UPGRADE_LAND_FULFILLED:
    case CLAIM_UPGRADE_LAND_FULFILLED:
    case UPDATE_LAND: {
      const newLands = state.lands.map((b) => {
        if (b.tokenId === action.land.tokenId) {
          return action.land;
        }
        return b;
      });
      return {
        ...state,
        lands: newLands,
        fetched: true,
      };
    }
    default:
      return state;
  }
}
