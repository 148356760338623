import { createSelector } from '@reduxjs/toolkit';
import { selectUserAddress } from './userSelector';

export const selectAllLands = (state) => state.land.lands;
export const baseSelect = (state, input) => input;

export const selectLand = (state, landTokenId) =>
  state.land.lands.find((land) => land?.tokenId === landTokenId);

export const selectFormattedLands = createSelector(
  [selectAllLands, selectUserAddress],
  (lands, userAddress) =>
    lands.map((land) => ({
      ...land,
      linkedBuildings: land.linkedBuildings.filter(
        (b) => b.owner?.toLowerCase() === userAddress?.toLowerCase(),
      ),
    })),
);

export const selectFormattedLand = createSelector(
  [selectLand, selectUserAddress],
  (land, userAddress) => ({
    ...land,
    linkedBuildings:
      land?.linkedBuildings.filter(
        (b) => b.owner?.toLowerCase() === userAddress?.toLowerCase(),
      ) || [],
  }),
);

export const selectFilteredLands = createSelector(
  [selectAllLands, baseSelect, selectUserAddress],
  (lands, filters, userAddress) => {
    if (filters) {
      let newLands = lands;
      if (filters.level?.length > 0) {
        newLands = newLands.filter((b) =>
          filters.level.includes(b.level.toString()),
        );
      }
      if (filters.planet?.length > 0) {
        newLands = newLands.filter((b) =>
          filters.planet.includes(b.planetName),
        );
      }
      if (filters.status?.length > 0) {
        const landsFrozen = [...newLands];
        let statusLands = [];
        if (filters.status.includes('leveling')) {
          statusLands = [...landsFrozen.filter((b) => b.leveling?.active)];
        }
        if (filters.status.includes('crafting')) {
          statusLands = [
            ...statusLands,
            ...landsFrozen.filter(
              (b) =>
                b.crafting?.ownerAddress?.toLowerCase() ===
                userAddress?.toLowerCase(),
            ),
          ];
        }
        if (filters.status.includes('available')) {
          statusLands = [
            ...statusLands,
            ...landsFrozen.filter(
              (b) =>
                !b.leveling?.active &&
                b.crafting?.ownerAddress?.toLowerCase() !==
                  userAddress?.toLowerCase(),
            ),
          ];
        }

        newLands = statusLands;
      }
      return newLands;
    }
    return lands;
  },
);
