import React, { useMemo, useRef, useState } from 'react';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';

import filtersLogo from '../../../assets/icons/filter.svg';
import { useQueryFilters } from '../../../hooks/useQueryFilters';
import FiltersPopper from '../../_molecules/FiltersPopper/filtersPopper';

export default function Filters({ filters }) {
  const classes = useStyle();
  const dropdownRef = useRef(null);
  const filtersKeys = useMemo(() => filters.map((f) => f.slug), [filters]);

  const [filtersPopperOpened, setFiltersPopperOpened] = useState(false);
  const { toggleFilter, selectedFilters } = useQueryFilters({
    filters: filtersKeys,
  });

  const nbSelectedValues = useMemo(() => {
    const nb = Object.values(selectedFilters);
    const endNb = nb.flat();

    return endNb?.length || 0;
  }, [selectedFilters]);

  return (
    <>
      <FiltersPopper
        anchorEl={dropdownRef}
        open={filtersPopperOpened}
        onClose={() => setFiltersPopperOpened(false)}
        onToggle={toggleFilter}
        filters={filters}
        selectedFilters={selectedFilters}
      />
      <ButtonBase
        disableRipple
        className={classes.button}
        ref={dropdownRef}
        onClick={() => setFiltersPopperOpened((prev) => !prev)}
      >
        <img src={filtersLogo} alt="logo" className={classes.logo} />
        {nbSelectedValues > 0 && (
          <div className={classes.number}>
            <Typography color="primary">{nbSelectedValues}</Typography>
          </div>
        )}
      </ButtonBase>
    </>
  );
}

Filters.propTypes = {
  filters: Proptypes.object,
};

const useStyle = makeStyles((theme) => ({
  button: {
    position: 'relative',
  },
  number: {
    position: 'absolute',
    top: 10,
    right: 6,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    width: 23,
    height: 23,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
