import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { Link } from '@material-ui/core';

import { Link as RouterLink, useHistory } from 'react-router-dom';
import withNavBar from './navBar.style';

function Navbar({ listHeader, classes }) {
  const history = useHistory();
  return (
    <div className={classes.container}>
      <nav className={classes.nav}>
        {listHeader.map((item) => {
          const isSelectedPath =
            item.search === '/'
              ? history.location.pathname === '/'
              : history.location.pathname.includes(item?.search || item.to);
          return (
            <Link
              component={RouterLink}
              to={item.to}
              variant="h5"
              underline="none"
              style={{ fontWeight: 500 }}
              key={item.key}
              className={cx(
                classes.link,
                isSelectedPath ? classes.selected : classes.nonSelected,
              )}
            >
              {item.text}
              {/* <div className={classes.underlineContainer}>
                <div
                  className={classes.underline}
                  style={{ width: item.text.length * 10 }}
                />
              </div> */}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}

Navbar.propTypes = {
  classes: Proptypes.objectOf(Proptypes.string).isRequired,
  listHeader: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
      to: Proptypes.string,
      text: Proptypes.string,
    }),
  ),
};

Navbar.defaultProps = {
  listHeader: [],
};

export default withNavBar(Navbar);
