import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import EmptyResult from '../../_molecules/EmptyResult/emptyResult';
import AssetList from '../../_molecules/AssetList/assetList';
import ConnectWallet from '../../_molecules/ConnectWallet/connectWallet';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';
import { isActionLoading } from '../../../redux/selectors/loadingSelectors';
import { FETCH_AVATARS } from '../../../redux/constants/actionTypes';
import { getAvatars } from '../../../redux/actions/avatarActions';

export default function InventoryAvatars() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const avatars = useSelector((state) => state.avatar.items);
  const isLoading = useSelector((state) =>
    isActionLoading(state, FETCH_AVATARS),
  );
  const fetched = useSelector((state) => state.avatar.fetched);
  const accessToken = useSelector((state) => state.user.accessToken);
  const user = useSelector((state) => state.user.user);
  const handleClickLogin = () => {
    history.push('/auth/login');
  };

  useEffect(async () => {
    if (accessToken && user?.walletAddress && !fetched) {
      dispatch(getAvatars());
    }
  }, [accessToken, fetched]);

  if (!accessToken) {
    return <ConnectWallet onLogin={handleClickLogin} text="Avatars" />;
  }

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
          <Typography variant="h4" align="left" color="primary">
            {`Owned (${avatars?.length || 0})`}
          </Typography>
          <Box ml={4}>
            <Link
              component="button"
              onClick={() =>
                window.open('https://opensea.io/collection/oxyaoriginproject')
              }
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h5" className={classes.buyText}>
                  For Sale on Opensea
                  <ArrowRightAltIcon
                    style={{ marginLeft: 10 }}
                    className={classes.icon}
                  />
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box mb={3} />
      {user?.walletAddress == null && (
        <EmptyResult text="You don't have wallet EVM (ETH, BSC, ...) linked to your account">
          <Box mt={4} />
          <ButtonPerso
            shrink
            onClick={() => {
              history.push('/account');
            }}
          >
            Link an EVM Wallet
          </ButtonPerso>
        </EmptyResult>
      )}
      {fetched && user?.walletAddress && avatars?.length === 0 ? (
        <EmptyResult text="You don t have any avatar">
          <Box mt={4} />
          <ButtonPerso
            shrink
            onClick={() => {
              window.open('https://opensea.io/collection/oxyaoriginproject');
            }}
          >
            BUY AN AVATAR
          </ButtonPerso>
        </EmptyResult>
      ) : (
        <AssetList assets={avatars} assetType="avatars" isLoading={isLoading} />
      )}
    </div>
  );
}

const useStyle = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(3),
    paddingTop: spacing(2.8),
    width: '100%',
    height: '100%',
  },
  title: {
    color: 'white',
  },
  buyText: {
    color: palette.text.secondary,
    textDecoration: 'underline',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecorationColor: palette.primary.light,
      '& svg': {
        color: palette.primary.light,
      },
    },
  },
}));
