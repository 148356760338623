import { CLOSE_MODAL, OPEN_MODAL } from '../constants/actionTypes';

const initialState = {
  openedModal: '',
  craftingBuilding: null,
  craftType: null,
  craftSubType: null,
  upgradedAsset: null,
};

export default function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        openedModal: action.modalName,
        craftingBuilding: action.craftingBuilding,
        craftType: action.craftType,
        craftSubType: action.craftSubType,
        upgradedAsset: action.upgradedAsset,
      };
    case CLOSE_MODAL:
      return {
        openedModal: '',
        craftingBuilding: null,
        craftType: null,
      };

    default:
      return state;
  }
}
