import React from 'react';
import {
  Box,
  Popper,
  Fade,
  Typography,
  makeStyles,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core';
import Proptypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

import Filter from '../../_atoms/Filter/filter';
import AccordionList from '../AccordionList/accordionList';

export default function FiltersPopper({
  anchorEl,
  selectedFilters,
  open,
  filters,
  onClose,
  onToggle,
}) {
  const classes = useStyle();
  return (
    <Popper
      open={open}
      style={{ zIndex: 100 }}
      id="id"
      anchorEl={anchorEl?.current}
      transition
      placement="bottom-end"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={150}>
          <ClickAwayListener onClickAway={onClose}>
            <Box className={classes.container}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="textSecondary" variant="h6">
                  FILTER BY
                </Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: 'grey' }} />
                </IconButton>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mt={2}
              >
                <AccordionList
                  queryParam="popperPanel"
                  keys={filters.map((f) => f.name)}
                  titles={filters.map((f) => (
                    <Typography
                      color={
                        selectedFilters[f.slug].length > 0
                          ? 'primary'
                          : 'textSecondary'
                      }
                    >
                      {`${f.name} (${selectedFilters[f.slug].length})`}
                    </Typography>
                  ))}
                  childrens={filters.map((filter) => (
                    <Filter
                      selectedChoices={selectedFilters[filter.slug]}
                      filterName={filter.name}
                      filterChoices={filter.choices}
                      onClickItem={(key) => onToggle(key, filter.slug)}
                    />
                  ))}
                />
                {/* {filters.map((filter) => (
                  <Box key={filter.name} mt={1} width="100%">
                    <Filter
                      selectedChoices={selectedFilters[filter.slug]}
                      filterName={filter.name}
                      filterChoices={filter.choices}
                      onClickItem={(key) => onToggle(key, filter.slug)}
                    />
                  </Box>
                ))} */}
              </Box>
            </Box>
          </ClickAwayListener>
        </Fade>
      )}
    </Popper>
  );
}

FiltersPopper.propTypes = {
  anchorEl: Proptypes.object,
  selectedFilters: Proptypes.object,
  open: Proptypes.bool,
  onClose: Proptypes.func,
  onToggle: Proptypes.func,
  filters: Proptypes.array,
};

FiltersPopper.defaultProps = {
  onToggle: () => {},
};

const useStyle = makeStyles((theme) => ({
  container: {
    background:
      'transparent radial-gradient(closest-side at 50% 50%, #0D1826 0%, #000000 100%) 0% 0% no-repeat padding-box',
    boxShadow: '-30px 3px 66px #1b1e24a8',
    border: '0.5px solid #29405F',
    opacity: 1,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(5),
    minWidth: 300,
    borderRadius: 5,
  },
}));
