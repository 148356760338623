import React, { useEffect } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AssetImage from '../../_atoms/AssetImage/assetImage';
import { getLands } from '../../../redux/actions/lands';
import { selectLand } from '../../../redux/selectors/landSlectors';

import LandSummaryPanel from '../../_molecules/LandSummaryPanel/landSummaryPanel';
import DetailPageSkeleton from '../../_atoms/DetailPageSkeleton/detailPageSkeleton';

import LandDescriptionPanel from '../../_organisms/LandDescriptionPanel/landDescriptionPanel';
import { parseImgUrlLand } from '../../../utils/string';
import Level from '../../_atoms/Level/level';

export default function LandDetail() {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { tokenId } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(getLands());
  }, []);

  const land = useSelector((state) => selectLand(state, parseInt(tokenId, 10)));
  if (land === undefined) {
    history.push('/inventory/lands');
  }
  const user = useSelector((state) => state.user.user);

  return (
    <div className={classes.container}>
      {!land ? (
        <DetailPageSkeleton />
      ) : (
        <Grid container className={classes.detail} spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.topMobile}>
              <Typography variant="h3" align="left" className={classes.title}>
                {land.size === 1 ? 'Land' : 'Colony'} #{land.tokenId}
              </Typography>
              <Level activeLevel={land.level} maxLevel={5} />
            </Box>
            <AssetImage src={parseImgUrlLand(land)} borderRadius />
            <Box mt={2} className={classes.wrapperLandDescriptionPanel}>
              <LandDescriptionPanel
                land={land}
                userAddress={user?.walletAddress}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box className={classes.topDesktop}>
              <Typography variant="h3" align="left" className={classes.title}>
                {land.size === 1 ? 'Land' : 'Colony'} #{land.tokenId}
              </Typography>
              <Level activeLevel={land.level} maxLevel={5} />
            </Box>
            <LandSummaryPanel land={land} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const useStyle = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    padding: spacing(3),
    width: '100%',
  },
  wrapperLandDescriptionPanel: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  detail: {
    height: '100%',
  },
  title: {
    color: 'white',
  },
  topDesktop: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  topMobile: {
    display: 'none',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '2rem',
    [breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
}));
