import axios from 'axios';
import {
  FETCH_LANDS,
  FETCH_LANDS_ERROR,
  FETCH_LANDS_FULFILLED,
  UPGRADE_LAND,
  UPGRADE_LAND_ERROR,
  CLAIM_UPGRADE_LAND,
  UPGRADE_LAND_FULFILLED,
  CLAIM_UPGRADE_LAND_FULFILLED,
  CLAIM_UPGRADE_LAND_ERROR,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes';

import { returnErrors } from './errorAction';
import { tokenConfig } from '../../utils/configFetch';

export const getLands = () => async (dispatch) => {
  dispatch({
    type: START_LOADING,
    actionName: FETCH_LANDS,
  });
  try {
    const lands = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/lands/byUser`,
      tokenConfig(),
    );
    dispatch({
      type: FETCH_LANDS_FULFILLED,
      lands: lands.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_LANDS_ERROR,
    });
  }
  dispatch({
    type: STOP_LOADING,
    actionName: FETCH_LANDS,
  });
};

export const upgradeLand = (landTokenId, landSize) => async (dispatch) => {
  dispatch({
    type: START_LOADING,
    actionName: UPGRADE_LAND,
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/lands/upgrade`,
      { tokenId: landTokenId, size: landSize },
      tokenConfig(),
    );
    dispatch({
      type: UPGRADE_LAND_FULFILLED,
      land: res.data,
    });
  } catch (e) {
    dispatch(
      returnErrors(e?.response.data, e?.response.status, UPGRADE_LAND_ERROR),
    );
    dispatch({
      type: UPGRADE_LAND_ERROR,
    });
  }
  dispatch({
    type: STOP_LOADING,
    actionName: UPGRADE_LAND,
  });
};

export const claimLand = (landTokenId, landSize) => async (dispatch) => {
  dispatch({
    type: START_LOADING,
    actionName: CLAIM_UPGRADE_LAND,
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/lands/upgrade/claim`,
      { tokenId: landTokenId, size: landSize },
      tokenConfig(),
    );
    dispatch({
      type: CLAIM_UPGRADE_LAND_FULFILLED,
      land: res.data,
    });
  } catch (e) {
    dispatch(
      returnErrors(
        e?.response.data,
        e?.response.status,
        CLAIM_UPGRADE_LAND_ERROR,
      ),
    );
    dispatch({
      type: CLAIM_UPGRADE_LAND_ERROR,
    });
  }
  dispatch({
    type: STOP_LOADING,
    actionName: CLAIM_UPGRADE_LAND,
  });
};
