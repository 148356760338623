import React from 'react';
import Proptypes from 'prop-types';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { LeaderboardProptypes } from '../../../constants/propTypes';
import LeaderboardItem from '../../_atoms/LeaderboardItem/leaderboard-item';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';
import { MAX_ITEMS_TO_DISPLAY_LEADERBOARD } from '../../../redux/constants/leaderboard';

export default function LeaderboardList({ list }) {
  const classes = useStyle();

  const [maxItems, setMaxItems] = React.useState(
    MAX_ITEMS_TO_DISPLAY_LEADERBOARD,
  );

  const handleSeeMore = () => {
    setMaxItems(maxItems + MAX_ITEMS_TO_DISPLAY_LEADERBOARD);
  };
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography
          align="center"
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            color: 'rgb(15, 190, 255)',
          }}
        >
          Rank
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          align="center"
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            color: 'rgb(15, 190, 255)',
          }}
        >
          Username
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          align="center"
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            color: 'rgb(15, 190, 255)',
          }}
        >
          Score
        </Typography>
      </Grid>

      <Grid container item xs={12}>
        <Box mt={2} />
      </Grid>

      {list?.slice(0, maxItems).map((item) => (
        <LeaderboardItem key={item.rank} item={item} />
      ))}
      {maxItems < list.length && (
        <ButtonPerso className={classes.btnMore} onClick={handleSeeMore}>
          More
        </ButtonPerso>
      )}
    </Grid>
  );
}

LeaderboardList.propTypes = {
  list: Proptypes.arrayOf(LeaderboardProptypes),
};

LeaderboardList.defaultProps = {
  list: [],
};

const useStyle = makeStyles(() => ({
  btnMore: {
    boxShadow: 'none',
    margin: '2rem auto 0 auto',
    minHeight: 'initial',
    fontSize: '1.2rem',
  },
}));
