import { withStyles } from '@material-ui/core';

export default withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  linkMedia: {
    margin: '0 4px',
    transition: '0.2s opacity ease-in-out',
    color: theme.palette.primary.light,
    '&:hover': {
      opacity: 0.8,
    },
  },
  imgMedia: {
    width: 15,
  },
}));
