import axios from 'axios';
import bs58 from 'bs58';

import {
  GET_ACCOUNT,
  LOGOUT,
  LOAD_USER_ERROR,
  LOAD_USER_FULFILLED,
  LOAD_USER,
  GET_ACCOUNT_ERROR,
  CHANGE_PROVIDER_ERROR,
  FETCH_UNLINK_EVM_WALLET,
  FETCH_UNLINK_EVM_WALLET_ERROR,
  FETCH_UNLINK_EVM_WALLET_FULFILLED,
  FETCH_LINK_WAX_WALLET,
  FETCH_LINK_WAX_WALLET_ERROR,
  FETCH_LINK_WAX_WALLET_FULFILLED,
  FETCH_LINK_ULTRA_WALLET,
  FETCH_LINK_ULTRA_WALLET_ERROR,
  FETCH_LINK_ULTRA_WALLET_FULFILLED,
  FETCH_LINK_SOLANA_WALLET,
  FETCH_LINK_SOLANA_WALLET_ERROR,
  FETCH_LINK_SOLANA_WALLET_FULFILLED,
  FETCH_LINK_EVM_WALLET_ERROR,
  FETCH_LINK_EVM_WALLET_FULFILLED,
  FETCH_LINK_EVM_WALLET,
  FETCH_LINK_ACCOUNT_ERROR,
  FETCH_LINK_ACCOUNT_FULFILLED,
  FETCH_LINK_ACCOUNT,
  FETCH_UPDATE_USER_ERROR,
  FETCH_UPDATE_USER_FULFILLED,
  FETCH_UPDATE_USER,
  FETCH_UPDATE_PASSWORD_ERROR,
  FETCH_UPDATE_PASSWORD_FULFILLED,
  FETCH_UPDATE_PASSWORD,
  FETCH_RECOVER_PASSWORD_ERROR,
  FETCH_RECOVER_PASSWORD_FULFILLED,
  FETCH_RECOVER_PASSWORD,
  FETCH_FORGET_PASSWORD_ERROR,
  FETCH_FORGET_PASSWORD,
  FETCH_LOGIN_WEB2_ERROR,
  FETCH_LOGIN_WEB2_FULFILLED,
  FETCH_LOGIN_WEB3_ERROR,
  FETCH_LOGIN_WEB3,
  FETCH_LOGIN_WEB3_FULFILLED,
  FETCH_REGISTER_WEB2,
  FETCH_REGISTER_WEB2_FULFILLED,
  FETCH_REGISTER_WEB2_ERROR,
  FETCH_VALIDATE_EMAIL,
  FETCH_SIGN_IN_FULFILLED,
  FETCH_SIGN_IN_ERROR,
  FETCH_SIGN_IN,
  FETCH_UNLINK_OTHER_WALLET,
  FETCH_UNLINK_OTHER_WALLET_FULFILLED,
  FETCH_UNLINK_OTHER_WALLET_ERROR,
  START_LOADING,
  STOP_LOADING,
  FETCH_LOGIN_WEB2,
  FETCH_VALIDATE_EMAIL_FULFILLED,
  FETCH_VALIDATE_EMAIL_ERROR,
  FETCH_GET_USER_BY_TOKEN,
  FETCH_GET_USER_BY_TOKEN_FULFILLED,
  FETCH_GET_USER_BY_TOKEN_ERROR,
} from '../constants/actionTypes';
import { returnErrors } from './errorAction';
import { configNoToken, tokenConfig } from '../../utils/configFetch';
import { loadState, removeState, saveState } from '../../store/localStorage';
import { returnSuccess } from './successAction';

export const logout = (onSuccessCallback) => (dispatch) => {
  removeState('accessToken');
  dispatch({ type: LOGOUT });
  if (onSuccessCallback) {
    onSuccessCallback();
  }
};

export const loadUser =
  (cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: LOAD_USER,
    });
    dispatch({
      type: START_LOADING,
      actionName: LOAD_USER,
    });
    const accessToken = loadState('accessToken');
    if (!accessToken) {
      dispatch({
        type: STOP_LOADING,
        actionName: LOAD_USER,
      });
      dispatch(returnErrors('Not connected', 400, 'LOAD_USER_FAIL'));
      return dispatch({
        type: LOAD_USER_ERROR,
      });
    }
    try {
      const getUser = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/users/me`,
        tokenConfig(),
      );

      dispatch({
        type: LOAD_USER_FULFILLED,
        user: getUser.data,
        currentAccount: getUser?.data?.walletAddress,
      });

      cbSuccess(getUser.data);
    } catch (e) {
      removeState('accessToken');
      dispatch({ type: CHANGE_PROVIDER_ERROR });
      dispatch({ type: LOAD_USER_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'LOAD_USER_ERROR',
        ),
      );
    }

    dispatch({
      type: STOP_LOADING,
      actionName: LOAD_USER,
    });
  };

export const signIn = (address) => async (dispatch) => {
  // dispatch({ type: LOGOUT });
  if (!address) {
    dispatch(returnErrors('Please connect your wallet'));
    dispatch({ type: GET_ACCOUNT_ERROR });
  }
  dispatch({ type: GET_ACCOUNT, account: address });

  dispatch({ type: FETCH_SIGN_IN });
  dispatch({
    type: START_LOADING,
    actionName: FETCH_SIGN_IN,
  });

  try {
    const body = JSON.stringify({
      address,
    });

    const getMessage = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/signin`,
      body,
      configNoToken(),
    );
    const { message } = getMessage.data;
    dispatch({
      type: FETCH_SIGN_IN_FULFILLED,
      payload: message,
    });
    return message;
  } catch (e) {
    dispatch({ type: FETCH_SIGN_IN_ERROR });
    dispatch(
      returnErrors(
        e?.response?.data?.message,
        e?.response?.status,
        'FETCH_SIGN_IN_ERROR',
      ),
    );
  }
};

export const loginWeb3 = (signature, account, message) => async (dispatch) => {
  //   const { signMessageAsync } = useSignMessage(); A UTILISER DANS LE TRUC

  dispatch({ type: FETCH_LOGIN_WEB3 });
  dispatch({
    type: START_LOADING,
    actionName: FETCH_LOGIN_WEB3,
  });

  if (!signature || !account || !message) {
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LOGIN_WEB3,
    });
    return dispatch({ type: FETCH_LOGIN_WEB3_ERROR });
  }

  try {
    const body2 = JSON.stringify({
      signature,
      address: account,
      message,
    });

    const getToken = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/signin/sign`,
      body2,
      configNoToken(),
    );

    const { accessToken, roles, user } = getToken.data;

    saveState('accessToken', accessToken);
    saveState('roles', roles);
    /*
    const bodyEarnAlliance = JSON.stringify({
      gameId: process.env.REACT_APP_EARN_ALLIANCE_GAME_ID,
      event: "successful_registration",
      address: account,
      time: new Date().toISOString(),
    });

    const ts = Date.now();
    const messageEarnAlliance = `${process.env.REACT_APP_EARN_ALLIANCE_CLIENT_ID}${ts}${bodyEarnAlliance}`;

    const hmac = cryptojs.HmacSHA256(
      messageEarnAlliance,
      process.env.REACT_APP_EARN_ALLIANCE_CLIENT_SECRET
    );

    const signatureEarnAlliance = hmac.toString(cryptojs.enc.Hex);

    const responseEarnAlliance = await axios.post(
      "https://events.earnalliance.com/v1/custom-events",
      bodyEarnAlliance,
      configEarnAlliance(signatureEarnAlliance)
    );
*/
    dispatch({
      type: FETCH_LOGIN_WEB3_FULFILLED,
      currentAccount: account,
      user,
    });
  } catch (e) {
    dispatch({ type: FETCH_LOGIN_WEB3_ERROR });
    dispatch(
      returnErrors(
        e?.response?.data?.message,
        e?.response?.status,
        'FETCH_LOGIN_WEB3_ERROR',
      ),
    );
  }
  dispatch({
    type: STOP_LOADING,
    actionName: FETCH_LOGIN_WEB3,
  });
};

export const registerWeb2 =
  ({ email, userName, password, confirmPassword }, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_REGISTER_WEB2 });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_REGISTER_WEB2,
    });

    if (!email || !password || !confirmPassword || !userName) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_REGISTER_WEB2,
      });
      return dispatch(
        returnErrors(
          'Please complete all fields !',
          0,
          'FETCH_REGISTER_WEB2_ERROR',
        ),
      );
    }
    if (password !== confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_REGISTER_WEB2,
      });
      return dispatch(
        returnErrors('Password are not same !', 0, 'FETCH_REGISTER_WEB2_ERROR'),
      );
    }

    try {
      const body = JSON.stringify({
        email,
        userName,
        password,
        confirmPassword,
      });
      const user = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/register/web2`,
        body,
        configNoToken(),
      );

      dispatch({
        type: FETCH_REGISTER_WEB2_FULFILLED,
      });
      cbSuccess(user);
    } catch (e) {
      dispatch({ type: FETCH_REGISTER_WEB2_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_REGISTER_WEB2_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_REGISTER_WEB2,
    });
  };

export const validateEmail =
  (emailToken, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_VALIDATE_EMAIL });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_VALIDATE_EMAIL,
    });

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/verified/email/${emailToken}`,
        configNoToken(),
      );

      dispatch({
        type: FETCH_VALIDATE_EMAIL_FULFILLED,
      });

      dispatch(returnSuccess(result?.data?.message, 'VALIDATE_EMAIL'));
      cbSuccess(result);
    } catch (e) {
      dispatch({ type: FETCH_VALIDATE_EMAIL_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_VALIDATE_EMAIL_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_VALIDATE_EMAIL,
    });
  };

export const loginWeb2 =
  ({ email, password }, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_LOGIN_WEB2 });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_LOGIN_WEB2,
    });

    if (!email || !password) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_LOGIN_WEB2,
      });
      return dispatch(
        returnErrors('Please complete all fields !', 0, 'LOGIN_WEB2_ERROR'),
      );
    }

    try {
      const body = JSON.stringify({
        email,
        password,
      });

      const getToken = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/signin/web2`,
        body,
        configNoToken(),
      );

      const { accessToken, roles } = getToken.data;

      saveState('accessToken', accessToken);
      saveState('roles', roles);

      const getUser = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/users/me`,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_LOGIN_WEB2_FULFILLED,
        user: getUser.data,
      });
      cbSuccess(getUser.data);
    } catch (e) {
      dispatch({ type: FETCH_LOGIN_WEB2_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_LOGIN_WEB2_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LOGIN_WEB2,
    });
  };

export const forgetPassword =
  (email, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_FORGET_PASSWORD });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_FORGET_PASSWORD,
    });

    try {
      const body = JSON.stringify({
        email,
      });

      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/pswd/forget`,
        body,
        configNoToken(),
      );
      dispatch(
        returnSuccess(
          'An email has been sent to reset your password',
          'FORGET_PASSWORD',
        ),
      );
      cbSuccess();
    } catch (e) {
      dispatch({ type: FETCH_FORGET_PASSWORD_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_FORGET_PASSWORD_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_FORGET_PASSWORD,
    });
  };

export const getUserByToken =
  (emailToken, cbSuccess = () => {}, cbError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_GET_USER_BY_TOKEN,
    });
    try {
      const getUser = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/users/byToken/${emailToken}`,
        configNoToken(),
      );

      dispatch({
        type: FETCH_GET_USER_BY_TOKEN_FULFILLED,
      });

      cbSuccess(getUser.data);
    } catch (e) {
      cbError(e);
      dispatch({ type: FETCH_GET_USER_BY_TOKEN_ERROR });
      /* dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          "FETCH_GET_USER_BY_TOKEN_ERROR"
        )
      ); */
    }
  };

export const recoverPassword =
  ({ emailToken, password, confirmPassword }, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_RECOVER_PASSWORD });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_RECOVER_PASSWORD,
    });

    if (!password || !confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_RECOVER_PASSWORD,
      });
      return dispatch(
        returnErrors(
          'Please complete all fields !',
          0,
          'FETCH_RECOVER_PASSWORD_ERROR',
        ),
      );
    }
    if (password !== confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_RECOVER_PASSWORD,
      });
      return dispatch(
        returnErrors('Password are not same !', 0, 'FETCH_REGISTER_WEB2_ERROR'),
      );
    }

    try {
      const body = JSON.stringify({
        password,
        confirmPassword,
      });
      const result = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/pswd/recover/${emailToken}`,
        body,
        configNoToken(),
      );

      dispatch({
        type: FETCH_RECOVER_PASSWORD_FULFILLED,
      });

      dispatch(returnSuccess(result?.data?.message, 'RECOVER_PASSWORD'));
      cbSuccess(result);
    } catch (e) {
      dispatch({ type: FETCH_RECOVER_PASSWORD_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_RECOVER_PASSWORD_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_RECOVER_PASSWORD,
    });
  };

export const updatePassword =
  ({ oldPassword, password, confirmPassword }, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_UPDATE_PASSWORD });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_UPDATE_PASSWORD,
    });

    if (!oldPassword || !password || !confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_UPDATE_PASSWORD,
      });
      return dispatch(
        returnErrors(
          'Please complete all fields !',
          0,
          'FETCH_UPDATE_PASSWORD_ERROR',
        ),
      );
    }
    if (password !== confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_UPDATE_PASSWORD,
      });
      return dispatch(
        returnErrors('Password are not same !', 0, 'FETCH_REGISTER_WEB2_ERROR'),
      );
    }

    try {
      const body = JSON.stringify({
        oldPassword,
        password,
        confirmPassword,
      });
      const result = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT}/users/updatePassword`,
        body,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_UPDATE_PASSWORD_FULFILLED,
        user: result?.data?.user,
      });

      dispatch(returnSuccess(result.data?.message, 'UPDATE_PASSWORD'));
      cbSuccess(result);
    } catch (e) {
      dispatch({ type: FETCH_UPDATE_PASSWORD_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_UPDATE_PASSWORD_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_UPDATE_PASSWORD,
    });
  };

export const updateUser =
  ({ userName, name, file }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_UPDATE_USER });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_UPDATE_USER,
    });

    try {
      let res;
      if (!!userName || !!name) {
        const body = JSON.stringify({
          userName,
          name,
        });
        // TODO: Probleme avec body.append à fix !!
        res = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/users`,
          body,
          tokenConfig(),
        );
      }

      if (file) {
        const body2 = new FormData();
        body2.append('avatarImg', file);
        res = await axios.patch(
          `${process.env.REACT_APP_API_ENDPOINT}/users/changeAvatar`,
          body2,
          tokenConfig(true),
        );
      }

      dispatch({
        type: FETCH_UPDATE_USER_FULFILLED,
        user: res.data,
      });

      dispatch(returnSuccess('User has been updated', 'UPDATE_USER'));
    } catch (e) {
      console.log(e);
      dispatch({ type: FETCH_UPDATE_USER_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_UPDATE_PASSWORD_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_UPDATE_USER,
    });
  };

export const linkAccount =
  ({ email, password, confirmPassword }, cbSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: FETCH_LINK_ACCOUNT });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_LINK_ACCOUNT,
    });

    if (!email || !password || !confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_LINK_ACCOUNT,
      });
      return dispatch(
        returnErrors(
          'Please complete all fields !',
          0,
          'FETCH_LINK_ACCOUNT_ERROR',
        ),
      );
    }
    if (password !== confirmPassword) {
      dispatch({
        type: STOP_LOADING,
        actionName: FETCH_LINK_ACCOUNT,
      });
      return dispatch(
        returnErrors('Password are not same !', 0, 'FETCH_LINK_ACCOUNT_ERROR'),
      );
    }

    try {
      const body = JSON.stringify({
        email,
        password,
        confirmPassword,
      });
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/users/link/account`,
        body,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_LINK_ACCOUNT_FULFILLED,
        user: res.data.user,
      });

      dispatch(returnSuccess('User has been created !', 'LINK_WALLET'));

      cbSuccess(res.data);
    } catch (e) {
      dispatch({ type: FETCH_LINK_ACCOUNT_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          'FETCH_LINK_ACCOUNT_ERROR',
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LINK_ACCOUNT,
    });
  };

export const linkWallet = (signature, account, message) => async (dispatch) => {
  //   const { signMessageAsync } = useSignMessage(); A UTILISER DANS LE TRUC

  dispatch({ type: FETCH_LINK_EVM_WALLET });
  dispatch({
    type: START_LOADING,
    actionName: FETCH_LINK_EVM_WALLET,
  });

  if (!signature || !account || !message) {
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LINK_EVM_WALLET,
    });
    return dispatch({ type: FETCH_LINK_EVM_WALLET_ERROR });
  }

  try {
    const body = JSON.stringify({
      signature,
      address: account,
      message,
    });

    const getToken = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/link/wallet`,
      body,
      tokenConfig(),
    );

    const { accessToken, roles, user } = getToken.data;

    saveState('accessToken', accessToken);
    saveState('roles', roles);

    dispatch(returnSuccess('Wallet Linked !', 'LINK_WALLET'));

    dispatch({
      type: FETCH_LINK_EVM_WALLET_FULFILLED,
      currentAccount: account,
      accessToken,
      user,
    });
  } catch (e) {
    dispatch({ type: FETCH_LINK_EVM_WALLET_ERROR });
    dispatch(
      returnErrors(
        e?.response?.data?.message,
        e?.response?.status,
        FETCH_LINK_EVM_WALLET_ERROR,
      ),
    );
  }
  dispatch({
    type: STOP_LOADING,
    actionName: FETCH_LINK_EVM_WALLET,
  });
};

export const linkSolanaWallet =
  (signature, publicKey, message) => async (dispatch) => {
    dispatch({
      type: FETCH_LINK_SOLANA_WALLET,
    });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_LINK_SOLANA_WALLET,
    });
    try {
      const body = JSON.stringify({
        signature: bs58.encode(signature),
        publicKey,
        message,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/users/link/wallet/solana`,
        body,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_LINK_SOLANA_WALLET_FULFILLED,
        user: response.data.user,
      });

      dispatch(returnSuccess('Wallet Solana Linked !', 'LINK_SOLANA_WALLET'));
    } catch (e) {
      dispatch({ type: FETCH_LINK_SOLANA_WALLET_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          FETCH_LINK_SOLANA_WALLET,
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LINK_SOLANA_WALLET,
    });
  };

export const linkUltraWallet =
  ({ signature, publicKey, accountName, message }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_LINK_ULTRA_WALLET,
    });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_LINK_ULTRA_WALLET,
    });
    try {
      const body = JSON.stringify({
        signature,
        publicKey,
        accountName,
        message,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/users/link/wallet/ultra`,
        body,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_LINK_ULTRA_WALLET_FULFILLED,
        user: response.data.user,
      });

      dispatch(returnSuccess('Wallet Ultra Linked !', FETCH_LINK_ULTRA_WALLET));
    } catch (e) {
      dispatch({ type: FETCH_LINK_ULTRA_WALLET_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          FETCH_LINK_ULTRA_WALLET,
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LINK_ULTRA_WALLET,
    });
  };

export const linkWaxWallet =
  ({ signature, publicKeys, accountName, message }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_LINK_WAX_WALLET,
    });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_LINK_WAX_WALLET,
    });
    try {
      const body = JSON.stringify({
        signature,
        publicKeys,
        accountName,
        message,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/users/link/wallet/wax`,
        body,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_LINK_WAX_WALLET_FULFILLED,
        user: response.data.user,
      });

      dispatch(returnSuccess('Wallet Wax Linked !', FETCH_LINK_WAX_WALLET));
    } catch (e) {
      dispatch({ type: FETCH_LINK_WAX_WALLET_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          FETCH_LINK_WAX_WALLET,
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_LINK_WAX_WALLET,
    });
  };

export const unlinkEvmWallet =
  (cbSuccesss = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_UNLINK_EVM_WALLET,
    });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_UNLINK_EVM_WALLET,
    });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/users/unlink/wallet`,
        tokenConfig(),
      );

      const { user, accessToken } = res.data;

      saveState(accessToken, 'accessToken');

      dispatch({
        type: FETCH_UNLINK_EVM_WALLET_FULFILLED,
        user,
        accessToken,
        currentAccount: null,
      });

      dispatch(
        returnSuccess('Unlink EVM Wallet Succeeded !', FETCH_UNLINK_EVM_WALLET),
      );
      cbSuccesss(res.data);
    } catch (e) {
      dispatch({ type: FETCH_UNLINK_EVM_WALLET_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          FETCH_UNLINK_EVM_WALLET,
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_UNLINK_EVM_WALLET,
    });
  };

export const unlinkOtherWallet =
  (chain, cbSuccesss = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_UNLINK_OTHER_WALLET,
    });
    dispatch({
      type: START_LOADING,
      actionName: FETCH_UNLINK_OTHER_WALLET,
    });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/users/unlink/wallet/${chain}`,
        tokenConfig(),
      );

      dispatch({
        type: FETCH_UNLINK_OTHER_WALLET_FULFILLED,
        user: res.data,
      });

      dispatch(
        returnSuccess(
          `Unlink ${chain} Wallet Succeeded !`,
          FETCH_UNLINK_OTHER_WALLET,
        ),
      );
      cbSuccesss(res.data);
    } catch (e) {
      dispatch({ type: FETCH_UNLINK_OTHER_WALLET_ERROR });
      dispatch(
        returnErrors(
          e?.response?.data?.message,
          e?.response?.status,
          FETCH_UNLINK_OTHER_WALLET,
        ),
      );
    }
    dispatch({
      type: STOP_LOADING,
      actionName: FETCH_UNLINK_OTHER_WALLET,
    });
  };
