import { GET_ERRORS, CLEAR_ERRORS } from '../constants/actionTypes';

// CLEAR ERRORS
export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

// RETURN ERRORS
export const returnErrors = (msg, status = 0, id = null) => ({
  type: GET_ERRORS,
  payload: {
    msg: typeof msg === 'string' ? msg : 'An error occured',
    status,
    id,
  },
});
