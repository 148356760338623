import React from 'react';
import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Proptypes from 'prop-types';

import { parseTypeByAsset } from '../../../utils/string';
import { BuildingProptypes, LandProptypes } from '../../../constants/propTypes';
import BlueBox from '../../_atoms/BlueBox/blueBox';
import AssetImage from '../../_atoms/AssetImage/assetImage';

export default function LandDescriptionPanel({ land, craftedBuilding }) {
  const classes = useStyle();

  return (
    <BlueBox>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6">
          Description
        </Typography>
        <Typography align="justify">
          But I must explain to you how all this mistaken idea of denouncing
          pleasure and praising pain was born and I will give you a complete
        </Typography>
        <Box mt={1} />
        {craftedBuilding && (
          <Link
            component={RouterLink}
            to={`/inventory/${parseTypeByAsset(craftedBuilding)}/${
              craftedBuilding.tokenId
            }`}
            key={craftedBuilding.tokenId}
            underline="always"
            color="textPrimary"
          >
            <Box width={80}>
              <AssetImage src={craftedBuilding.imgUrl} clickable />
            </Box>
          </Link>
        )}
        <Box
          display="flex"
          gridRowGap="0.5rem"
          gridColumnGap="0.5rem"
          alignItems="center"
        >
          <Typography className={classes.titleAttribute} variant="h6">
            PLANET
          </Typography>
          <Typography color="initial">
            {land.planetName?.toUpperCase()}
          </Typography>
        </Box>
        <Box
          display="flex"
          gridRowGap="0.5rem"
          gridColumnGap="0.5rem"
          alignItems="center"
        >
          <Typography className={classes.titleAttribute} variant="h6">
            SIZE
          </Typography>
          <Typography color="initial">
            {land.size}x{land.size}
          </Typography>
        </Box>
      </div>
    </BlueBox>
  );
}
LandDescriptionPanel.propTypes = {
  land: LandProptypes,
  craftedBuilding: BuildingProptypes,
  userAddress: Proptypes.string,
};
const useStyle = makeStyles(({ palette, spacing }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: spacing(2),
    rowGap: spacing(),
  },
  title: {
    color: palette.primary.light,
    fontSize: '1.6rem',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    textTransform: 'Capitalize',
  },
  titleAttribute: {
    color: palette.primary.light,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontSize: '1.2rem',
  },
}));
