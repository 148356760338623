import { createTheme } from '@material-ui/core';

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      fontFamily: 'Montserrat, sans-serif',
      margin: 0,
    },
    body2: {
      fontSize: 12,
      fontFamily: 'Montserrat, sans-serif',
      margin: 0,
    },

    /* Suppress error "You are using the typography
     * variant caption which will be restyled in the next major release
     */
    useNextVariants: true,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'white',
  },
  palette: {
    primary: {
      light: 'rgb(15, 190, 255)',
      main: '#00649E',
      dark: 'rgba(15, 190, 255, 0.078)',
      contrastText: '#fff',
    },
    text: {
      primary: '#ffffff',
      secondary: '#00649E',
      blueDark: '#29405F',
      grey: '#394D67',
    },
    secondary: {
      light: '#18A0FB',
      main: 'rgb(7, 16, 32)',
      dark: 'rgba(15, 190, 255, 0.078)',
      contrastText: '#fff',
    },
    grey: {
      800: '#555555',
    },
    gradient: {
      main: 'linear-gradient(90deg, #FFCD62 0%, #FFCD62 0%, #736629 100%)',
      btn: 'linear-gradient(90deg, #FFCD6294 0%, #FFCD6294 0%, #73662994 100%)',
      btnBlue: 'linear-gradient(90deg, #1C88ED 0%, #00D8FF 100%)',
    },
    background: {
      default: '#fefefe',
    },
  },
  // sm down === <= sm
  // sm up === >= sm
  breakpoints: {
    values: {
      xs: 320, // Smartphone
      sm: 600, // Ipad
      md: 900, // Small PC
      lg: 1200, // Medium PC
      xl: 1536, // Large PC
    },
  },
  overrides: {
    // MuiTypography: {
    //   h2: {
    //     '@media (max-width:800px)': {
    //       fontSize: '3rem',
    //     },
    //   },
    //   h3: {
    //     fontWeight: 'bold',
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 'normal',
    //     letterSpacing: 'normal',
    //   },
    // },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'none',
        borderRadius: '2rem',
        color: 'white',
      },
      outlined: {
        borderRadius: '2rem',
        color: 'white',
        border: 'solid 2px #fcfcfc',
      },
      label: {
        fontFamily: 'Montserrat,Roboto,Helvetica, arial, sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        textTransform: 'none',
        fontSize: '1.6rem',
      },
      startIcon: {
        display: 'inline',
        position: 'relative',
        top: '4px',
      },
      iconSizeMedium: {
        label: {
          fontSize: 'initial',
        },
      },
    },
    MuiBadge: {
      badge: {
        fontSize: '1rem',
      },
    },
    MuiDivider: {
      root: {
        borderRadius: '6px',
        backgroundImage: 'linear-gradient(to bottom, #fc7756, #fc5564)',
        height: '5px',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: 'solid 1px rgba(15, 190, 255, 0.3)',
        borderRadius: '2rem',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '500',
        fontSize: '1.4rem',
        height: '5rem',
        color: '#bbb7b7',
      },
      input: {
        fontSize: '1.4rem',
        transform: 'translate(3px, -4px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.4rem',
        fontWeight: '500',
        color: '#bbb7b7',
      },
    },
    MuiTextField: {
      root: {
        '& input': {
          fontSize: '1.4rem',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(19px, 16px) scale(1)',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 'initial !important',
      },
    },
    MuiList: {
      root: {
        background: '#0D1826',
        paddingBottom: '0 !important',
      },
    },
  },
});

export default theme;
