import InventoryIcon from '../assets/icons/INVENTORY.svg';
import MarketplaceIcon from '../assets/icons/marketplace.svg';
import CollaborativeIcon from '../assets/icons/collab.svg';
import SettingsIcon from '../assets/icons/settings.svg';
import HomeIcon from '../assets/icons/HOME.svg';

import InventoryIconNS from '../assets/navigationNonSelected/INVENTORY.svg';
import MarketplaceIconNS from '../assets/navigationNonSelected/marketplace.svg';
import CollaborativeIconNS from '../assets/navigationNonSelected/collab.svg';
import SettingsIconNS from '../assets/navigationNonSelected/settings.svg';
import HomeIconNS from '../assets/navigationNonSelected/HOME.svg';

export const listDrawerHeader = [
  {
    key: 'home',
    to: '/#',
    onClick: () => {},
    text: 'Dashboard',
  },
];

export const listInventoryHeader = [
  {
    key: 'Avatars',
    to: '/inventory/avatars',
    text: 'AVATARS',
  },
  {
    key: 'Lands',
    to: '/inventory/lands',
    text: 'LANDS',
  },
  {
    key: 'Buildings',
    to: '/inventory/buildings',
    text: 'BUILDINGS',
  },
  {
    key: 'Weapons',
    to: '/inventory/weapons',
    text: 'WEAPONS',
  },
  {
    key: 'Consumables',
    to: '/inventory/consumables',
    text: 'CONSUMABLES',
  },
];

export const listHomeHeader = [
  {
    key: 'RoadToGenesis',
    to: '/',
    search: '/',
    text: 'ROAD TO GENESIS',
  },
  {
    key: 'Leaderboard',
    to: '/leaderboard',
    search: '/leaderboard',
    text: 'LEADERBOARD',
  },
];

export const listMarketplaceHeader = [
  {
    key: 'Buy',
    to: '/marketplace/buildings',
    search: '/marketplace/buildings',
    text: 'BUY',
  },
  {
    key: 'Sell',
    to: '/marketplace/sell/listed',
    search: '/marketplace/sell',
    text: 'SELL',
  },
];

export const listMarketplaceBuy = [
  {
    key: 'buildings',
    to: '/marketplace/buildings',
    text: 'BUILDINGS',
  },
  {
    key: 'weapons',
    to: '/marketplace/weapons',
    text: 'WEAPONS',
  },
];

export const listMarketplaceSell = [
  {
    key: 'listed',
    to: '/marketplace/sell/listed',
    text: 'LISTED',
  },
  {
    key: 'no listed',
    to: '/marketplace/sell/noListed',
    text: 'NO LISTED',
  },
];

export const listNavigationHeader = [
  {
    key: 'Home',
    to: '/',
    text: 'HOME',
    iconPath: HomeIcon,
    iconPathNonSelected: HomeIconNS,
  },
  {
    key: 'Inventory',
    to: '/inventory/avatars',
    text: 'INVENTORY',
    iconPath: InventoryIcon,
    iconPathNonSelected: InventoryIconNS,
  },
  {
    key: 'Marketplace',
    to: 'https://tokentrove.com/collection/OxyaOrigin',
    text: 'MARKETPLACE',
    iconPath: MarketplaceIcon,
    iconPathNonSelected: MarketplaceIconNS,
  },
  {
    key: 'collaborate',
    to: '/collaborate',
    text: 'COLLABORATIVE',
    iconPath: CollaborativeIcon,
    iconPathNonSelected: CollaborativeIconNS,
  },
  /* {
    key: 'mission',
    to: '/mission',
    text: 'MISSIONS',
    iconPath: MissionIcon,
    iconPathNonSelected: MissionIconNS,
  }, */
  {
    key: 'account',
    to: '/account',
    text: 'ACCOUNT',
    iconPath: SettingsIcon,
    iconPathNonSelected: SettingsIconNS,
  },
];
