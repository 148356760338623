import {
  FETCH_LEADERBOARD_GENESIS_USER_FULFILLED,
  FETCH_LEADERBOARD_GENESIS_USER_ERROR,
  FETCH_LEADERBOARD_KEYS_USER_FULFILLED,
  FETCH_LEADERBOARD_KEYS_USER_ERROR,
  FETCH_LEADERBOARD_GENESIS_FULFILLED,
  FETCH_LEADERBOARD_GENESIS_ERROR,
  FETCH_LEADERBOARD_KEYS_FULFILLED,
  FETCH_LEADERBOARD_KEYS_ERROR,
  FETCH_LEADERBOARD_CITIZEN_USER_FULFILLED,
  FETCH_LEADERBOARD_CITIZEN_FULFILLED,
  FETCH_LEADERBOARD_CITIZEN_USER_ERROR,
  FETCH_LEADERBOARD_CITIZEN_ERROR,
} from '../constants/actionTypes';

const initialState = {
  genesis: [],
  keys: [],
  citizen: [],
  scoreUserGenesis: {
    rank: 0,
    score: 0,
  },
  scoreUserKeys: {
    rank: 0,
    score: 0,
  },
  scoreUserCitizen: {
    rank: 0,
    score: 0,
  },
  fetchedGenesis: false,
  fetchedKeys: false,
  fetchedCitizen: false,
  fetchedUserGenesis: false,
  fetchedUserKeys: false,
  fetchedUserCitizen: false,
  fetching: false,
};

// eslint-disable-next-line default-param-last
export default function avatarReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD_GENESIS_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        scoreUserGenesis: {
          score: action.leaderboard.score,
          rank: action.leaderboard.rank,
        },
        fetchedUserGenesis: true,
      };
    case FETCH_LEADERBOARD_KEYS_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        scoreUserKeys: {
          score: action.leaderboard.score,
          rank: action.leaderboard.rank,
        },
        fetchedUserKeys: true,
      };
    case FETCH_LEADERBOARD_CITIZEN_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        scoreUserCitizen: {
          score: action.leaderboard.score,
          rank: action.leaderboard.rank,
        },
        fetchedUserCitizen: true,
      };
    case FETCH_LEADERBOARD_GENESIS_FULFILLED:
      return {
        ...state,
        fetchedGenesis: true,
        fetching: false,
        genesis: action.leaderboard,
      };
    case FETCH_LEADERBOARD_KEYS_FULFILLED:
      return {
        ...state,
        fetchedKeys: true,
        fetching: false,
        keys: action.leaderboard,
      };
    case FETCH_LEADERBOARD_CITIZEN_FULFILLED:
      return {
        ...state,
        fetchedCitizen: true,
        fetching: false,
        citizen: action.leaderboard,
      };
    case FETCH_LEADERBOARD_GENESIS_USER_ERROR:
      return {
        ...state,
        fetching: false,
        fetchedUserGenesis: false,
      };
    case FETCH_LEADERBOARD_KEYS_USER_ERROR:
      return {
        ...state,
        fetching: false,
        fetchedUserKeys: false,
      };
    case FETCH_LEADERBOARD_CITIZEN_USER_ERROR:
      return {
        ...state,
        fetching: false,
        fetchedUserCitizen: false,
      };
    case FETCH_LEADERBOARD_GENESIS_ERROR:
      return {
        ...state,
        fetching: false,
        fetchedGenesis: false,
      };
    case FETCH_LEADERBOARD_KEYS_ERROR:
      return {
        ...state,
        fetching: false,
        fetchedKeys: false,
      };
    case FETCH_LEADERBOARD_CITIZEN_ERROR:
      return {
        ...state,
        fetching: false,
        fetchedCitizen: false,
      };
    default:
      return state;
  }
}
