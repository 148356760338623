import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import AssetList from '../../_molecules/AssetList/assetList';
import EmptyResult from '../../_molecules/EmptyResult/emptyResult';
import ConnectWallet from '../../_molecules/ConnectWallet/connectWallet';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';
import { isActionLoading } from '../../../redux/selectors/loadingSelectors';
import { FETCH_LANDS } from '../../../redux/constants/actionTypes';
import { getLands } from '../../../redux/actions/lands';
import Filters from '../../_organisms/Filters/filters';
import { landFilters } from '../../../constants/filters';
import { useQueryFilters } from '../../../hooks/useQueryFilters';
import { selectFilteredLands } from '../../../redux/selectors/landSlectors';

export default function InventoryLands() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const fetched = useSelector((state) => state.land.fetched);
  const isLoading = useSelector((state) => isActionLoading(state, FETCH_LANDS));
  const user = useSelector((state) => state.user.user);

  const filters = useMemo(() => landFilters.map((f) => f.slug), []);
  const { selectedFilters } = useQueryFilters({
    filters,
  });

  const lands = useSelector((state) =>
    selectFilteredLands(state, selectedFilters),
  );

  const handleClickLogin = () => {
    history.push('/auth/login');
  };

  const accessToken = useSelector((state) => state.user.accessToken);

  useEffect(async () => {
    if (accessToken && !fetched && user?.walletAddress) {
      dispatch(getLands());
    }
  }, [accessToken, fetched]);

  if (!accessToken) {
    return <ConnectWallet onLogin={handleClickLogin} text="Lands" />;
  }

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
          <Typography variant="h4" align="left" color="primary">
            {`Owned (${lands?.length || 0})`}
          </Typography>
          <Box ml={4}>
            <Link
              underline="always"
              component="button"
              onClick={() =>
                window.open('https://opensea.io/collection/gatesofoxya-lands')
              }
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h5" className={classes.buyText}>
                  For Sale On Opensea
                  <ArrowRightAltIcon style={{ marginLeft: 10 }} />
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
        <Box display="flex" gridColumnGap={20}>
          <Filters filters={landFilters} />
        </Box>
      </Box>
      <Box mb={3} />
      {user?.walletAddress == null && (
        <EmptyResult text="You don't have wallet EVM (ETH, BSC, ...) linked to your account">
          <Box mt={4} />
          <ButtonPerso
            shrink
            onClick={() => {
              history.push('/account');
            }}
          >
            Link an EVM Wallet
          </ButtonPerso>
        </EmptyResult>
      )}
      {fetched && user?.walletAddress && lands.length === 0 ? (
        <EmptyResult text="You don t have any land">
          <Box mt={4} />
          <ButtonPerso
            shrink
            onClick={() =>
              window.open('https://opensea.io/collection/gatesofoxya-lands')
            }
          >
            BUY A LAND
          </ButtonPerso>
        </EmptyResult>
      ) : (
        <AssetList
          assets={lands}
          assetType="lands"
          isLoading={isLoading}
          userAddress={user?.walletAddress}
        />
      )}
    </div>
  );
}

const useStyle = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(3),
    paddingTop: spacing(2.8),
    width: '100%',
    height: '100%',
  },
  buyText: {
    color: palette.text.secondary,
    fontSize: '1.2rem',
    textDecoration: 'underline',
    display: 'flex',
    textTransform: 'uppercase',
    alignItems: 'center',
    '&:hover': {
      textDecorationColor: palette.primary.light,
      '& svg': {
        color: palette.primary.light,
      },
    },
  },
}));
