import {
  FETCH_VALOR_CREST_FULFILLED,
  FETCH_VALOR_CREST_ERROR,
} from '../constants/actionTypes';

const initialState = {
  items: [],
  fetched: false,
};

// eslint-disable-next-line default-param-last
export default function valorCrestReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VALOR_CREST_FULFILLED:
      return {
        ...state,
        items: action.items,
        fetched: true,
      };
    case FETCH_VALOR_CREST_ERROR:
      return {
        ...state,
        fetched: true,
      };
    default:
      return state;
  }
}
