import {
  LOGOUT,
  GET_ACCOUNT,
  GET_ACCOUNT_ERROR,
  LOAD_USER_ERROR,
  LOAD_USER,
  LOAD_USER_FULFILLED,
  FETCH_FORGET_PASSWORD,
  FETCH_FORGET_PASSWORD_FULFILLED,
  FETCH_GET_USER_BY_TOKEN,
  FETCH_GET_USER_BY_TOKEN_ERROR,
  FETCH_GET_USER_BY_TOKEN_FULFILLED,
  FETCH_LINK_ACCOUNT,
  FETCH_LINK_ACCOUNT_ERROR,
  FETCH_LINK_ACCOUNT_FULFILLED,
  FETCH_LINK_EVM_WALLET,
  FETCH_LINK_EVM_WALLET_ERROR,
  FETCH_LINK_EVM_WALLET_FULFILLED,
  FETCH_LOGIN_WEB2,
  FETCH_LOGIN_WEB2_ERROR,
  FETCH_LOGIN_WEB2_FULFILLED,
  FETCH_LOGIN_WEB3,
  FETCH_LOGIN_WEB3_ERROR,
  FETCH_LOGIN_WEB3_FULFILLED,
  FETCH_RECOVER_PASSWORD,
  FETCH_RECOVER_PASSWORD_ERROR,
  FETCH_RECOVER_PASSWORD_FULFILLED,
  FETCH_REGISTER_WEB2,
  FETCH_REGISTER_WEB2_ERROR,
  FETCH_REGISTER_WEB2_FULFILLED,
  FETCH_SIGN_IN,
  FETCH_SIGN_IN_ERROR,
  FETCH_SIGN_IN_FULFILLED,
  FETCH_UNLINK_EVM_WALLET,
  FETCH_UNLINK_EVM_WALLET_ERROR,
  FETCH_UNLINK_EVM_WALLET_FULFILLED,
  FETCH_UPDATE_PASSWORD,
  FETCH_UPDATE_PASSWORD_ERROR,
  FETCH_UPDATE_PASSWORD_FULFILLED,
  FETCH_UPDATE_USER,
  FETCH_UPDATE_USER_ERROR,
  FETCH_UPDATE_USER_FULFILLED,
  FETCH_LINK_SOLANA_WALLET_ERROR,
  FETCH_LINK_ULTRA_WALLET_ERROR,
  FETCH_LINK_WAX_WALLET_ERROR,
  FETCH_LINK_SOLANA_WALLET_FULFILLED,
  FETCH_LINK_ULTRA_WALLET_FULFILLED,
  FETCH_LINK_WAX_WALLET_FULFILLED,
  FETCH_LINK_SOLANA_WALLET,
  FETCH_LINK_ULTRA_WALLET,
  FETCH_LINK_WAX_WALLET,
  FETCH_UNLINK_OTHER_WALLET,
  FETCH_UNLINK_OTHER_WALLET_FULFILLED,
  FETCH_UNLINK_OTHER_WALLET_ERROR,
} from '../constants/actionTypes';

import { loadState, removeState } from '../../store/localStorage';

const initialState = {
  currentAccount: null,
  user: null,
  fetching: false,
  fetched: false,
  isLoading: false,
  isAdmin: false,
  message: '',
  accessToken: loadState('accessToken'),
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOGIN_WEB3:
    case FETCH_LOGIN_WEB2:
    case FETCH_REGISTER_WEB2:
    case FETCH_SIGN_IN:
    case FETCH_UPDATE_PASSWORD:
    case FETCH_RECOVER_PASSWORD:
    case FETCH_UPDATE_USER:
    case FETCH_LINK_ACCOUNT:
    case FETCH_LINK_EVM_WALLET:
    case FETCH_LINK_SOLANA_WALLET:
    case FETCH_LINK_ULTRA_WALLET:
    case FETCH_LINK_WAX_WALLET:
    case FETCH_UNLINK_EVM_WALLET:
    case FETCH_UNLINK_OTHER_WALLET:
    case FETCH_FORGET_PASSWORD:
    case FETCH_GET_USER_BY_TOKEN:
    case LOAD_USER:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case FETCH_FORGET_PASSWORD_FULFILLED:
    case FETCH_RECOVER_PASSWORD_FULFILLED:
    case FETCH_GET_USER_BY_TOKEN_FULFILLED:
    case FETCH_REGISTER_WEB2_FULFILLED:
      return {
        ...state,
        isLoading: false,
        fetching: false,
        fetched: true,
      };
    case FETCH_SIGN_IN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        fetching: false,
        fetched: true,
        message: action.payload,
      };
    case GET_ACCOUNT:
      return {
        ...state,
        currentAccount: action.account,
      };
    case FETCH_LOGIN_WEB3_FULFILLED:
    case LOAD_USER_FULFILLED:
      return {
        ...state,
        currentAccount: action.currentAccount,
        accessToken: loadState('accessToken'),
        isLoading: false,
        fetching: false,
        fetched: true,
        user: action.user,
      };
    case FETCH_LINK_EVM_WALLET_FULFILLED:
    case FETCH_UNLINK_EVM_WALLET_FULFILLED:
      return {
        ...state,
        currentAccount: action.currentAccount,
        accessToken: action.accessToken,
        isLoading: false,
        fetching: false,
        fetched: true,
        user: action.user,
      };
    case FETCH_LOGIN_WEB2_FULFILLED:
    case FETCH_UNLINK_OTHER_WALLET_FULFILLED:
    case FETCH_LINK_SOLANA_WALLET_FULFILLED:
    case FETCH_LINK_ULTRA_WALLET_FULFILLED:
    case FETCH_LINK_WAX_WALLET_FULFILLED:
    case FETCH_LINK_ACCOUNT_FULFILLED:
    case FETCH_UPDATE_PASSWORD_FULFILLED:
    case FETCH_UPDATE_USER_FULFILLED:
      return {
        ...state,
        accessToken: loadState('accessToken'),
        isLoading: false,
        fetching: false,
        fetched: true,
        user: action.user,
      };
    case FETCH_UPDATE_PASSWORD_ERROR:
    case FETCH_UPDATE_USER_ERROR:
    case FETCH_RECOVER_PASSWORD_ERROR:
    case FETCH_LINK_EVM_WALLET_ERROR:
    case FETCH_LINK_SOLANA_WALLET_ERROR:
    case FETCH_LINK_ULTRA_WALLET_ERROR:
    case FETCH_LINK_WAX_WALLET_ERROR:
    case FETCH_UNLINK_EVM_WALLET_ERROR:
    case FETCH_UNLINK_OTHER_WALLET_ERROR:
    case FETCH_LINK_ACCOUNT_ERROR:
    case FETCH_GET_USER_BY_TOKEN_ERROR:
    case FETCH_REGISTER_WEB2_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
      };
    case GET_ACCOUNT_ERROR:
    case LOAD_USER_ERROR:
    case FETCH_LOGIN_WEB3_ERROR:
    case FETCH_LOGIN_WEB2_ERROR:
    case FETCH_SIGN_IN_ERROR:
    case LOGOUT:
      removeState('accessToken');
      removeState('roles');
      return {
        ...state,
        fetching: false,
        user: null,
        currentAccount: null,
        accessToken: null,
      };
    default:
      return state;
  }
}
