import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';

import BlueBox from '../../_atoms/BlueBox/blueBox';

export default function AvatarDescriptionPanel({
  className,
  planetName,
  tribe,
}) {
  const classes = useStyle();

  return (
    <BlueBox className={className}>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6">
          Description
        </Typography>
        <Typography align="justify">
          But I must explain to you how all this mistaken idea of denouncing
          pleasure and praising pain was born and I will give you a complete
        </Typography>
        <Box mt={1} />
        <Box display="flex" gridRowGap="0.5rem" gridColumnGap="0.5rem">
          <Typography
            color="textSecondary"
            variant="h6"
            className={classes.titleAttribute}
          >
            PLANET:
          </Typography>
          <Typography color="initial" className={classes.attribute}>
            {planetName.toUpperCase()}
          </Typography>
        </Box>
        <Box display="flex" gridRowGap="0.5rem" gridColumnGap="0.5rem">
          <Typography
            color="textSecondary"
            variant="h6"
            className={classes.titleAttribute}
          >
            TRIBE:
          </Typography>
          <Typography color="initial" className={classes.attribute}>
            {tribe.toUpperCase()}
          </Typography>
        </Box>
      </div>
    </BlueBox>
  );
}
AvatarDescriptionPanel.propTypes = {
  planetName: Proptypes.string,
  tribe: Proptypes.string,
  className: Proptypes.string,
};

AvatarDescriptionPanel.defaultProps = {
  planetName: '',
  tribe: '',
};
const useStyle = makeStyles(({ spacing, palette }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: spacing(2),
    rowGap: spacing(1),
  },
  title: {
    color: palette.primary.light,
    fontSize: '1.6rem',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    textTransform: 'Capitalize',
  },
  titleAttribute: {
    color: palette.primary.light,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontSize: '1.2rem',
  },
  attribute: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    textAlign: 'left',
  },
}));
