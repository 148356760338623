import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Resource from '../../_atoms/Resource/resource';

export default function ResourcesHeadband() {
  const resources = useSelector((state) => state.user.user?.resources);
  const tokens = useSelector((state) => state.user.user?.tokens);
  const allResources = useMemo(
    () => resources?.concat(tokens),
    [resources, tokens],
  );
  const classes = useStyle();
  return (
    <div className={classes.container}>
      {allResources &&
        allResources.map((resource, index) => (
          <Resource
            className={cx(index !== 0 ? classes.border : '')}
            resource={resource}
            flat
            key={`resource-${resource.type}`}
          />
        ))}
    </div>
  );
}

const useStyle = makeStyles(({ palette }) => ({
  container: {
    display: 'grid',
    marginRight: 'auto',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gap: '1rem',
  },
  border: {
    // paddingRight: spacing(3),
    // marginTop: spacing(3),
    // marginBottom: spacing(3),
    borderLeft: `1px solid ${palette.text.grey}`,
  },
}));
