import alkar from '../assets/resources/alkar.png';
import electrium from '../assets/resources/electrium.png';
import steelvar from '../assets/resources/steelvar.png';
import tokenOgem from '../assets/resources/tokenOgem.png';
import tokenOxyz from '../assets/resources/tokenOxyz.png';

export const resourcesUrls = {
  Alkar: alkar,
  Electrium: electrium,
  Steelvar: steelvar,
  Siknar: steelvar,
  Oxyagem: tokenOgem,
  Oxyz: tokenOxyz,
};
