import React from 'react';
import { makeStyles } from '@material-ui/core';
import Proptypes from 'prop-types';
import cx from 'classnames';

export default function AssetImage({
  src,
  clickable,
  isSelected,
  children,
  noBorder,
  borderRadius,
}) {
  const classes = useStyle({ clickable, noBorder, borderRadius });

  return (
    <div
      className={cx(classes.wrapper, isSelected ? classes.isSelected : null)}
    >
      <div className={cx(classes.container)}>
        <div style={{ marginTop: '90%' }} />
        <div className={classes.imageContainer}>
          <img
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            src={src}
            alt="blank"
          />
        </div>
      </div>
      {children}
    </div>
  );
}

AssetImage.propTypes = {
  src: Proptypes.string,
  clickable: Proptypes.bool,
  isSelected: Proptypes.bool,
  noBorder: Proptypes.bool,
  borderRadius: Proptypes.bool,
  children: Proptypes.node,
};

AssetImage.defaultProps = {
  src: '',
  clickable: false,
  isSelected: false,
  noBorder: false,
  borderRadius: false,
  children: null,
};

const useStyle = makeStyles(({ palette }) => ({
  wrapper: {
    // borderRadius: '5px',
    border: ({ noBorder }) => {
      if (noBorder) {
        return '';
      }
      return `1px solid ${palette.secondary.light}`;
    },

    background: ({ noBorder }) => {
      if (noBorder) {
        return 'transparent';
      }
      return palette.secondary.main;
    },

    borderRadius: ({ noBorder }) => {
      if (noBorder) {
        return 0;
      }
      return '2rem';
    },
  },
  imageContainer: {
    position: 'absolute',
    // height: 'fit-content',
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:hover': {
      transform: ({ clickable }) => (clickable ? 'scale(1.05)' : ''),
    },
    '& img': {
      borderRadius: ({ noBorder }) => {
        if (noBorder) {
          return 0;
        }
        return '2rem';
      },
    },
  },
  isSelected: {
    border: `1px dash ${palette.primary.light}`,
    boxShadow: `0px 0px 10px 2px ${palette.primary.light}`,
  },
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
  },
}));
