import { CLEAR_SUCCESS, GET_SUCCESS } from '../constants/actionTypes';

// CLEAR ERRORS
export const clearSuccess = () => ({
  type: CLEAR_SUCCESS,
});

// RETURN ERRORS
export const returnSuccess = (msg, id = null) => ({
  type: GET_SUCCESS,
  payload: { msg, id },
});
