import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';

export default function DetailPageSkeleton() {
  const classes = useStyle();
  return (
    <Grid container className={classes.detail} spacing={2}>
      <Grid item xs={4}>
        <Skeleton
          sx={{ bgcolor: 'grey.900' }}
          variant="rectangular"
          width="100%"
          height={400}
        />
      </Grid>
      <Grid item xs={8}>
        <Skeleton
          sx={{ bgcolor: 'grey.900' }}
          variant="rectangular"
          width="100%"
          height={400}
        />
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => ({
  detail: {
    height: '100%',
  },
}));
