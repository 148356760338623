import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';

export default function EmptyResult({ text, children }) {
  const classes = useStyle();

  return (
    <div className={classes.empty}>
      <Typography variant="h4" color="textSecondary">
        {text}
      </Typography>
      {children}
    </div>
  );
}

EmptyResult.propTypes = {
  text: Proptypes.string,
  children: Proptypes.node,
};

EmptyResult.defaultProps = {
  text: '',
  children: null,
};

const useStyle = makeStyles(() => ({
  empty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '80%',
  },
}));
