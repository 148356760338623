import React from 'react';
import {
  ButtonBase,
  makeStyles,
  Typography,
  Checkbox,
  Box,
} from '@material-ui/core';
import Proptypes from 'prop-types';

export default function Filter({
  filterChoices,
  selectedChoices,
  onClickItem,
}) {
  const classes = useStyle();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {filterChoices.map((filterChoice) => (
        <ButtonBase
          key={filterChoice.key}
          className={classes.button}
          onClick={() => onClickItem(filterChoice.key)}
        >
          <Checkbox
            checked={selectedChoices.includes(filterChoice.key)}
            color="primary"
          />
          <Typography variant="h6" color="textSecondary">
            {filterChoice.name}
          </Typography>
        </ButtonBase>
      ))}
    </Box>
  );
}

Filter.propTypes = {
  filterChoices: Proptypes.array,
  selectedChoices: Proptypes.array,
  onClickItem: Proptypes.func,
};

Filter.defaultProps = {
  onClickItem: () => {},
};

const useStyle = makeStyles(() => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  panel: {
    background:
      'transparent radial-gradient(closest-side at 11% 79%, #0D1826 0%, #000000 100%) 0% 0% no-repeat padding-box',
    border: '0.5px solid #00649E',
    minWidth: 300,
  },
}));
