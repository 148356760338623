import { withStyles } from '@material-ui/core';

export default withStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'initial',
    overflow: 'scroll',
  },
  paper: {
    // maxWidth: '50rem',
    display: 'flex',
    borderRadius: '3rem',
    minWidth: 375,
    flexDirection: 'column',
    height: 'auto',
    background: theme.palette.secondary.main,
    boxShadow: '0 0 30px #27c6f74d',
    border: `1px solid ${theme.palette.secondary.light}`,
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
  top: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '3rem',
  },
  titleTop: {
    color: 'white',
    fontSize: '1.6rem',
    textAlign: 'center',
    fontFamily: 'Akira',
  },
  textTop: {
    color: 'white',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  body: {
    margin: '2rem 0',
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  btnText: {
    color: 'white',
    textTransform: 'initial',
  },
  btnCancel: {
    background: 'linear-gradient(90deg, #9B9B9B 0%, #3C4446 100%)',
    borderColor: '#5A5A5A',
    width: '12rem',
  },
  btnConfirm: {
    background: theme.palette.gradient.btnBlue,
    borderColor: '#00FDFF',
    width: '12rem',
  },
}));
