import React from 'react';
import Proptypes from 'prop-types';
import { Grid, makeStyles, Link, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import {
  AvatarProptypes,
  BuildingProptypes,
  WeaponProptypes,
} from '../../../constants/propTypes';
import { parseTypeByAsset } from '../../../utils/string';
import Loader from '../../_atoms/Loader/loader';
import AssetItem from '../../_atoms/AssetItem/assetItem';

export default function AssetList({
  assets,
  assetType,
  isLoading,
  to,
  userAddress,
}) {
  const classes = useStyle();

  return (
    <Grid container spacing={4}>
      {isLoading ? (
        <Box mt={30} className={classes.loader}>
          <Loader size={40} />
        </Box>
      ) : (
        assets &&
        assets.map((asset) => (
          <Grid
            item
            xs={6}
            md={3}
            key={asset.tokenId}
            style={{ height: '100%' }}
          >
            {assetType === 'valorCrests' ? (
              <AssetItem
                asset={asset}
                assetType={assetType}
                userAddress={userAddress}
              />
            ) : (
              <Link
                underline="none"
                component={RouterLink}
                to={`/${to}/${assetType || parseTypeByAsset(asset)}/${
                  asset.tokenId
                }`}
              >
                <AssetItem
                  asset={asset}
                  assetType={assetType}
                  userAddress={userAddress}
                />
              </Link>
            )}
          </Grid>
        ))
      )}
    </Grid>
  );
}

AssetList.propTypes = {
  assets: Proptypes.arrayOf(
    Proptypes.oneOfType([BuildingProptypes, AvatarProptypes, WeaponProptypes]),
  ),
  assetType: Proptypes.string,
  isLoading: Proptypes.bool,
  to: Proptypes.string,
  userAddress: Proptypes.string,
};

AssetList.defaultProps = {
  assets: [],
  assetType: null,
  isLoading: false,
  to: 'inventory',
};

const useStyle = makeStyles(() => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
