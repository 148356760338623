import React from 'react';
import cx from 'classnames';
import { Box, Chip, Typography, makeStyles } from '@material-ui/core';
import Proptypes from 'prop-types';
import ConnectWallet from '../../_atoms/ConnectWallet/connect-wallet';
import { parseOtherWallet, ucFirst } from '../../../utils/string';
import { sizeMobileForAccountPage } from '../../../constants/size';

export default function LinkUnlinkWallet({
  otherWallet,
  chain,
  email,
  onLink,
  onUnlink,
}) {
  const classes = useStyle({});

  const handleClick = () => {
    if (otherWallet?.address) {
      onUnlink(chain, otherWallet);
    } else {
      onLink(chain, otherWallet);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap="1rem"
      gridColumnGap="1rem"
      key={chain}
    >
      <Typography variant="body1" className={classes.label}>
        {ucFirst(chain)} Wallet
        {chain === 'evm' && (
          <span style={{ fontSize: '1rem' }}> (ETH, BNB, AVAX, ...)</span>
        )}
      </Typography>
      {chain.indexOf('evm') !== -1 && !otherWallet ? (
        <ConnectWallet
          className={classes.walletConnect}
          classNameBtn={classes.btnWalletConnect}
        />
      ) : (
        <Chip
          label={
            otherWallet
              ? parseOtherWallet(otherWallet)
              : `Connect ${ucFirst(chain)} Wallet`
          }
          className={cx(
            classes.chip,
            classes.chipWallet,
            otherWallet && classes.chipConnected,
            !email && classes.chipDisabled,
          )}
          onClick={handleClick}
        />
      )}
    </Box>
  );
}

LinkUnlinkWallet.propTypes = {
  chain: Proptypes.string,
  otherWallet: Proptypes.shape({
    chain: Proptypes.string,
    address: Proptypes.string,
    addressName: Proptypes.string,
    name: Proptypes.string,
  }),
  email: Proptypes.string,
  onLink: Proptypes.func,
  onUnlink: Proptypes.func,
};

LinkUnlinkWallet.defaultProps = {
  otherWallet: null,
  email: '',
  onLink: () => {},
  onUnlink: () => {},
  chain: '',
};

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  label: {
    fontWeight: 600,
    fontSize: '1.4rem',
    marginLeft: '1rem',
    textAlign: 'left',
  },
  walletConnect: {
    width: '17rem !important',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '150px !important',
    },
  },
  btnWalletConnect: {
    '& span': {
      fontSize: '1.2rem',
    },
    border: '1px solid #18A0FB',
    background: 'rgba(15, 190, 255, 0.078)',
    width: '17rem',
    transition: '0.2s all ease-in-out',
    height: '4rem',
    '&:hover': {
      color: '#18A0FB',
      background: 'rgba(15, 190, 255, 0.078)',
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '16rem',
    },
  },
  chip: {
    color: 'white',
    fontWeight: 700,
    fontSize: '1.2rem',
    height: '4rem',
    width: '17rem',
    borderRadius: '2rem',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '16rem',
    },
  },
  chipWallet: {
    background: '#0F1D15',
    border: `1px solid ${palette.secondary.light}`,
    transition: '0.2s all ease-in-out',

    '&:hover': {
      background: '#0F1D15',
      '& span': {
        color: '#18A0FB',
      },
    },
    '&:focus': {
      background: '#0F1D15',
    },
  },
  chipConnected: {
    border: '1px solid #00FC0A',
  },
  chipDisabled: {
    pointerEvents: 'none',
    cursor: 'initial',
    border: '1px solid #cccccc',
    background: 'rgba(15, 29, 21, 0.7)',
    color: '#cccccc',
  },
}));
