import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { AvatarProptypes } from '../../../constants/propTypes';

import BlueBox from '../../_atoms/BlueBox/blueBox';

export default function AvatarSummaryPannel({ avatar, className }) {
  const classes = useStyle();

  return (
    <Box className={cx(classes.root, className)}>
      <BlueBox className={classes.properties}>
        <Typography variant="h2" align="left">
          Properties
        </Typography>
        <Box className={classes.wrapperContentProperties}>
          {avatar &&
            avatar?.attributes.map((elem) => (
              <Box className={classes.propertie}>
                <Typography
                  align="left"
                  variant="body1"
                  className={classes.propertieTitle}
                >
                  {elem.trait_type}
                </Typography>
                <Typography
                  align="left"
                  variant="body1"
                  className={classes.propertieValue}
                >
                  {elem.value}
                </Typography>
              </Box>
            ))}
        </Box>
      </BlueBox>
    </Box>
  );
}
AvatarSummaryPannel.propTypes = {
  avatar: AvatarProptypes,
  className: Proptypes.string,
};

AvatarSummaryPannel.defaultProps = {
  avatar: {},
  className: '',
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {},
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    color: 'white',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    fontSize: '2.4rem',
  },
  squareWithBorder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
  },
  link: {
    transition: '0.2s opacity ease-in-out',
    color: palette.primary.light,
    '& img': {
      width: 20,
    },
  },
  properties: {
    marginTop: '2rem',
    padding: '2rem 1rem',
    '& h2': {
      textTransform: 'Capitalize',
      color: palette.primary.light,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.6rem',
      fontWeight: 700,
    },
    [breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  wrapperContentProperties: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2rem',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  propertie: {
    width: '45%',
  },
  propertieTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    color: palette.primary.light,
    textTransform: 'uppercase',
  },
  propertieValue: {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
  },
}));
