import Proptypes from 'prop-types';

export const LeaderboardProptypes = Proptypes.shape({
  userName: Proptypes.string,
  email: Proptypes.string,
  playfabId: Proptypes.string,
  rank: Proptypes.number,
  score: Proptypes.number,
});

export const AvatarProptypes = Proptypes.shape({
  imgUrl: Proptypes.string,
  tokenId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  type: Proptypes.string,
  attributes: Proptypes.arrayOf(
    Proptypes.shape({
      trait_type: Proptypes.string,
      value: Proptypes.string,
    }),
  ),
});

export const KeysProptypes = Proptypes.shape({
  imgUrl: Proptypes.string,
  tokenId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  name: Proptypes.string,
  properties: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
      value: Proptypes.string,
      static: Proptypes.bool,
    }),
  ),
});

export const BuildingProptypes = Proptypes.shape({
  tokenId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  burned: Proptypes.bool,
  ownerAddress: Proptypes.string,
  type: Proptypes.string,
  level: Proptypes.number,
  linkedLandTokenId: Proptypes.number,
  craftingBuildingTokenId: Proptypes.number,
  leveling: Proptypes.shape({
    active: Proptypes.bool,
    endsAt: Proptypes.number,
    startsAt: Proptypes.number,
    targetLevel: Proptypes.number,
    craftingBuildingTokenId: Proptypes.number,
    isCraft: Proptypes.bool,
  }),
  crafting: Proptypes.shape({
    active: Proptypes.bool,
    endsAt: Proptypes.number,
    tokenId: Proptypes.number,
    startsAt: Proptypes.number,
    ownerAddress: Proptypes.string,
  }),
  craftingProduction: Proptypes.number,
  imgUrl: Proptypes.string,
  extracting: Proptypes.shape({
    activatedAt: Proptypes.number,
    emptiedAt: Proptypes.number,
    extractionType: Proptypes.string,
  }),
  collaborativeSettings: Proptypes.shape({
    endsAt: Proptypes.number,
    startsAt: Proptypes.number,
    commission: Proptypes.number,
    isAvailable: Proptypes.bool,
  }),

  collaborativeCrafts: Proptypes.arrayOf(
    Proptypes.shape({
      endsAt: Proptypes.number,
      startsAt: Proptypes.number,
    }),
  ),
});

export const LandProptypes = Proptypes.shape({
  tokenId: Proptypes.number,
  burned: Proptypes.bool,
  ownerAddress: Proptypes.string,
  type: Proptypes.string,
  level: Proptypes.number,
  x: Proptypes.number,
  y: Proptypes.number,
  size: Proptypes.number,
  leveling: Proptypes.shape({
    active: Proptypes.bool,
    endsAt: Proptypes.number,
    startsAt: Proptypes.number,
  }),
  pendingImgUrl: Proptypes.string,
  imgUrl: Proptypes.string,
  linkedBuildings: Proptypes.arrayOf(
    Proptypes.shape({
      assetType: Proptypes.string,
      owner: Proptypes.string,
      linkedAt: Proptypes.number,
      tokenId: Proptypes.number,
    }),
  ),
});

export const landPlaceProptypes = Proptypes.shape({
  building: Proptypes.shape({
    armoryItems: Proptypes.arrayOf(BuildingProptypes),
    available: Proptypes.number,
    link: Proptypes.number,
    workshopItems: Proptypes.arrayOf(BuildingProptypes),
  }),
  extractor: Proptypes.shape({
    available: Proptypes.number,
    linked: Proptypes.number,
    items: Proptypes.arrayOf(BuildingProptypes),
  }),
});

export const ResourceProptypes = Proptypes.shape({
  _id: Proptypes.string,
  quantity: Proptypes.number,
  type: Proptypes.string,
});

export const RuleProptype = Proptypes.shape({
  assetType: Proptypes.string,
  targetLevel: Proptypes.number,
  duration: Proptypes.number,
  buildingRequirement: Proptypes.shape({
    type: Proptypes.string,
    level: Proptypes.number,
  }),
  resources: Proptypes.arrayOf(
    Proptypes.shape({
      type: Proptypes.string,
      quantity: Proptypes.number,
    }),
  ),
  tokens: Proptypes.arrayOf(
    Proptypes.shape({
      type: Proptypes.string,
      quantity: Proptypes.number,
    }),
  ),
});

export const WeaponProptypes = Proptypes.shape({
  tokenId: Proptypes.number,
  burned: Proptypes.bool,
  ownerAddress: Proptypes.string,
  type: Proptypes.string,
  level: Proptypes.number,
  craftingBuildingTokenId: Proptypes.number,
  leveling: Proptypes.shape({
    active: Proptypes.bool,
    endsAt: Proptypes.number,
    startsAt: Proptypes.number,
    targetLevel: Proptypes.number,
    craftingBuildingTokenId: Proptypes.number,
    isCraft: Proptypes.bool,
  }),
  imgUrl: Proptypes.string,
});

export const UserProptypes = Proptypes.shape({
  walletAddress: Proptypes.string,
  resources: Proptypes.arrayOf(ResourceProptypes),
  otherWalletsLinked: Proptypes.arrayOf(
    Proptypes.shape({
      chain: Proptypes.string,
      address: Proptypes.string,
      addressName: Proptypes.string,
    }),
  ),
  email: Proptypes.string,
});
