import { assetTypeBuilding } from '../constants/type';

export const parseAccount = (account) => {
  if (!account) {
    return '';
  }
  const begin = account.substr(0, 5);
  const end = account.substr(account.length - 5, account.length);
  return `${begin}.....${end}`;
};

export const parseOtherWallet = (otherWallet) => {
  if (otherWallet?.addressName) return otherWallet.addressName;
  return parseAccount(otherWallet.address);
};

export const parseChainId = (chainId) => {
  if (chainId === 1) return 'Mainnet';
  return 'Testnet';
};

// TODO: add others tokenAddress if it exists more
export const parseTypeByAsset = (asset) => {
  if (asset.type === 'QG' || asset.type === 'Land') return 'lands';
  if (
    [
      assetTypeBuilding.Armory,
      assetTypeBuilding.Workshop,
      assetTypeBuilding.SteelvarExtractor,
      assetTypeBuilding.SiknarExtractor,
      assetTypeBuilding.ElectriumExtractor,
      assetTypeBuilding.AlkarExtractor,
    ].includes(asset.type)
  ) {
    return `buildings/${removeSpace(asset.type)}`;
  }
  return 'weapons';
};

export const parseTypeByOrder = (order) => {
  console.log(order);
  const type = order.sell.data.properties.name?.split(' ')[0];
  if (
    [
      assetTypeBuilding.Armory,
      assetTypeBuilding.Workshop,
      assetTypeBuilding.SteelvarExtractor,
      assetTypeBuilding.SiknarExtractor,
      assetTypeBuilding.ElectriumExtractor,
      assetTypeBuilding.AlkarExtractor,
    ].includes(type)
  ) {
    return `buildings/${removeSpace(type)}`;
  }
  return 'weapons';
};

// TODO: add others tokenAddress if it exists more
export const parseTypeByTokenAddress = (tokenAddress) => {
  if (
    tokenAddress.toLowerCase() ===
    process.env.REACT_APP_BUILDING_CONTRACT_ADDRESS.toLowerCase()
  ) {
    return 'buildings';
  }
  return 'weapons';
};

export const parseImgUrlLand = (land) => {
  const thumbnailId = land.thumbnailId?.toString() || 0;
  if (land.size === 1) {
    return `${process.env.REACT_APP_PATH_IPFS_URL}/ipfs/${process.env.REACT_APP_IPFS_CID_LAND}/${thumbnailId}.png`;
  }
  if (land.size === 2) {
    return `${process.env.REACT_APP_PATH_IPFS_URL}/ipfs/${process.env.REACT_APP_IPFS_CID_COLONY}/${thumbnailId}.png`;
  }
  return '';
};

export const removeSpace = (string) => string.replace(/\s/g, '');

export function ucFirst(string) {
  if (string) {
    const arr = string?.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr?.join(' ');
  }
  return undefined;
}
