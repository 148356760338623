export const faqCrafting = [
  {
    key: 1,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 2,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 3,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 4,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 5,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
];

export const faqUpgrade = [
  {
    key: 1,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 2,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 3,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
];

export const faqMarketplace = [
  {
    key: 1,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 2,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
  {
    key: 3,
    title: 'Lorem ipsum dolor sit amet, consectetur',
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No.',
  },
];

export const textModalRegister = {
  title: 'Registration\nSuccess!',
  details: 'Welcome to the Oxya Origin army, the Road to Genesis awaits!',
  listDetails: [
    {
      key: 1,
      text: 'Complete and verify your account to qualify for a Starter Pack (the email may be in your spam folder).',
    },
    {
      key: 2,
      text: 'Follow us on socials to stay up to date with the latest news.',
    },
  ],
};
