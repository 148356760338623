import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Proptypes from 'prop-types';

export default function AccordionBox({
  summary,
  expanded,
  onChange,
  children,
}) {
  const classes = useStyle();

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      className={classes.container}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.panel}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.detail}>{children}</AccordionDetails>
    </Accordion>
  );
}

AccordionBox.propTypes = {
  children: Proptypes.node,
  summary: Proptypes.node,
  expanded: Proptypes.bool,
  onChange: Proptypes.func,
};

const useStyle = makeStyles((theme) => ({
  panel: {
    background: '#0D1826',
    border: '0.5px solid #00649E',
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: 35,
    },
    '&.Mui-expanded': {
      background: '#00324F',
      '& svg': {
        color: theme.palette.primary.light,
      },
      '& p': {
        color: `${theme.palette.primary.light}`,
      },
      '& h6': {
        color: `${theme.palette.primary.light}`,
      },
      '& h4': {
        color: `${theme.palette.primary.light}`,
      },
    },
    '&:hover': {
      '& svg': {
        color: theme.palette.primary.light,
      },
      '& p': {
        color: `${theme.palette.primary.light}`,
      },
      '& h6': {
        color: `${theme.palette.primary.light}`,
      },
      '& h4': {
        color: `${theme.palette.primary.light}`,
      },
    },
  },
  container: {
    width: '100%',
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  detail: {
    maxHeight: '40vh',
    overflowY: 'scroll',
    background:
      'transparent radial-gradient(closest-side at 11% 79%, #0D1826 0%, #000000 100%) 0% 0% no-repeat padding-box',
    border: '0.5px solid #00649E',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));
