import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { resourcesUrls } from '../../../constants/resource';
import { ResourceProptypes } from '../../../constants/propTypes';

export default function Resource({ resource, className }) {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={cx(classes.root, className)}
      gridGap="1rem"
    >
      <img
        src={resourcesUrls[resource.type]}
        alt="resource"
        width={50}
        height={50}
      />
      <div className={classes.container}>
        <Typography
          color="textPrimary"
          style={{ fontWeight: 900, textTransform: 'uppercase' }}
        >
          {resource.type}
        </Typography>
        <Typography color="primary" style={{ fontWeight: 900 }}>
          {resource.quantity}
        </Typography>
      </div>
    </Box>
  );
}

Resource.propTypes = {
  resource: ResourceProptypes,
  className: Proptypes.string,
};

Resource.defaultProps = {
  resource: {},
};

const useStyle = makeStyles(() => ({
  root: {
    padding: '0 5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
