import { withStyles } from '@material-ui/core';
import { heightNavBar } from '../../../constants/size';

export default withStyles(({ palette, spacing, breakpoints }) => ({
  title: {
    marginRight: spacing(4),
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    position: 'relative',
  },
  container: {
    display: 'flex',
    background: 'linear-gradient(90deg, #000000 0%, #0C1724 100%)',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderTop: `0.5px solid ${palette.text.blueDark}`,
    borderBottom: `0.5px solid ${palette.text.blueDark}`,
    // border: `1px solid ${palette.primary.light}70`,
    height: `calc(${heightNavBar} + 0.5px)`,
  },
  link: {
    fontSize: '1.5rem',
    [breakpoints.down(500)]: {
      fontSize: '1.2rem',
    },
  },
  selected: {
    position: 'relative',
    color: palette.primary.light,
    fontWeight: 500,
    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      width: '100%',
      height: '2px',
      background: palette.primary.light,
      boxShadow: `0 0 5px ${palette.primary.light}`,
    },
  },
  nonSelected: {
    color: palette.primary.main,
    '&:hover': {
      color: palette.primary.light,
    },
  },
  underlineContainer: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  underline: {
    backgroundColor: palette.primary.light,
    height: 3,
    borderRadius: 10,
  },
}));
