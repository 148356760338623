import React, { useCallback } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';

import AssetImage from '../AssetImage/assetImage';
import StatusChip from '../StatusChip/statusChip';
import { parseImgUrlLand } from '../../../utils/string';

export default function AssetItem({ asset, assetType, userAddress }) {
  const classes = useStyle();

  const computeStatus = useCallback(() => {
    if (assetType === 'avatars') {
      return null;
    }
    if (assetType === 'valorCrests') {
      return <StatusChip name={`x${asset.balance}`} hideBorder isValorCrest />;
    }
    if (asset?.leveling?.active) {
      if (asset?.leveling?.isCraft) {
        return <StatusChip name="Crafting" hideBorder />;
      }
      return <StatusChip name="Leveling" hideBorder />;
    }
    if (
      asset?.crafting?.ownerAddress &&
      asset?.crafting?.ownerAddress?.toLowerCase() ===
        userAddress?.toLowerCase()
    ) {
      return <StatusChip name="Crafting" hideBorder />;
    }
    if (asset?.extracting?.activatedAt && !!asset?.linkedLandTokenId) {
      return <StatusChip name="Extracting" hideBorder />;
    }
    if (asset?.collaborativeSettings?.isAvailable) {
      return <StatusChip name="Collaborative crafting" hideBorder />;
    }
    if (asset?.collaborativeSettings?.endsAt) {
      return <StatusChip name="Pending collaborative withdraw" hideBorder />;
    }

    return <StatusChip name="Available" isAvailable hideBorder />;
  }, [asset, assetType]);

  const renderDescription = useCallback(() => {
    if (asset?.size) {
      return (
        <>
          <Typography variant="h6">
            {`${asset.size === 1 ? 'LAND' : 'COLONY'} #${asset.tokenId}`}
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Typography variant="h6">{asset.planetName}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" align="left">
              {`${asset.size} X ${asset.size}`}
            </Typography>
            <Typography variant="h6">{`LV.${asset.level}`}</Typography>
          </Box>
        </>
      );
    }
    if (
      [
        'Siknar Extractor',
        'Steelvar Extractor',
        'Electrium Extractor',
        'Alkar Extractor',
      ].includes(asset.type)
    ) {
      return (
        <>
          <Typography variant="h6">{`Extractor #${asset.tokenId}`}</Typography>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Typography variant="h6" align="left">
              {`${asset.extracting.extractionType}`}
            </Typography>
            <Typography variant="h6">{`LV.${asset.level}`}</Typography>
          </Box>
        </>
      );
    }
    return (
      <>
        <Typography variant="h6">
          {assetType === 'valorCrests'
            ? asset.name
            : `${asset.type} #${asset.tokenId}`}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {asset?.level && (
            <Typography variant="h6">{`LV.${asset.level}`}</Typography>
          )}
        </Box>
      </>
    );
  }, [asset]);
  return (
    <div className={classes.container}>
      <div className={classes.topRightBox} />
      <div className={classes.topLeftBox} />
      <div className={classes.bottomRightBox} />
      <div className={classes.bottomLeftBox} />
      <div className={classes.statusBox}>{computeStatus()}</div>

      <AssetImage
        src={assetType === 'lands' ? parseImgUrlLand(asset) : asset.imgUrl}
        noBorder
      />
      <Box p={2}>{renderDescription()}</Box>
    </div>
  );
}

AssetItem.propTypes = {
  asset: Proptypes.object,
  assetType: Proptypes.string,
  userAddress: Proptypes.string,
};

const useStyle = makeStyles((theme) => ({
  container: {
    background: '#0D1826',
    position: 'relative',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      '& $topRightBox': {
        borderTop: `1px solid ${theme.palette.primary.light}`,
        borderRight: `1px solid ${theme.palette.primary.light}`,
      },
      '& $topLeftBox': {
        borderTop: `1px solid ${theme.palette.primary.light}`,
        borderLeft: `1px solid ${theme.palette.primary.light}`,
      },
      '& $bottomRightBox': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        borderRight: `1px solid ${theme.palette.primary.light}`,
      },
      '& $bottomLeftBox': {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        borderLeft: `1px solid ${theme.palette.primary.light}`,
      },
    },
  },
  statusBox: {
    position: 'absolute',
    top: 4,
    left: 4,
    zIndex: 1000,
  },
  topRightBox: {
    position: 'absolute',
    top: -1,
    right: -1,
    borderTop: `1px solid ${theme.palette.primary.light}80`,
    borderRight: `1px solid ${theme.palette.primary.light}80`,
    width: '30%',
    height: '30%',
  },
  topLeftBox: {
    position: 'absolute',
    top: -1,
    left: -1,
    borderTop: `1px solid ${theme.palette.primary.light}80`,
    borderLeft: `1px solid ${theme.palette.primary.light}80`,
    width: '30%',
    height: '30%',
  },
  bottomRightBox: {
    position: 'absolute',
    bottom: -1,
    right: -1,
    borderBottom: `1px solid ${theme.palette.primary.light}80`,
    borderRight: `1px solid ${theme.palette.primary.light}80`,
    width: '30%',
    height: '30%',
  },
  bottomLeftBox: {
    position: 'absolute',
    bottom: -1,
    left: -1,
    borderBottom: `1px solid ${theme.palette.primary.light}80`,
    borderLeft: `1px solid ${theme.palette.primary.light}80`,
    width: '30%',
    height: '30%',
  },
}));
