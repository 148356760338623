import { makeStyles } from '@material-ui/core';
import {
  heightHeader,
  heightNavBar,
  sizeLogo,
  widthElemNavBar,
} from '../../../constants/size';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    height: heightHeader,
    background: 'black',
    boxShadow: 'none',
  },
  noDisplay: {
    display: 'none',
  },
  toolbar: {
    padding: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  sectionDesktop: {
    display: 'none',
    [breakpoints.up(1000)]: {
      display: 'flex',
    },
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    marginRight: spacing(2),
  },
  mobileSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 2rem',
    [breakpoints.up(1000)]: {
      display: 'none',
    },
  },

  boxContentMobile: {
    justifyContent: 'flex-end',
  },
  linkLogoMobile: {
    flex: '5',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '5rem',
    '& img': {
      width: '20rem',
    },
    [breakpoints.down(580)]: {
      display: 'none',
    },
  },
  social: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    transition: '0.2s all ease-in-out',
    '& img': {
      transition: '0.2s all ease-in-out',
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  containerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerHeader: {
    margin: 0,
    display: 'flex',
    justifyContent: 'end',
  },
  iconLarge: {
    fontSize: '3rem',
  },
  closeBtn: {
    color: 'white',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  divider: {
    background: '#707070',
    height: '1px',
  },
  logoNav: {
    width: `calc(${widthElemNavBar} + 0.5px)`,
    height: heightNavBar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `0.5px solid ${palette.text.blueDark}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkLogo: {
    width: `calc(${widthElemNavBar} + 0.5px)`,
    '& img': {
      height: sizeLogo,
      width: sizeLogo,
    },
  },
  linkNav: {
    fontSize: '1.4rem',
    color: 'white',
    margin: '0 2rem',
    textDecoration: 'none',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  linkNavActive: {
    color: '#e5ba57 !important',
  },
  signIn: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
  },
  btnSignIn: {
    padding: '1rem 2rem',
  },
  btnWebsite: {
    padding: 0,
    marginLeft: '2rem',
    border: 'none',
    boxShadow: 'none',
  },
  btnRegister: {
    padding: '1rem 2rem',
    background:
      'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    border: 'none',
  },
  btnUser: {
    textTransform: 'initial',
    height: '4.5rem',
    '& p': {
      fontSize: '1.2rem',
    },
  },
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
  },
  btnConnect: {
    borderRadius: '1rem 0',
    fontSize: '1.4rem',
  },

  boxUserMenu: {
    minWidth: '35rem',
    height: '24rem !important',
    position: 'absolute',
    zIndex: 100,
    top: '75px',
    right: '12px',
    borderColor: '#0FBEFD',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [breakpoints.down(580)]: {
      top: '90px',
    },
  },
  contentUserMenu: {
    display: 'flex',
    gap: '2rem',
    '& img': {
      width: '77px',
      height: '77px',
      borderRadius: '50%',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  detailsContentUserMenu: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  username: {
    color: 'white',
    fontWeight: 600,
    fontSize: '1.6rem',
  },
  textUserMenu: {
    color: '#959595',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  btnsUserMenu: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  btnAccountUserMenu: {
    background: '#091E31',
    boxShadow: 'none',
    '& span': {
      fontSize: '1.4rem',
    },
  },
  btnLogout: {
    background: '#1B0F1D',
    border: '1px solid #FC0001',
    boxShadow: 'none',
    '& span': {
      fontSize: '1.4rem',
    },
  },
  btnNoPointerEvent: {
    pointerEvent: 'none',
    cursor: 'initial',
    '&:hover': {
      opacity: 1,
    },
  },
  containerInfo: {
    marginTop: '2rem',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textInfo: {
    textTransform: 'uppercase',
    color: 'white',
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  bigBtn: {
    fontSize: '1.4rem',
    marginTop: '2rem',
  },
  listText: {
    fontSize: '1.4rem',
  },
  drawerPaper: {
    background: 'linear-gradient( -45deg, rgb(13,24,52) 0%, rgb(8,12,24) 99%)',
    width: '100% !important',
    height: '100vh',
    flexShrink: 0,
    zIndex: 999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoOxya: {
    maxWidth: '70rem',
    margin: 'auto',
    [breakpoints.down(700)]: {
      margin: 0,
    },
  },
  wrapperLinksMenuMobile: {
    margin: '1rem 0',
  },
  itemMenu: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: 'white',
      fontSize: '2rem',
      textTransform: 'uppercase',
      fontWeight: 500,
      letterSpacing: '0.5rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  btnToToken: {
    boxShadow: 'none',
    maxWidth: 'initial',
    padding: '0 2rem',
    minHeight: '12rem',
    background: 'linear-gradient(360deg, rgb(7,12,25) 0%, rgb(23,44,67) 40%)',
    '&::after': {
      background:
        'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
    },
  },
  wrapperBottomMenuMobile: {
    // padding: "3rem",
    border: '1px solid rgb(21, 37, 84)',
    '& hr': {
      background: 'rgb(21, 37, 84)',
      borderColor: 'rgb(21, 37, 84)',
    },
  },
}));
