import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as waxjs from '@waxio/waxjs/dist';

import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useAccount } from 'wagmi';
import { disconnect, signMessage } from '@wagmi/core';

import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import EditIcon from '@material-ui/icons/Edit';
import BlueBox from '../../_atoms/BlueBox/blueBox';

import avatarGeneral from '../../../assets/onboarding/avatarGeneral.png';
import { parseAccount, parseOtherWallet, ucFirst } from '../../../utils/string';
import { sizeMobileForAccountPage } from '../../../constants/size';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

import ModalCenter from '../../_atoms/ModalCenter/modal-center';
import {
  linkSolanaWallet,
  linkUltraWallet,
  linkWallet,
  linkWaxWallet,
  logout,
  signIn,
  unlinkEvmWallet,
  unlinkOtherWallet,
  // unlinkWallet,
  updatePassword,
  updateUser,
} from '../../../redux/actions/user';
import { useQueryWithQueryString } from '../../../hooks/useQuery';
import { returnErrors } from '../../../redux/actions/errorAction';
import { chainsWalletToLink } from '../../../constants/wallet';
import LinkUnlinkWallet from '../../_molecules/LinkUnlinkWallet/linkUnlinkWallet';

export default function Account() {
  const classes = useStyle({});
  const queryParams = useQueryWithQueryString();

  const dispatch = useDispatch();

  const accessToken =
    useSelector((state) => state.user.accessToken) || queryParams.accessToken;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const user = useSelector((state) => state.user?.user);

  const [fullName, setFullName] = React.useState(user?.name);
  const [username, setUsername] = React.useState(user?.userName);
  const [email, setEmail] = React.useState(user?.email);

  const [showPassword, setShowPassword] = React.useState(false);

  const [oldPassword, setOldPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [selectedAvatar, setSelectedAvatar] = React.useState(null);
  const [selectedFileAvatar, setSelectedFileAvatar] = React.useState(null);

  const [openModalUpdatePassword, setOpenModalUpdatePassword] =
    React.useState(false);

  const [openDialogUnlinkWallet, setOpenDialogUnlinkWallet] =
    React.useState(false);

  const [walletToUnlink, setWalletToUnlink] = React.useState(null);

  const { address } = useAccount({
    /* onDisconnect() {
      handleLogout();
    }, */
  });

  const handleAuthWeb3 = async () => {
    const message = await dispatch(signIn(address));
    try {
      const signature = await signMessage({
        message,
      });
      await dispatch(linkWallet(signature, address, message));
    } catch (e) {
      console.log(e, 'e');
      handleLogout();
    }
  };

  useEffect(() => {
    if (address) {
      handleAuthWeb3(address);
    }
  }, [address]);

  useEffect(() => {
    setFullName(user?.name || '');
    setUsername(user?.userName || '');
    setEmail(user?.email || '');
  }, [user]);

  const noDisabledSaveChanges = useMemo(
    () =>
      !!selectedFileAvatar ||
      ((username !== user?.userName ||
        !!selectedFileAvatar ||
        fullName !== user?.name) &&
        (!!username || !!fullName || !!selectedFileAvatar)),
    [user, username, fullName, selectedFileAvatar],
  );

  const handleCloseModal = () => {
    setOpenModalUpdatePassword(false);
  };

  const handleChangeFullName = (e) => {
    setFullName(e.target.value);
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    setSelectedFileAvatar(file);
    const reader = new FileReader();
    reader.onload = function (eventLoad) {
      setSelectedAvatar(eventLoad.target.result);
    };
    reader.readAsDataURL(file);
  };

  const avatarDisplay = useMemo(() => {
    if (selectedAvatar) return selectedAvatar;
    if (user?.avatarUrl) return user.avatarUrl;
    return avatarGeneral;
  }, [selectedAvatar, user]);

  const handleOpenModalUpdatePassword = () => {
    setOpenModalUpdatePassword(true);
  };

  const handleOpenDialogUnlinkWallet = (_, otherWallet) => {
    setWalletToUnlink(otherWallet);
    setOpenDialogUnlinkWallet(true);
  };

  const handleCloseDialogUnlinkWallet = () => {
    setOpenDialogUnlinkWallet(false);
    setWalletToUnlink(null);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleUpdatePassword = async () => {
    if (!!oldPassword && !!password && !!confirmPassword) {
      await dispatch(
        updatePassword(
          {
            oldPassword,
            password,
            confirmPassword,
          },
          () => {
            handleLogout();
          },
        ),
      );
    }
  };

  const handleLogout = async () => {
    await disconnect();
    dispatch(logout());
  };

  const handleUnlinkWallet = async () => {
    if (walletToUnlink) {
      if (walletToUnlink.chain.indexOf('evm') !== -1) {
        await dispatch(unlinkEvmWallet());
        window.location.reload();
      } else {
        await dispatch(unlinkOtherWallet(walletToUnlink.chain));
      }
    }
    handleCloseDialogUnlinkWallet();
    // window.location.reload();
    /* if (user?.walletAddress && user?.email) {
      await dispatch(unlinkWallet());
      handleCloseDialogUnlinkWallet();
      window.location.reload();
    } */
  };

  const handleLinkWallet = async (chain, otherWallet) => {
    if (chain === 'solana' && otherWallet == null) {
      await handleLinkWalletSolana();
      return;
    }
    if (chain === 'ultra' && otherWallet == null) {
      await handleLinkWalletUltra();
      return;
    }

    if (chain === 'wax' && otherWallet == null) {
      await handleLinkWalletWax();
      return;
    }

    console.log(chain, otherWallet);
  };

  const handleSaveUpdate = async () => {
    await dispatch(
      updateUser({
        userName: user?.userName !== username ? username : null,
        name: user?.name !== fullName ? fullName : null,
        file: selectedFileAvatar,
      }),
    );
    setSelectedFileAvatar(null);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLinkWalletUltra = async () => {
    if ('ultra' in window) {
      try {
        const { ultra } = window;
        const responseConnect = await ultra.connect();
        const blockchainId = responseConnect.data.blockchainid;

        const accountName = blockchainId.split('@')[0];
        const { publicKey } = responseConnect.data;

        const message = await dispatch(signIn(blockchainId));
        const parseMessage = `message:${message}`;

        const responseSign = await ultra.signMessage(parseMessage);
        await dispatch(
          linkUltraWallet({
            signature: responseSign.data.signature,
            publicKey,
            accountName,
            message: parseMessage,
          }),
        );
      } catch (e) {
        dispatch(returnErrors(e.data?.message, 500, 'ULTRA_CONNECTION'));
      }
    } else {
      dispatch(
        returnErrors(
          "You don't have ultra wallet extension in this browser !",
          500,
          'ULTRA_CONNECTION',
        ),
      );
    }
  };

  const handleLinkWalletWax = async () => {
    const wax = new waxjs.WaxJS({
      rpcEndpoint: 'https://wax.greymass.com',
      tryAutoLogin: false,
    });
    const nonce = 'hello';
    try {
      const waxUserAccount = await wax.login(nonce);

      const message = await dispatch(signIn(waxUserAccount));
      const waxProof = await wax.userAccountProof(message, '', false);
      const publicKeys = wax.pubKeys;

      await dispatch(
        linkWaxWallet({
          signature: waxProof.signature,
          publicKeys,
          accountName: waxUserAccount,
          message: waxProof.message,
        }),
      );
    } catch (e) {
      wax.logout();
      dispatch(returnErrors(e.message, 500, 'WAX_CONNECTION'));
    }
  };
  const getSolanaProvider = async () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    dispatch(
      returnErrors(
        "You don't have solana in your browser",
        500,
        'SOLANA_CONNECTION',
      ),
    );
    setTimeout(() => {
      window.open('https://phantom.app/', '_blank');
    }, [3000]);
  };

  const handleLinkWalletSolana = async () => {
    const provider = await getSolanaProvider();
    if (provider) {
      try {
        await provider.disconnect();
        const resp = await provider.connect();
        const publicKey = resp.publicKey.toString();

        const message = await dispatch(signIn(publicKey));
        const encodedMessage = new TextEncoder().encode(message);
        const { signature } = await provider.signMessage(encodedMessage);
        await dispatch(linkSolanaWallet(signature, publicKey, message));
      } catch (e) {
        dispatch(returnErrors(e.message, 500, 'SOLANA_CONNECTION'));
      }
    }
  };

  return (
    <>
      {accessToken ? (
        <div className={classes.root}>
          {openModalUpdatePassword && (
            <ModalCenter
              open={openModalUpdatePassword}
              onClose={handleCloseModal}
              classNameChildren={classes.childrenModal}
              titleTop="Update Password"
            >
              <TextField
                size="small"
                label="Old Password"
                autoComplete="old-password"
                className={classes.textField}
                placeholder="****************"
                value={oldPassword}
                onChange={handleChangeOldPassword}
                required
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={classes.icon} position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" className={classes.icon}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />
              <TextField
                size="small"
                label="Password"
                autoComplete="new-password"
                value={password}
                onChange={handleChangePassword}
                placeholder="****************"
                required
                className={classes.textField}
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={classes.icon} position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" className={classes.icon}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />
              <TextField
                size="small"
                type={showPassword ? 'text' : 'password'}
                label="Confirm Password"
                autoComplete="confirm-new-password"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                placeholder="****************"
                required
                className={classes.textField}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={classes.icon} position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" className={classes.icon}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />
              <ButtonPerso
                className={cx(classes.btnCreateAccount, classes.btnModal)}
                onClick={handleUpdatePassword}
              >
                Save New Password
              </ButtonPerso>
            </ModalCenter>
          )}
          {openDialogUnlinkWallet && (
            <Dialog
              open={openDialogUnlinkWallet}
              onClose={handleCloseDialogUnlinkWallet}
              classes={{
                paper: classes.paperDialog,
              }}
            >
              <DialogTitle className={classes.dialogTitle}>
                {`Unlink ${ucFirst(walletToUnlink.chain)} Wallet`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogText}>
                  {`Are you sure you want to unlink your ${ucFirst(
                    walletToUnlink.chain,
                  )} wallet ${parseAccount(
                    parseOtherWallet(walletToUnlink),
                  )} from your account ${user?.email} ?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonPerso
                  onClick={handleCloseDialogUnlinkWallet}
                  noBoxShadow
                  className={classes.btnDialog}
                >
                  Cancel
                </ButtonPerso>
                <ButtonPerso
                  onClick={handleUnlinkWallet}
                  noBoxShadow
                  className={classes.btnDialog}
                >
                  Yes
                </ButtonPerso>
              </DialogActions>
            </Dialog>
          )}
          <BlueBox className={classes.containerContent}>
            <Box>
              <Box className={classes.headerContent}>
                <div type="button" className={classes.btnAvatar}>
                  <img src={avatarDisplay} alt="avatar user" />
                  <input
                    type="file"
                    id="avatarUpload"
                    accept=".png, .jpg, .jpeg"
                    style={{ display: 'none' }}
                    onChange={handleChangeAvatar}
                  />
                  <label htmlFor="avatarUpload" className={classes.btnEdit}>
                    <EditIcon />
                  </label>
                </div>
                <Typography variant="h1">{'Oxya\nAccount'}</Typography>
              </Box>

              <Typography
                variant="body1"
                style={{ fontSize: '1.5rem', marginTop: '1rem' }}
              >
                Define your Oxyan persona and take your place amongst the
                galaxy’s fiercest warriors.
              </Typography>
            </Box>
            <Box className={classes.wrapperTextFields}>
              <Typography variant="body1" className={classes.label}>
                Name
              </Typography>
              <TextField
                size="small"
                autoComplete="full-name"
                value={fullName}
                onChange={handleChangeFullName}
                className={classes.textField}
                placeholder="Full Name"
                required
                variant="outlined"
                InputProps={{
                  classes: { input: classes.input },
                }}
              />
              <Box className={classes.wrapperUsername}>
                <Chip label="@" className={classes.chipUsername} />
                <TextField
                  size="small"
                  autoComplete="username-name"
                  value={username}
                  onChange={handleChangeUsername}
                  className={cx(
                    classes.textField,
                    !user?.email && classes.textFieldDisabled,
                  )}
                  placeholder="Username"
                  required
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: cx(classes.input, classes.inputUsername),
                    },
                  }}
                  style={{ marginTop: '1rem' }}
                  disabled={!user?.email}
                />
              </Box>
            </Box>
            <Box className={classes.wrapperTextFields}>
              <Typography variant="body1" className={classes.label}>
                Email
              </Typography>
              <Box className={classes.wrapperEmail}>
                <TextField
                  size="small"
                  autoComplete="email"
                  value={email}
                  disabled
                  className={classes.textField}
                  placeholder="Email"
                  required
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: cx(classes.input, classes.inputEmail),
                    },
                  }}
                />
                <Chip
                  label={user?.emailVerified ? 'Verified' : 'Not Verified'}
                  className={cx(
                    classes.chip,
                    classes.chipEmailNotVerified,
                    user?.emailVerified && classes.chipEmailVerified,
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.wrapperBottom}>
              <Box className={classes.wrapperLinksWallet}>
                {chainsWalletToLink.map((chainWalletToLink) => {
                  const otherWalletLinked = user?.otherWalletsLinked
                    ? user.otherWalletsLinked.find(
                        (elem) =>
                          elem.chain.indexOf(chainWalletToLink.chain) !== -1,
                      )
                    : undefined;
                  return (
                    <LinkUnlinkWallet
                      chain={chainWalletToLink.chain}
                      key={chainWalletToLink.key}
                      email={user?.email}
                      otherWallet={otherWalletLinked}
                      onLink={handleLinkWallet}
                      onUnlink={handleOpenDialogUnlinkWallet}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box className={classes.wrapperBtns}>
              <ButtonPerso
                className={cx(classes.btnCreateAccount, classes.btnSave)}
                onClick={handleSaveUpdate}
                disabled={!noDisabledSaveChanges}
              >
                Save Changes
              </ButtonPerso>
              {!!user?.email && (
                <ButtonPerso
                  noUppercase
                  className={classes.btnUpdate}
                  onClick={handleOpenModalUpdatePassword}
                >
                  Update Password
                </ButtonPerso>
              )}
              <ButtonPerso
                className={cx(classes.btnCreateAccount, classes.btnLogout)}
                onClick={handleLogout}
              >
                Log Out
              </ButtonPerso>
            </Box>
          </BlueBox>
        </div>
      ) : (
        <Redirect to={{ pathname: '/auth/login' }} />
      )}
    </>
  );
}

const useStyle = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    overflowX: 'hidden',
    fontSize: '1.4rem',
    fontFamily: 'Montserrat, sans-serif',
    color: 'white',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${spacing(1)}`,
  },
  childrenModal: {
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    maxWidth: '50rem',
    width: '100%',
  },
  paperDialog: {
    display: 'flex',
    borderRadius: '3rem',
    minWidth: 375,
    flexDirection: 'column',
    height: 'auto',
    background: palette.secondary.main,
    boxShadow: '0 0 30px #27c6f74d',
    border: `1px solid ${palette.primary.light}`,
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
    padding: '1rem',
  },
  dialogTitle: {
    '& h2': {
      color: 'white',
      textTransform: 'uppercase',
      fontFamily: 'Akira',
      fontSize: '2.2rem',
    },
  },
  dialogText: {
    fontSize: '1.4rem',
    color: 'white',
    whiteSpace: 'pre-wrap',
  },
  btnDialog: {
    width: '15rem',
  },
  containerContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'initial',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: 'calc(90vh - 17rem)',
    margin: '0 auto',
    padding: '5rem',
    maxWidth: '70rem',
    gap: '2rem',
    '& h2': {
      fontFamily: 'Akira',
      fontWeight: 600,
      textShadow: '0px 3px 9px rgba(0, 0, 0, 0.58)',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      whiteSpace: 'pre-wrap',
      fontSize: '7rem',
      color: 'white',
      [breakpoints.down('lg')]: {
        fontSize: '6rem',
      },
      [breakpoints.down('md')]: {
        fontSize: '4.8rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '3rem',
        textAlign: 'center',
      },
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      padding: '1rem',
      marginTop: '2rem',
    },
    '@media (max-height: 900px)': {
      height: 'fit-content',
    },
  },
  headerContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      fontSize: '4rem',
      fontFamily: 'Akira',
      fontWeight: 600,
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      [breakpoints.down(sizeMobileForAccountPage)]: {
        fontSize: '2.8rem',
      },
    },
  },
  btnEdit: {
    position: 'relative',
    right: '10px',
    bottom: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnAvatar: {
    background: 'none',
    border: 'none',
    transition: '0.2s all ease-in-out',
    '& img': {
      width: '10rem',
      height: '10rem',
      borderRadius: '50%',
      [breakpoints.down(sizeMobileForAccountPage)]: {
        width: '80%',
      },
    },
    '& svg': {
      fill: '#ffffff',
    },
  },
  bodyContent: {
    marginTop: '2rem',
  },
  form: {},
  wrapperTextFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      marginTop: '2rem',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: '1.4rem',
    marginLeft: '1rem',
    textAlign: 'left',
  },
  textField: {
    width: '100%',
    '& input': {
      paddingTop: '17.5px',
      position: 'relative',
      zIndex: 1,
    },
    '& .Mui-focused': {
      color: 'white',
    },
    '& fieldset': {
      borderRadius: '3rem',
      // background: "#0e1e2f",
      border: '1px solid #18A0FB !important',
    },
  },
  textFieldDisabled: {
    '& fieldset': {
      background: '#1B0F1D',
      borderColor: '#FC0001 !important',
    },
  },
  input: {
    color: 'white',
  },
  icon: {
    '& svg': {
      fill: '#393f4c',
    },
  },
  inputUsername: {
    left: '5rem',
  },
  inputEmail: {
    width: 'calc(100% - 133px)',
  },
  btnCreateAccount: {
    background:
      'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    border: 'none',
    width: '18rem',
    '& span': {
      textTransform: 'initial',
      fontSize: '1.4rem',
    },

    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  btnModal: {
    width: '100%',
  },
  wrapperUsername: {
    position: 'relative',
  },
  wrapperEmail: {
    position: 'relative',
  },
  chipUsername: {
    position: 'absolute',
    top: '10px',
    height: '45px',
    width: '5rem',
    background: '#071021',
    border: '1px solid #18A0FB',
    zIndex: 2,
    color: 'white',
    fontWeight: 700,
    fontSize: '1.4rem',
  },
  chipDisabled: {
    pointerEvents: 'none',
    cursor: 'initial',
  },
  chip: {
    color: 'white',
    fontWeight: 700,
    fontSize: '1.4rem',
    height: '4rem',
    width: '185px',
    borderRadius: '2rem',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  chipEmailNotVerified: {
    background: '#1B0F1D',
    width: 'auto',
    border: '1px solid #FC0001',
    height: '45px',
    position: 'absolute',
    right: 0,
  },
  chipEmailVerified: {
    background: '#0F1D15',
    border: '1px solid #00FC0A',
  },
  wrapperBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      flexDirection: 'column',
      marginTop: '2rem',
      gap: '2rem',
    },
  },
  wrapperLinksWallet: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '2rem',
  },
  btnWalletConnect: {
    border: '1px solid #18A0FB',
    background: 'rgba(15, 190, 255, 0.078)',
    width: '185px',
    transition: '0.2s all ease-in-out',
    height: '4rem',
    '&:hover': {
      color: '#18A0FB',
      background: 'rgba(15, 190, 255, 0.078)',
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  chipWallet: {
    background: '#0F1D15',
    border: '1px solid #00FC0A',
    transition: '0.2s all ease-in-out',

    '&:hover': {
      background: '#0F1D15',
      '& span': {
        color: '#18A0FB',
      },
    },
    '&:focus': {
      background: '#0F1D15',
    },
  },
  chipUpdate: {
    background: '#071120',
    border: '1px solid #18A0FB',
    '&:hover': {
      backgroundColor: 'rgb(206 206 206 / 10%)',
    },
    '&:focus': {
      background: '#071120',
    },
  },
  btnUpdate: {
    boxShadow: 'none',
    width: '185px',
    '& span': {
      fontSize: '1.4rem',
    },
    [breakpoints.down(sizeMobileForAccountPage)]: {
      width: '100%',
    },
  },
  wrapperBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    gap: '2rem',
    marginTop: '2rem',
  },
  btnSave: {
    boxShadow: 'none',
    [breakpoints.down(sizeMobileForAccountPage)]: {
      marginTop: '2rem',
    },
  },
  btnLogout: {
    border: '1px solid #FC0001',
    background: '#1B0F1D',
    boxShadow: 'none',
  },
}));
