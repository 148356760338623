import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';

export default function SquareWithBorder({ children, className, selected }) {
  const classes = useStyle({ selected });

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.topRightBox} />
      <div className={classes.topLeftBox} />
      <div className={classes.bottomRightBox} />
      <div className={classes.bottomLeftBox} />
      {children}
    </div>
  );
}

const useStyle = makeStyles(({ palette }) => ({
  container: {
    position: 'relative',
    '&:hover': {
      '& div': {
        borderColor: palette.primary.light,
      },
      '& p': {
        color: palette.primary.light,
      },
    },
  },
  topRightBox: {
    transition: '0.1s all ease-in-out',
    position: 'absolute',
    top: 0,
    right: 0,
    borderTop: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    borderRight: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    width: '30%',
    height: '30%',
  },
  topLeftBox: {
    transition: '0.1s all ease-in-out',
    position: 'absolute',
    top: 0,
    left: 0,
    borderTop: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    borderLeft: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    width: '30%',
    height: '30%',
  },
  bottomRightBox: {
    transition: '0.1s all ease-in-out',
    position: 'absolute',
    bottom: '2px',
    right: 0,
    borderBottom: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    borderRight: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    width: '30%',
    height: '30%',
  },
  bottomLeftBox: {
    transition: '0.1s all ease-in-out',
    position: 'absolute',
    bottom: '2px',
    left: 0,
    borderBottom: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    borderLeft: (props) =>
      `1px solid ${
        props.selected ? palette.primary.light : palette.text.blueDark
      }`,
    width: '30%',
    height: '30%',
  },
}));

SquareWithBorder.propTypes = {
  children: Proptypes.node,
  className: Proptypes.string,
  selected: Proptypes.bool,
};

SquareWithBorder.defaultProps = {
  selected: false,
};
