import React, { useEffect } from 'react';
import Proptypes from 'prop-types';

import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@mui/icons-material/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory, Redirect } from 'react-router-dom';
import { disconnect, signMessage } from '@wagmi/core';

import { useAccount } from 'wagmi';
import BlueBox from '../../_atoms/BlueBox/blueBox';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

import { useQueryWithQueryString } from '../../../hooks/useQuery';

import {
  forgetPassword,
  loginWeb2,
  loginWeb3,
  logout,
  registerWeb2,
  signIn,
  validateEmail,
} from '../../../redux/actions/user';
import ModalCenter from '../../_atoms/ModalCenter/modal-center';
import { isActionLoading } from '../../../redux/selectors/loadingSelectors';
import {
  FETCH_LOGIN_WEB2,
  FETCH_REGISTER_WEB2,
} from '../../../redux/constants/actionTypes';

export default function Auth({ match }) {
  const classes = useStyle({});
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useQueryWithQueryString();

  const { address } = useAccount({
    /* onDisconnect() {
      handleLogout();
    }, */
  });

  const localIsRegister = history.location.pathname.indexOf('register') !== -1;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const { emailToken } = match.params;
    if (emailToken) {
      dispatch(validateEmail(emailToken));
    }
  }, [match]);

  const accessToken = useSelector((state) => state.user.accessToken);
  const isRegistering = useSelector((state) =>
    isActionLoading(state, FETCH_REGISTER_WEB2),
  );

  const isLogining = useSelector((state) =>
    isActionLoading(state, FETCH_LOGIN_WEB2),
  );

  const [isRegister, setIsRegister] = React.useState(localIsRegister);
  const [showPassword, setShowPassword] = React.useState(false);
  const [openModalForgetPassword, setOpenModalForgetPassword] = React.useState(
    false || queryParams?.forgetPassword,
  );

  const [email, setEmail] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  useEffect(() => {
    if (!accessToken) {
      handleLogout();
    }
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken && !!address) {
      handleAuthWeb3(address);
    }
  }, [accessToken, address]);

  const handleCloseModal = () => {
    setOpenModalForgetPassword(false);
  };

  const handleOpenModalForgetPassword = () => {
    setOpenModalForgetPassword(true);
  };

  const handleAuthWeb3 = async (addressParam) => {
    try {
      const message = await dispatch(signIn(addressParam));
      const signature = await signMessage({
        message,
      });
      await dispatch(loginWeb3(signature, address, message));
    } catch (e) {
      console.log(e, 'e');
      handleLogout();
    }
  };

  const handleLogout = async () => {
    await disconnect();
    dispatch(logout());
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickTabLogin = () => {
    setIsRegister(false);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    history.push('/auth/login');
  };

  const handleClickTabRegister = () => {
    setIsRegister(true);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    history.push('/auth/register');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegisterWeb2 = () => {
    try {
      dispatch(
        registerWeb2({ email, userName, password, confirmPassword }, () => {
          dispatch(
            loginWeb2({ email, password }, () => {
              history.push({
                pathname: '/',
                state: {
                  modalRegister: true,
                },
              });
            }),
          );
        }),
      );
    } catch (e) {
      handleLogout();
    }
  };

  const handleLoginWeb2 = () => {
    try {
      dispatch(loginWeb2({ email, password }));
    } catch (e) {
      handleLogout();
    }
  };

  const handleForgetPassword = () => {
    dispatch(
      forgetPassword(email, () => {
        setOpenModalForgetPassword(false);
      }),
    );
  };

  return (
    <>
      {accessToken ? (
        <Redirect to={{ pathname: '/', state: { from: history.location } }} />
      ) : (
        <div className={classes.root}>
          {openModalForgetPassword && (
            <ModalCenter
              open={openModalForgetPassword}
              onClose={handleCloseModal}
              classNameChildren={classes.childrenModal}
              titleTop="Forget Password"
            >
              <TextField
                size="small"
                label="Email"
                autoComplete="email"
                className={classes.textField}
                placeholder="Enter an email"
                value={email}
                onChange={handleChangeEmail}
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.icon}>
                      <MailIcon />
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />
              <ButtonPerso
                className={classes.btnModal}
                onClick={handleForgetPassword}
              >
                Reset Password
              </ButtonPerso>
            </ModalCenter>
          )}
          <Box className={classes.containerContent}>
            <Box className={classes.boxTab}>
              <ButtonPerso
                onClick={handleClickTabRegister}
                className={cx(
                  classes.btnAuth,
                  isRegister ? classes.btnActive : '',
                )}
              >
                Register
              </ButtonPerso>
              <ButtonPerso
                onClick={handleClickTabLogin}
                className={cx(
                  classes.btnAuth,
                  isRegister === false ? classes.btnActive : '',
                )}
              >
                Login
              </ButtonPerso>
            </Box>
            {isRegister ? (
              <BlueBox className={classes.containerAuth}>
                <TextField
                  size="small"
                  label="Email"
                  autoComplete="email"
                  className={classes.textField}
                  placeholder="Enter an email"
                  value={email}
                  onChange={handleChangeEmail}
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.icon}>
                        <MailIcon />
                      </InputAdornment>
                    ),
                    classes: { input: classes.input },
                  }}
                />
                <TextField
                  size="small"
                  label="Username"
                  autoComplete="Username"
                  value={userName}
                  onChange={handleChangeUserName}
                  className={classes.textField}
                  placeholder="Enter your username"
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.icon}>
                        <PersonIcon />
                      </InputAdornment>
                    ),
                    classes: { input: classes.input },
                  }}
                />
                <TextField
                  size="small"
                  label="Password"
                  autoComplete="new-password"
                  value={password}
                  onChange={handleChangePassword}
                  placeholder="****************"
                  required
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className={classes.icon} position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" className={classes.icon}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: { input: classes.input },
                  }}
                />
                <TextField
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  label="Confirm Password"
                  autoComplete="confirm-new-password"
                  value={confirmPassword}
                  onChange={handleChangeConfirmPassword}
                  placeholder="****************"
                  required
                  className={classes.textField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className={classes.icon} position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" className={classes.icon}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: { input: classes.input },
                  }}
                />
                <ButtonPerso
                  onClick={handleRegisterWeb2}
                  isLoading={isRegistering || !!isLogining}
                >
                  Register
                </ButtonPerso>
              </BlueBox>
            ) : (
              <BlueBox className={classes.containerAuth}>
                <TextField
                  size="small"
                  label="Email"
                  autoComplete="email"
                  value={email}
                  onChange={handleChangeEmail}
                  className={classes.textField}
                  placeholder="Enter your email"
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.icon}>
                        <MailIcon />
                      </InputAdornment>
                    ),
                    classes: { input: classes.input },
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumnGap="1rem"
                  gridRowGap="1rem"
                >
                  <TextField
                    size="small"
                    label="Password"
                    autoComplete="new-password"
                    value={password}
                    onChange={handleChangePassword}
                    className={classes.textField}
                    placeholder="****************"
                    required
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.icon}
                        >
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" className={classes.icon}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      classes: { input: classes.input },
                    }}
                  />
                  <button
                    onClick={handleOpenModalForgetPassword}
                    type="button"
                    className={classes.btnForgetPassword}
                  >
                    Forgot your password
                  </button>
                </Box>

                <ButtonPerso onClick={handleLoginWeb2} isLoading={isLogining}>
                  Login
                </ButtonPerso>
              </BlueBox>
            )}
          </Box>
        </div>
      )}
    </>
  );
}

Auth.propTypes = {
  match: Proptypes.shape({
    isExact: Proptypes.bool,
    params: Proptypes.object,
    path: Proptypes.string,
    url: Proptypes.string,
  }),
};

const useStyle = makeStyles(({ breakpoints }) => ({
  root: {
    overflowX: 'hidden',
    fontSize: '1.4rem',
    fontFamily: 'Montserrat, sans-serif',
    color: 'white',
    '& button': {
      boxShadow: 'none !important',
    },
  },
  childrenModal: {
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    maxWidth: '50rem',
    width: '100%',
  },
  btnModal: {
    background:
      'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    border: 'none',
    width: '100%',
    '& span': {
      textTransform: 'initial',
    },
  },
  section: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
  },
  menu: {
    maxWidth: '10rem',
    minWidth: '10rem',
    padding: '6rem 0',
    position: 'fixed',
    height: '100%',
    zIndex: 2,
    background: 'transparent',
    transform: 'translate(0)',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  wrapperPage: {
    padding: '3rem',
    [breakpoints.up('sm')]: {
      paddingLeft: '13rem',
    },
    width: '100%',
    height: '100%',
  },
  containerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem',
    minHeight: 'calc(100vh - 11rem)',
    '& h2': {
      fontFamily: 'Akira',
      fontWeight: 600,
      textShadow: '0px 3px 9px rgba(0, 0, 0, 0.58)',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      whiteSpace: 'pre-wrap',
      fontSize: '7rem',
      color: 'white',
      [breakpoints.down('lg')]: {
        fontSize: '6rem',
      },
      [breakpoints.down('md')]: {
        fontSize: '4.8rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '3rem',
        textAlign: 'center',
      },
    },
    '@media (max-height: 800px)': {
      marginTop: '4rem',
    },
  },
  boxTab: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
    maxWidth: '50rem',
    marginBottom: '4rem',
  },
  btnAuth: {
    fontSize: '2rem',
    background: 'rgba(15, 190, 255, 0.078)',
    padding: '1rem 4rem',
    width: '100%',
    borderColor: 'white',
    boxShadow: 'none',
  },
  btnActive: {
    background: 'white',
    '& span': {
      color: '#070f1f',
    },
  },
  containerConnectWallet: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem 0',
    maxWidth: '50rem',
    marginTop: '4rem',
    marginBottom: '2rem',
    '& button': {
      '&::after': {
        border: 'none',
      },
    },
  },
  containerImgWallets: {
    display: 'flex',
    gap: '1rem',
    '& img': {
      width: '25px',
      height: '25px',
    },
  },
  btnWalletConnect: {
    width: '100%',
    '& span': {
      textTransform: 'uppercase',
    },
    '&::after': {
      border: 'none',
    },
  },
  textOr: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '3rem',
    marginBottom: '2rem',
  },
  btnForgetPassword: {
    textDecoration: 'underline',
    background: 'transparent',
    border: 'none',
    color: 'white',
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '1rem',
    width: 'fit-content',
  },
  containerAuth: {
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    maxWidth: '50rem',
    width: '100%',
  },
  textField: {
    '& input': {
      paddingTop: '17.5px',
    },
    '& .Mui-focused': {
      color: 'white',
    },
    '& fieldset': {
      borderRadius: '3rem',
      border: '1px solid #18A0FB !important',
    },
  },
  icon: {
    '& svg': {
      fill: '#393f4c',
    },
  },
  input: {
    color: 'white',
  },
}));
