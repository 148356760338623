import metamaskWallet from '../assets/wallets/metamask.png';
import walletConnectWallet from '../assets/wallets/walletConnect.png';
import coinbaseWallet from '../assets/wallets/coinbase.png';
import ledgerWallet from '../assets/wallets/ledger.png';
import trustwallet from '../assets/wallets/trustwallet.png';
import walletUnknow1 from '../assets/wallets/walletunknow1.png';
import walletUnknow2 from '../assets/wallets/walletunknow2.png';
import walletUnknow3 from '../assets/wallets/walletunknow3.png';
import walletUnknow4 from '../assets/wallets/walletunknow4.png';

import ultraLauncher from '../assets/launchers/ultra.png';
import gsLauncher from '../assets/launchers/gameswift.png';
// import elixirLauncher from '../assets/launchers/elixir.png';

export const buildingImages = {
  Workshop: `${process.env.REACT_APP_PATH_S3}/buildings/workshop.jpg`,
  Armory: `${process.env.REACT_APP_PATH_S3}/buildings/armory.jpg`,
  'Steelvar Extractor': `${process.env.REACT_APP_PATH_S3}/buildings/extractorSteelvar.jpg`,
  'Siknar Extractor': `${process.env.REACT_APP_PATH_S3}/buildings/extractorSiknar.jpg`,
  'Electrium Extractor': `${process.env.REACT_APP_PATH_S3}/buildings/extractorSteelvar.jpg`,
  'Alkar Extractor': `${process.env.REACT_APP_PATH_S3}/buildings/alkarExtractor.jpg`,
};

export const imgWallets = [
  {
    key: 0,
    alt: 'metamask wallet',
    img: metamaskWallet,
  },
  {
    key: 1,
    alt: 'walletConnect wallet',
    img: walletConnectWallet,
  },
  {
    key: 2,
    alt: 'coinbase wallet',
    img: coinbaseWallet,
  },
  {
    key: 3,
    alt: 'ledger wallet',
    img: ledgerWallet,
  },
  {
    key: 4,
    alt: 'trust wallet',
    img: trustwallet,
  },
  {
    key: 5,
    alt: 'Unknow wallet1',
    img: walletUnknow1,
  },
  {
    key: 6,
    alt: 'Unknow wallet2',
    img: walletUnknow2,
  },
  {
    key: 7,
    alt: 'Unknow wallet3',
    img: walletUnknow3,
  },
  {
    key: 8,
    alt: 'Unknow wallet4',
    img: walletUnknow4,
  },
];

export const imgLaunchers = [
  {
    key: 0,
    alt: 'Ultra launcher',
    image: ultraLauncher,
    url: 'https://ultra.io/ecosystem/gaming',
  },
  {
    key: 2,
    alt: 'GameSwift launcher',
    image: gsLauncher,
    url: 'https://launcher.gameswift.io/',
  },
];
