import axios from 'axios';
import {
  FETCH_LEADERBOARD_GENESIS,
  FETCH_LEADERBOARD_GENESIS_FULFILLED,
  FETCH_LEADERBOARD_GENESIS_ERROR,
  FETCH_LEADERBOARD_KEYS,
  FETCH_LEADERBOARD_KEYS_FULFILLED,
  FETCH_LEADERBOARD_KEYS_ERROR,
  START_LOADING,
  FETCH_LEADERBOARD_GENESIS_USER,
  FETCH_LEADERBOARD_GENESIS_USER_FULFILLED,
  FETCH_LEADERBOARD_GENESIS_USER_ERROR,
  FETCH_LEADERBOARD_KEYS_USER_FULFILLED,
  FETCH_LEADERBOARD_KEYS_USER_ERROR,
  FETCH_LEADERBOARD_KEYS_USER,
  FETCH_LEADERBOARD_CITIZEN_USER,
  FETCH_LEADERBOARD_CITIZEN_USER_FULFILLED,
  FETCH_LEADERBOARD_CITIZEN_USER_ERROR,
  STOP_LOADING,
  FETCH_LEADERBOARD_CITIZEN,
  FETCH_LEADERBOARD_CITIZEN_FULFILLED,
  FETCH_LEADERBOARD_CITIZEN_ERROR,
} from '../constants/actionTypes';

import { configNoToken, tokenConfig } from '../../utils/configFetch';

export const getLeaderboardGenesisUser = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_LEADERBOARD_GENESIS_USER,
    type: START_LOADING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/playfab/leaderboard/user/genesis`,
      tokenConfig(),
    );

    dispatch({
      type: FETCH_LEADERBOARD_GENESIS_USER_FULFILLED,
      leaderboard: res.data.leaderboard,
    });
  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_GENESIS_USER_ERROR });
  }
  dispatch({
    actionName: FETCH_LEADERBOARD_GENESIS,
    type: STOP_LOADING,
  });
};

export const getLeaderboardKeysUser = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_LEADERBOARD_KEYS_USER,
    type: START_LOADING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/playfab/leaderboard/user/keys`,
      tokenConfig(),
    );

    dispatch({
      type: FETCH_LEADERBOARD_KEYS_USER_FULFILLED,
      leaderboard: res.data.leaderboard,
    });
  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_KEYS_USER_ERROR });
  }
  dispatch({
    actionName: FETCH_LEADERBOARD_KEYS_USER,
    type: STOP_LOADING,
  });
};

export const getLeaderboardCitizenUser = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_LEADERBOARD_CITIZEN_USER,
    type: START_LOADING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/playfab/leaderboard/user/citizen`,
      tokenConfig(),
    );

    dispatch({
      type: FETCH_LEADERBOARD_CITIZEN_USER_FULFILLED,
      leaderboard: res.data.leaderboard,
    });
  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_CITIZEN_USER_ERROR });
  }
  dispatch({
    actionName: FETCH_LEADERBOARD_CITIZEN_USER,
    type: STOP_LOADING,
  });
};

export const getLeaderboardGenesis = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_LEADERBOARD_GENESIS,
    type: START_LOADING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/playfab/leaderboard/genesis`,
      configNoToken(),
    );

    dispatch({
      type: FETCH_LEADERBOARD_GENESIS_FULFILLED,
      leaderboard: res.data.leaderboard,
    });
  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_GENESIS_ERROR });
  }
  dispatch({
    actionName: FETCH_LEADERBOARD_GENESIS,
    type: STOP_LOADING,
  });
};

export const getLeaderboardKeys = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_LEADERBOARD_KEYS,
    type: START_LOADING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/playfab/leaderboard/keys`,
      configNoToken(),
    );

    dispatch({
      type: FETCH_LEADERBOARD_KEYS_FULFILLED,
      leaderboard: res.data.leaderboard,
    });
  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_KEYS_ERROR });
  }
  dispatch({
    actionName: FETCH_LEADERBOARD_GENESIS,
    type: STOP_LOADING,
  });
};

export const getLeaderboardCitizen = () => async (dispatch) => {
  dispatch({
    actionName: FETCH_LEADERBOARD_CITIZEN,
    type: START_LOADING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/playfab/leaderboard/citizen`,
      configNoToken(),
    );

    dispatch({
      type: FETCH_LEADERBOARD_CITIZEN_FULFILLED,
      leaderboard: res.data.leaderboard,
    });
  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_CITIZEN_ERROR });
  }
  dispatch({
    actionName: FETCH_LEADERBOARD_CITIZEN,
    type: STOP_LOADING,
  });
};
