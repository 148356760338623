import React, { useEffect, useMemo } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import AssetImage from '../../_atoms/AssetImage/assetImage';
import opensea from '../../../assets/social/opensea.svg';

import { isActionLoading } from '../../../redux/selectors/loadingSelectors';
import { FETCH_AVATARS } from '../../../redux/constants/actionTypes';
import DetailPageSkeleton from '../../_atoms/DetailPageSkeleton/detailPageSkeleton';
import { getAvatars } from '../../../redux/actions/avatarActions';
import AvatarSummaryPannel from '../../_molecules/AvatarSummaryPanel/avatarSummaryPanel';
import AvatarDescriptionPanel from '../../_organisms/AvatarDescriptionPanel/avatarDescriptionPanel';
import SquareWithBorder from '../../_atoms/SquareWithBorder/squareWithBorder';

export default function AvatarDetail() {
  const classes = useStyle();
  const avatars = useSelector((state) => state.avatar.items);
  const isFetched = useSelector((state) => state.avatar.fetched);
  const dispatch = useDispatch();
  const { tokenId } = useParams();
  const history = useHistory();

  const avatar = useMemo(
    () => avatars.find((b) => b.tokenId.toString() === tokenId),
    [avatars, tokenId],
  );

  const planetName = useMemo(() => avatar?.attributes[0]?.value, [avatar]);
  const tribe = useMemo(() => avatar?.attributes[1]?.value, [avatar]);

  if (avatar === undefined) {
    history.push('/inventory/avatars');
  }

  const accessToken = useSelector((state) => state.user.accessToken);
  const avatarsFetched = useSelector((state) => state.avatar.fetched);

  useEffect(async () => {
    if (accessToken && !avatarsFetched) {
      dispatch(getAvatars());
    }
  }, [accessToken, avatarsFetched]);

  const isLoading = useSelector((state) =>
    isActionLoading(state, FETCH_AVATARS),
  );

  return (
    <div className={classes.container}>
      {!isFetched || isLoading ? (
        <DetailPageSkeleton />
      ) : (
        <Grid container className={classes.detail} spacing={4}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.topMobile} mb={2}>
              <Typography variant="h3" align="left" className={classes.title}>
                Oxyan #{avatar.tokenId}
              </Typography>
              <SquareWithBorder className={classes.squareWithBorder}>
                <a
                  href={`https://opensea.io/fr/assets/ethereum/0xe106c63e655df0e300b78336af587f300cff9e76/${avatar.tokenId}`}
                  target="_blank"
                  className={classes.link}
                  rel="noreferrer"
                >
                  <img
                    src={opensea}
                    loading="lazy"
                    alt={`Oxya character ${avatar.tokenId}`}
                    className={classes.imgMedia}
                  />
                </a>
              </SquareWithBorder>
            </Box>
            <AssetImage src={avatar.imgUrl} borderRadius />
            <Box mt={2} className={classes.wrapperAvatarDescriptionPanel}>
              <AvatarDescriptionPanel planetName={planetName} tribe={tribe} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box className={classes.topDesktop}>
              <Typography variant="h3" align="left" className={classes.title}>
                Oxyan #{avatar.tokenId}
              </Typography>
              <SquareWithBorder className={classes.squareWithBorder}>
                <a
                  href={`https://opensea.io/fr/assets/ethereum/0xe106c63e655df0e300b78336af587f300cff9e76/${avatar.tokenId}`}
                  target="_blank"
                  className={classes.link}
                  rel="noreferrer"
                >
                  <img
                    src={opensea}
                    loading="lazy"
                    alt={`Oxya character ${avatar.tokenId}`}
                    className={classes.imgMedia}
                  />
                </a>
              </SquareWithBorder>
            </Box>
            <AvatarSummaryPannel avatar={avatar} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const useStyle = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    padding: spacing(3),
    width: '100%',
    height: '100%',
  },
  wrapperAvatarDescriptionPanel: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  detail: {},
  topDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  topMobile: {
    display: 'none',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  title: {
    color: 'white',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    fontSize: '2.4rem',
  },
  squareWithBorder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
  },
}));
