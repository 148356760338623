import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';

export default function StatusChip({
  name,
  isValorCrest,
  isAvailable,
  hideBorder,
}) {
  const classes = useStyle({ isAvailable, hideBorder, isValorCrest });
  return (
    <div className={classes.container}>
      <Typography align="left" className={classes.text}>
        {name}
      </Typography>
    </div>
  );
}

StatusChip.propTypes = {
  name: Proptypes.string,
  isValorCrest: Proptypes.bool,
  isAvailable: Proptypes.bool,
  hideBorder: Proptypes.bool,
};

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(),
    borderRadius: 20,
    border: ({ isAvailable, hideBorder }) =>
      `1px ${hideBorder ? '' : 'solid'} ${
        isAvailable ? 'green' : theme.palette.primary.light
      }`,
  },
  text: {
    textTransform: 'uppercase',
    fontWeight: (props) => (props.isValorCrest ? 800 : 500),
    color: ({ isAvailable, isValorCrest }) => {
      if (isAvailable) {
        return 'green';
      }
      if (isValorCrest) {
        return 'white';
      }
      return theme.palette.primary.light;
    },
  },
}));
