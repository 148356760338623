import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { LeaderboardProptypes } from '../../../constants/propTypes';

export default function LeaderboardItem({ item }) {
  return (
    <>
      <Grid item xs={4} style={{ alignSelf: 'center' }}>
        <Typography
          align="center"
          style={{
            letterSpacing: '0px',
            fontFamily: 'Montserrat',
            fontWeight: 500,
          }}
        >
          {item.rank}
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ alignSelf: 'center' }}>
        <Typography
          align="center"
          style={{
            letterSpacing: '0px',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            color: '#ffffff',
          }}
        >
          {item.userName}
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ alignSelf: 'center' }}>
        <Typography
          align="center"
          style={{
            letterSpacing: '0px',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            color: '#ffffff',
          }}
        >
          {item.score}
        </Typography>
      </Grid>
    </>
  );
}

LeaderboardItem.propTypes = {
  item: LeaderboardProptypes,
};

LeaderboardItem.defaultProps = {
  item: null,
};
