import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './components/_pages/Home/dashboard';
import Guild from './components/_pages/Guild/Guild';
import Mission from './components/_pages/Mission/Mission';
import Collaborate from './components/_pages/Collaborate/Collaborate';
import DashboardContainer from './containers/Dashboard/DashboardContainer.connect';
import InventoryAvatars from './components/_pages/Inventory/Avatars';
import InventoryBuildings from './components/_pages/Inventory/Buildings';
import InventoryLands from './components/_pages/Inventory/Lands';
import InventoryConsumables from './components/_pages/Inventory/Consumables';
import InventoryWeapons from './components/_pages/Inventory/Weapons';
import LandDetail from './components/_pages/InventoryDetail/LandDetail';
import AvatarDetail from './components/_pages/InventoryDetail/AvatarDetail';
import MarketplaceBuildings from './components/_pages/Marketplace/Buildings';
import MarketplaceWeapons from './components/_pages/Marketplace/Weapons';
import Auth from './components/_pages/Auth/Auth';
import Account from './components/_pages/Account/Account';
import RecoverPassword from './components/_pages/RecoverPassword/RecoverPassword';
import Leaderboards from './components/_pages/Home/leaderboards';

function AppRoutes(props) {
  return (
    <DashboardContainer {...props}>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/leaderboard" component={Leaderboards} />
        {/* <Route exact path="/faq" component={FAQ} /> */}
        <Route exact path="/auth/register" component={Auth} />
        <Route exact path="/auth/login" component={Auth} />
        <Route exact path="/auth/login/:emailToken" component={Auth} />
        <Route
          exact
          path="/recover-password/:emailToken"
          component={RecoverPassword}
        />
        <Route exact path="/inventory/avatars" component={InventoryAvatars} />
        <Route
          exact
          path="/inventory/avatars/:tokenId"
          component={AvatarDetail}
        />
        <Route exact path="/inventory/lands" component={InventoryLands} />
        <Route path="/inventory/lands/:tokenId" component={LandDetail} />
        <Route
          exact
          path="/inventory/buildings"
          component={InventoryBuildings}
        />
        <Route
          exact
          path="/inventory/consumables"
          component={InventoryConsumables}
        />
        <Route exact path="/inventory/weapons" component={InventoryWeapons} />
        <Route
          exact
          path="/marketplace/buildings"
          component={MarketplaceBuildings}
        />
        <Route
          exact
          path="/marketplace/weapons"
          component={MarketplaceWeapons}
        />
        {/*
        <Route
          exact
          path="/marketplace/buildings/:tokenId"
          component={MarketplaceBuildingsDetail}
        />
        <Route
          exact
          path="/marketplace/weapons/:tokenId"
          component={MarketplaceWeaponsDetail}
        />
  <Route exact path="/marketplace/robots" component={MarketplaceRobots} /> */}
        <Route exact path="/guild" component={Guild} />
        <Route exact path="/mission" component={Mission} />
        <Route exact path="/collaborate" component={Collaborate} />
        <Route exact path="/account" component={Account} />
      </Switch>
    </DashboardContainer>
  );
}

export default AppRoutes;
