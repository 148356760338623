import React from 'react';
import Proptypes from 'prop-types';
import { Box } from '@material-ui/core';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import AccordionBox from '../../_atoms/AccordionBox/accordionBox';
import { useQuery } from '../../../hooks/useQuery';

export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = [...(result[key] || []), value];
  }
  return result;
}

export default function AccordionList({ titles, childrens, keys, queryParam }) {
  const queryParams = useQuery();
  const panel = queryParams?.get(queryParam);
  const history = useHistory();
  return (
    <>
      {titles.map((title, index) => (
        <React.Fragment key={`${title?.props?.children}`}>
          <Box mt={2} />
          <AccordionBox
            expanded={panel === keys[index]}
            onChange={() => {
              if (panel === keys[index]) {
                const newQueries = paramsToObject(queryParams);
                delete newQueries[queryParam];
                history.replace({ search: qs.stringify(newQueries) });
              } else {
                const newQueries = paramsToObject(queryParams);

                history.replace({
                  search: qs.stringify({
                    ...newQueries,
                    [queryParam]: keys[index],
                  }),
                });
              }
            }}
            summary={title}
          >
            {childrens[index]}
          </AccordionBox>
        </React.Fragment>
      ))}
    </>
  );
}

AccordionList.propTypes = {
  childrens: Proptypes.arrayOf(Proptypes.node),
  titles: Proptypes.arrayOf(Proptypes.node),
  keys: Proptypes.arrayOf(Proptypes.string),
  initialExtendedPanel: Proptypes.bool,
  queryParam: Proptypes.string,
};

AccordionList.defaultProps = {
  queryParam: 'panel',
};
