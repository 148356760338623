import React, { useMemo, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  Link,
  Divider,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import avatarGeneral from '../../../assets/onboarding/avatarGeneral.png';

import iconTwitter from '../../../assets/social/twitter.svg';
import iconDiscord from '../../../assets/social/discord.svg';

import logo from '../../../assets/logo.png';
import logoOxya from '../../../assets/logo-oxya.png';
import withHeader from './header.style';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';
import { parseAccount } from '../../../utils/string';
import { logout } from '../../../redux/actions/user';
import ResourcesHeadband from '../ResourcesHeadband/resourcesHeadband';
import ButtonWithIcon from '../../_molecules/ButtonWithIcon/button-with-icon';
import ButtonOxyaToken from '../../_molecules/ButtonOxyaToken/button-oxya-token';
import BlueBox from '../../_atoms/BlueBox/blueBox';
import { listNavigationHeader } from '../../../constants/header';

export default function Header() {
  const classes = withHeader();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const [openMenuUser, setOpenMenuUser] = React.useState(false);
  const [openMenuMobile, setOpenMenuMobile] = React.useState(false);

  const handleCloseMenuMobile = () => {
    setOpenMenuMobile(false);
  };

  const handleOpenMenuMobile = () => {
    setOpenMenuMobile(true);
  };

  const handleOpenMenuUser = () => {
    setOpenMenuUser(!openMenuUser);
  };

  const handleCloseMenuUser = () => {
    setOpenMenuUser(false);
  };

  const handleClickRegister = () => {
    history.push('/auth/register');
  };

  const handleClickLogin = () => {
    history.push('/auth/login');
  };

  const handleAccountPage = () => {
    history.push('/account');
  };

  const handleLogout = async () => {
    dispatch(logout(() => history.push('/')));
    setOpenMenuUser(false);
  };

  const handleClickLinktree = () => {
    window.open('https://lnkfi.re/OxyaOrigin', '_blank');
  };

  const handleClickWebsite = () => {
    window.open('https://oxyaorigin.com', '_blank');
  };

  const displayUser = useMemo(() => {
    if (user?.email) return user.email;
    if (user?.walletAddress) return parseAccount(user.walletAddress);
    return 'username';
  }, [user]);

  const dropdownRef = useRef(null);

  return (
    <AppBar className={classes.root} position="sticky">
      <Toolbar className={classes.toolbar}>
        <div className={classes.sectionDesktop}>
          <div className={classes.logoNav}>
            <HashLink smooth to="/" className={classes.linkLogo}>
              <img src={logo} alt="logo" />
            </HashLink>
          </div>
          <ResourcesHeadband />
          {!user ? (
            <div className={classes.signIn} ref={dropdownRef}>
              <ButtonPerso
                onClick={handleClickLogin}
                className={classes.btnSignIn}
              >
                Login
              </ButtonPerso>
              <ButtonPerso
                onClick={handleClickRegister}
                className={classes.btnRegister}
              >
                Register
              </ButtonPerso>
            </div>
          ) : (
            <ButtonWithIcon
              className={classes.btnUser}
              icon={<AccountCircleIcon />}
              text={displayUser}
              onClick={handleOpenMenuUser}
            />
          )}
          <ButtonPerso
            onClick={handleClickWebsite}
            className={classes.btnWebsite}
          >
            Website
          </ButtonPerso>
        </div>
        {user && openMenuUser && (
          <BlueBox
            className={classes.boxUserMenu}
            onMouseLeave={handleCloseMenuUser}
          >
            <Box className={classes.contentUserMenu}>
              <img
                src={user?.avatarUrl ? user.avatarUrl : avatarGeneral}
                alt={`avatar of ${displayUser}`}
              />
              <Box className={classes.detailsContentUserMenu}>
                <Typography className={classes.username}>
                  {user?.userName ? user.userName : 'No Username'}
                </Typography>
                <Typography className={classes.textUserMenu}>
                  {user?.email ? user.email : 'No email address connected'}
                </Typography>
                <Typography className={classes.textUserMenu}>
                  {user?.walletAddress
                    ? parseAccount(user.walletAddress)
                    : 'No wallet connected'}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.btnsUserMenu}>
              <ButtonPerso
                className={classes.btnAccountUserMenu}
                onClick={handleAccountPage}
              >
                My Account
              </ButtonPerso>
              <ButtonPerso className={classes.btnLogout} onClick={handleLogout}>
                Log out
              </ButtonPerso>
            </Box>
          </BlueBox>
        )}

        <Box className={classes.mobileSection}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            style={{ marginRight: '1rem' }}
            onClick={handleOpenMenuMobile}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          {!user ? (
            <div className={classes.signIn} ref={dropdownRef}>
              <ButtonPerso
                onClick={handleClickLogin}
                className={classes.btnSignIn}
              >
                Login
              </ButtonPerso>
              <ButtonPerso
                onClick={handleClickRegister}
                className={classes.btnRegister}
              >
                Register
              </ButtonPerso>
            </div>
          ) : (
            <ButtonWithIcon
              className={classes.btnUser}
              icon={<AccountCircleIcon />}
              text={displayUser}
              onClick={handleOpenMenuUser}
            />
          )}
        </Box>
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={openMenuMobile}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleCloseMenuMobile}
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ padding: '3rem 3rem 0' }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseMenuMobile}
              style={{ justifyContent: 'flex-end' }}
            >
              <CloseIcon style={{ color: 'white' }} fontSize="large" />
            </IconButton>
            <img src={logoOxya} alt="logo" className={classes.logoOxya} />
            <Box
              className={classes.wrapperLinksMenuMobile}
              display="flex"
              flexDirection="column"
              gridColumnGap="1rem"
              gridRowGap="1rem"
            >
              {listNavigationHeader.map((itemMenu) => (
                <button
                  className={classes.itemMenu}
                  type="button"
                  key={`menu-mobile-${itemMenu.key}`}
                  onClick={() => {
                    setOpenMenuMobile(false);
                    if (itemMenu.key === 'Marketplace') {
                      window.open(itemMenu.to, '_blank');
                    } else history.push(itemMenu.to);
                  }}
                >
                  <img
                    width={itemMenu.widthIcon}
                    height={itemMenu.heightIcon}
                    src={itemMenu.iconPathNonSelected}
                    alt={itemMenu.alt}
                  />
                  <Typography variant="body1">{itemMenu.text}</Typography>
                </button>
              ))}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            className={classes.wrapperBottomMenuMobile}
          >
            <Box
              display="flex"
              flexDirection="column"
              gridColumnGap="2rem"
              gridRowGap="2rem"
              justifyContent="center"
              alignItems="center"
            >
              <Box className={classes.social}>
                <Link
                  style={{ display: 'inline-flex' }}
                  href="https://twitter.com/OxyaOrigin"
                >
                  <img
                    className={classes.imgSocial}
                    src={iconDiscord}
                    width={33}
                    height={31}
                    alt="discord"
                  />
                </Link>
                <Link
                  href="https://twitter.com/OxyaOrigin"
                  style={{ display: 'inline-flex' }}
                >
                  <img
                    className={classes.imgSocial}
                    src={iconTwitter}
                    width={28}
                    height={29}
                    alt="twitter"
                  />
                </Link>
              </Box>
              <ButtonWithIcon
                className={classes.btnLinkTree}
                text="Our Links"
                onClick={handleClickLinktree}
                icon={
                  <Box className={classes.wrapperIcon}>
                    <ArrowRightAltIcon />
                  </Box>
                }
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box style={{ padding: '2rem' }}>
              <ButtonOxyaToken className={classes.btnToToken} />
            </Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {};

Header.defaultProps = {};
