import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { isEqual } from 'lodash';

import { useQuery } from './useQuery';
import { paramsToObject } from '../components/_molecules/AccordionList/accordionList';

export function useQueryFilters({ filters }) {
  const queryParams = useQuery();
  const history = useHistory();

  const [selectedFilters, setSelectedFilters] = useState(
    filters.reduce(
      (acc, value) => ({
        ...acc,
        [value]: queryParams.getAll(value),
      }),
      {},
    ),
  );

  useEffect(() => {
    const newFilters = filters.reduce(
      (acc, value) => ({
        ...acc,
        [value]: queryParams.getAll(value),
      }),
      {},
    );
    if (!isEqual(newFilters, selectedFilters)) {
      setSelectedFilters(newFilters);
    }
  }, [filters, queryParams]);

  const toggleFilter = useCallback(
    (key, name) => {
      const existingKeyFilters = queryParams.getAll(name) || [];
      const queries = paramsToObject(queryParams);

      if (existingKeyFilters.includes(key)) {
        history.replace({
          search: qs.stringify({
            ...queries,
            [name]: existingKeyFilters.filter((i) => i !== key),
          }),
        });
      } else {
        history.replace({
          search: qs.stringify({
            ...queries,
            [name]: [...existingKeyFilters, key],
          }),
        });
      }
    },
    [queryParams, selectedFilters],
  );

  return { selectedFilters, toggleFilter };
}
