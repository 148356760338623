export const selectUserAddress = (state) => state.user.currentAccount;

export const selectPreparingWithdrawals = (state) =>
  state.user.withdrawals
    .filter((w) => w.token.type === 'ETH' && w.rollup_status === 'included')
    .reduce((acc, w) => acc + Number(w.token.data.quantity._hex), 0) /
  1000000000000000000;

export const selectReadyWithdrawals = (state) =>
  state.user.withdrawals
    .filter(
      (w) =>
        w.token.type === 'ETH' &&
        w.rollup_status === 'confirmed' &&
        w.withdrawn_to_wallet === false,
    )
    .reduce((acc, w) => acc + Number(w.token.data.quantity._hex), 0) /
  1000000000000000000;

export const selectCompleteWithdrawals = (state) =>
  state.user.withdrawals
    .filter(
      (w) =>
        w.token.type === 'ETH' &&
        w.rollup_status === 'confirmed' &&
        w.withdrawn_to_wallet,
    )
    .reduce((acc, w) => acc + Number(w.token.data.quantity._hex), 0) /
  1000000000000000000;

/*
export const selectWithdrawableEthAmount = (state) =>
  state.user.withdrawals
    .filter((w) => w.token.type === 'ETH' && w.rollup_status === 'included')
    .reduce((acc, w) => acc + Number(w.token.data.quantity._hex), 0) /
    1000000000000000000 -
  state.user.withdrawals
    .filter((w) => w.token.type === 'ETH' && w.rollup_status === 'confirmed')
    .reduce((acc, w) => acc + Number(w.token.data.quantity._hex), 0) /
    1000000000000000000;
*/
