// Errors
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Success
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const GET_SUCCESS = 'GET_SUCCESS';

// Provider
export const CHANGE_PROVIDER = 'CHANGE_PROVIDER';
export const CHANGE_PROVIDER_ERROR = 'CHANGE_PROVIDER_ERROR';

// IMX
export const SETUP_LINK_SUCCESS = 'SETUP_LINK_SUCCESS';

// User
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const FETCH_LOGIN_ERROR = 'LOGIN_ERROR';

export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_FULFILLED = 'LOAD_USER_FULFILLED';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';

export const FETCH_GET_USER_BY_TOKEN = 'FETCH_GET_USER_BY_TOKEN';
export const FETCH_GET_USER_BY_TOKEN_FULFILLED =
  'FETCH_GET_USER_BY_TOKEN_FULFILLED';
export const FETCH_GET_USER_BY_TOKEN_ERROR = 'FETCH_GET_USER_BY_TOKEN_ERROR';

export const FETCH_SIGN_IN = 'FETCH_SIGN_IN';
export const FETCH_SIGN_IN_FULFILLED = 'FETCH_SIGN_IN_FULFILLED';
export const FETCH_SIGN_IN_ERROR = 'FETCH_SIGN_IN_ERROR';

export const FETCH_REGISTER_WEB2 = 'FETCH_REGISTER_WEB2';
export const FETCH_REGISTER_WEB2_FULFILLED = 'FETCH_REGISTER_WEB2_FULFILLED';
export const FETCH_REGISTER_WEB2_ERROR = 'FETCH_REGISTER_WEB2_ERROR';

export const FETCH_LOGIN_WEB3 = 'FETCH_LOGIN_WEB3';
export const FETCH_LOGIN_WEB3_FULFILLED = 'FETCH_LOGIN_WEB3_FULFILLED';
export const FETCH_LOGIN_WEB3_ERROR = 'FETCH_LOGIN_WEB3_ERROR';

export const FETCH_LOGIN_WEB2 = 'FETCH_LOGIN_WEB2';
export const FETCH_LOGIN_WEB2_FULFILLED = 'FETCH_LOGIN_WEB2_FULFILLED';
export const FETCH_LOGIN_WEB2_ERROR = 'FETCH_LOGIN_WEB2_ERROR';

export const LOAD_USER_BALANCE_SUCCESS = 'LOAD_USER_BALANCE_SUCCESS';

export const FETCH_UPDATE_PASSWORD = 'FETCH_UPDATE_PASSWORD';
export const FETCH_UPDATE_PASSWORD_FULFILLED =
  'FETCH_UPDATE_PASSWORD_FULFILLED';
export const FETCH_UPDATE_PASSWORD_ERROR = 'FETCH_UPDATE_PASSWORD_ERROR';

export const FETCH_UPDATE_USER = 'FETCH_UPDATE_USER';
export const FETCH_UPDATE_USER_FULFILLED = 'FETCH_UPDATE_USER_FULFILLED';
export const FETCH_UPDATE_USER_ERROR = 'FETCH_UPDATE_USER_ERROR';

export const FETCH_LINK_ACCOUNT = 'FETCH_LINK_ACCOUNT';
export const FETCH_LINK_ACCOUNT_FULFILLED = 'FETCH_LINK_ACCOUNT_FULFILLED';
export const FETCH_LINK_ACCOUNT_ERROR = 'FETCH_LINK_ACCOUNT_ERROR';

export const FETCH_LINK_EVM_WALLET = 'FETCH_LINK_EVM_WALLET';
export const FETCH_LINK_EVM_WALLET_FULFILLED =
  'FETCH_LINK_EVM_WALLET_FULFILLED';
export const FETCH_LINK_EVM_WALLET_ERROR = 'FETCH_LINK_EVM_WALLET_ERROR';

export const FETCH_LINK_SOLANA_WALLET = 'FETCH_LINK_SOLANA_WALLET';
export const FETCH_LINK_SOLANA_WALLET_FULFILLED =
  'FETCH_LINK_SOLANA_WALLET_FULFILLED';
export const FETCH_LINK_SOLANA_WALLET_ERROR = 'FETCH_LINK_SOLANA_WALLET_ERROR';

export const FETCH_LINK_ULTRA_WALLET = 'FETCH_LINK_ULTRA_WALLET';
export const FETCH_LINK_ULTRA_WALLET_FULFILLED =
  'FETCH_LINK_ULTRA_WALLET_FULFILLED';
export const FETCH_LINK_ULTRA_WALLET_ERROR = 'FETCH_LINK_ULTRA_WALLET_ERROR';

export const FETCH_LINK_WAX_WALLET = 'FETCH_LINK_WAX_WALLET';
export const FETCH_LINK_WAX_WALLET_FULFILLED =
  'FETCH_LINK_WAX_WALLET_FULFILLED';
export const FETCH_LINK_WAX_WALLET_ERROR = 'FETCH_LINK_WAX_WALLET_ERROR';

export const FETCH_UNLINK_EVM_WALLET = 'FETCH_UNLINK_EVM_WALLET';
export const FETCH_UNLINK_EVM_WALLET_FULFILLED =
  'FETCH_UNLINK_EVM_WALLET_FULFILLED';
export const FETCH_UNLINK_EVM_WALLET_ERROR = 'FETCH_UNLINK_EVM_WALLET_ERROR';

export const FETCH_UNLINK_OTHER_WALLET = 'FETCH_UNLINK_OTHER_WALLET';
export const FETCH_UNLINK_OTHER_WALLET_FULFILLED =
  'FETCH_UNLINK_OTHER_WALLET_FULFILLED';
export const FETCH_UNLINK_OTHER_WALLET_ERROR =
  'FETCH_UNLINK_OTHER_WALLET_ERROR';

export const FETCH_FORGET_PASSWORD = 'FETCH_FORGET_PASSWORD';
export const FETCH_FORGET_PASSWORD_FULFILLED =
  'FETCH_FORGET_PASSWORD_FULFILLED';
export const FETCH_FORGET_PASSWORD_ERROR = 'FETCH_FORGET_PASSWORD_ERROR';

export const FETCH_RECOVER_PASSWORD = 'FETCH_RECOVER_PASSWORD';
export const FETCH_RECOVER_PASSWORD_FULFILLED =
  'FETCH_RECOVER_PASSWORD_FULFILLED';
export const FETCH_RECOVER_PASSWORD_ERROR = 'FETCH_RECOVER_PASSWORD_ERROR';

export const FETCH_VALIDATE_EMAIL = 'FETCH_VALIDATE_EMAIL';
export const FETCH_VALIDATE_EMAIL_FULFILLED = 'FETCH_VALIDATE_EMAIL_FULFILLED';
export const FETCH_VALIDATE_EMAIL_ERROR = 'FETCH_VALIDATE_EMAIL_ERROR';

// ChainId
export const CHANGE_CHAIN_ID = 'CHANGE_CHAIN_ID';
export const CHANGE_CHAINID_ERROR = 'CHANGE_CHAINID_ERROR';

export const CHANGE_BUILDING_CONTRACT_WITH_SIGNER =
  'CHANGE_BUILDING_CONTRACT_WITHS_IGNER';

export const CHANGE_BUILDING_CONTRACT_WITH_SIGNER_ERROR =
  'CHANGE_BUILDING_CONTRACT_WITH_SIGNER_ERROR';

export const LOGOUT = 'LOGOUT';

// Buildings
export const FETCH_BUILDINGS = 'FETCH_BUILDINGS';
export const FETCH_BUILDINGS_FULFILLED = 'FETCH_BUILDINGS_FULFILLED';
export const FETCH_BUILDINGS_ERROR = 'FETCH_BUILDINGS_ERROR';
export const FETCH_USER_BUILDINGS_FULFILLED = 'FETCH_USER_BUILDINGS_FULFILLED';

export const FETCH_BULK_BUILDINGS = 'FETCH_BULK_BUILDINGS';
export const FETCH_BULK_BUILDINGS_FULFILLED = 'FETCH_BULK_BUILDINGS_FULFILLED';
export const FETCH_BULK_BUILDINGS_ERROR = 'FETCH_BULK_BUILDINGS_ERROR';

export const FETCH_BULK_WEAPONS = 'FETCH_BULK_WEAPONS';
export const FETCH_BULK_WEAPONS_FULFILLED = 'FETCH_BULK_WEAPONS_FULFILLED';
export const FETCH_BULK_WEAPONS_ERROR = 'FETCH_BULK_WEAPONS_ERROR';

export const FETCH_BUILDING = 'FETCH_BUILDING';
export const FETCH_BUILDING_FULFILLED = 'FETCH_BUILDING_FULFILLED';
export const FETCH_BUILDING_ERROR = 'FETCH_BUILDING_ERROR';

export const CHECK_IS_BUILDING_OWNER = 'CHECK_IS_BUILDING_OWNER';
export const CHECK_IS_BUILDING_OWNER_FULFILLED =
  'CHECK_IS_BUILDING_OWNER_FULFILLED';
export const CHECK_IS_BUILDING_OWNER_ERROR = 'CHECK_IS_BUILDING_OWNER_ERROR';

export const CHECK_IS_WEAPON_OWNER = 'CHECK_IS_WEAPON_OWNER';
export const CHECK_IS_WEAPON_OWNER_FULFILLED =
  'CHECK_IS_WEAPON_OWNER_FULFILLED';
export const CHECK_IS_WEAPON_OWNER_ERROR = 'CHECK_IS_WEAPON_OWNER_ERROR';

export const UPDATE_BUILDING = 'UPDATE_BUILDING';

export const LINK_BUILDING = 'LINK_BUILDING';
export const LINK_BUILDING_FULFILLED = 'LINK_BUILDING_FULFILLED';
export const LINK_BUILDING_ERROR = 'LINK_BUILDING_ERROR';

export const EMPTY_BUILDING = 'EMPTY_BUILDING';
export const EMPTY_BUILDING_FULFILLED = 'EMPTY_BUILDING_FULFILLED';
export const EMPTY_BUILDING_ERROR = 'EMPTY_BUILDING_ERROR';

export const CRAFT_BUILDING = 'CRAFT_BUILDING';
export const CRAFT_BUILDING_FULFILLED = 'CRAFT_BUILDING_FULFILLED';
export const CRAFT_BUILDING_ERROR = 'CRAFT_BUILDING_ERROR';

export const UPGRADE_BUILDING = 'UPGRADE_BUILDING';
export const UPGRADE_BUILDING_FULFILLED = 'UPGRADE_BUILDING_FULFILLED';
export const UPGRADE_BUILDING_ERROR = 'UPGRADE_BUILDING_ERROR';

export const CLAIM_CRAFT_BUILDING = 'CLAIM_CRAFT_BUILDING';
export const CLAIM_CRAFT_BUILDING_FULFILLED = 'CLAIM_CRAFT_BUILDING_FULFILLED';
export const CLAIM_CRAFT_BUILDING_ERROR = 'CLAIM_CRAFT_BUILDING_ERROR';

export const UNLINK_BUILDING = 'UNLINK_BUILDING';
export const UNLINK_BUILDING_FULFILLED = 'UNLINK_BUILDING_FULFILLED';
export const UNLINK_BUILDING_ERROR = 'UNLINK_BUILDING_ERROR';

export const START_COLLABORATE = 'START_COLLABORATE';
export const START_COLLABORATE_FULFILLED = 'START_COLLABORATE_FULFILLED';
export const START_COLLABORATE_ERROR = 'START_COLLABORATE_ERROR';

export const STOP_COLLABORATE = 'STOP_COLLABORATE';
export const STOP_COLLABORATE_FULFILLED = 'STOP_COLLABORATE_FULFILLED';
export const STOP_COLLABORATE_ERROR = 'STOP_COLLABORATE_ERROR';

export const WITHDRAW_COLLABORATE = 'WITHDRAW_COLLABORATE';
export const WITHDRAW_COLLABORATE_FULFILLED = 'WITHDRAW_COLLABORATE_FULFILLED';
export const WITHDRAW_COLLABORATE_ERROR = 'WITHDRAW_COLLABORATE_ERROR';

// Lands
export const FETCH_LANDS = 'FETCH_LANDS';
export const FETCH_LANDS_FULFILLED = 'FETCH_LANDS_FULFILLED';
export const FETCH_LANDS_ERROR = 'FETCH_LANDS_ERROR';

export const UPGRADE_LAND = 'UPGRADE_LAND';
export const UPGRADE_LAND_FULFILLED = 'UPGRADE_LAND_FULFILLED';
export const UPGRADE_LAND_ERROR = 'UPGRADE_LAND_ERROR';

export const CLAIM_UPGRADE_LAND = 'CLAIM_UPGRADE_LAND';
export const CLAIM_UPGRADE_LAND_FULFILLED = 'CLAIM_UPGRADE_LAND_FULFILLED';
export const CLAIM_UPGRADE_LAND_ERROR = 'CLAIM_UPGRADE_LAND_ERROR';

export const UPDATE_LAND = 'UPDATE_LAND';

// Rules
export const FETCH_RULES = 'FETCH_RULES';
export const FETCH_RULES_ERROR = 'FETCH_RULES_ERROR';
export const FETCH_RULES_FULFILLED = 'FETCH_RULES_FULFILLED';

// Avatars
export const FETCH_AVATARS = 'FETCH_AVATARS';
export const FETCH_AVATARS_ERROR = 'FETCH_AVATARS_ERROR';
export const FETCH_AVATARS_FULFILLED = 'FETCH_AVATARS_FULFILLED';

// ValorCrest
export const FETCH_VALOR_CREST = 'FETCH_VALOR_CREST';
export const FETCH_VALOR_CREST_FULFILLED = 'FETCH_VALOR_CREST_FULFILLED';
export const FETCH_VALOR_CREST_ERROR = 'FETCH_VALOR_CREST_ERROR';

// Weapons
export const FETCH_WEAPONS = 'FETCH_WEAPONS';
export const FETCH_WEAPONS_ERROR = 'FETCH_WEAPONS_ERROR';
export const FETCH_WEAPONS_FULFILLED = 'FETCH_WEAPONS_FULFILLED';
export const FETCH_USER_WEAPONS_FULFILLED = 'FETCH_USER_WEAPONS_FULFILLED';

export const FETCH_WEAPON = 'FETCH_WEAPON';
export const FETCH_WEAPON_FULFILLED = 'FETCH_WEAPON_FULFILLED';
export const FETCH_WEAPON_ERROR = 'FETCH_WEAPON_ERROR';

export const CRAFT_WEAPON = 'CRAFT_WEAPON';
export const CRAFT_WEAPON_FULFILLED = 'CRAFT_WEAPON_FULFILLED';
export const CRAFT_WEAPON_ERROR = 'CRAFT_WEAPON_ERROR';

export const UPGRADE_WEAPON = 'UPGRADE_WEAPON';
export const UPGRADE_WEAPON_FULFILLED = 'UPGRADE_WEAPON_FULFILLED';
export const UPGRADE_WEAPON_ERROR = 'UPGRADE_WEAPON_ERROR';

export const CLAIM_WEAPON = 'CLAIM_WEAPON';
export const CLAIM_WEAPON_FULFILLED = 'CLAIM_WEAPON_FULFILLED';
export const CLAIM_WEAPON_ERROR = 'CLAIM_WEAPON_ERROR';

// MARKETPLACE

// AllAsset IMX
export const FETCH_ALL_ASSETS_IMX_USER = 'FETCH_ALL_ASSETS_IMX_USER';
export const FETCH_ALL_ASSETS_IMX_USER_FULFILLED =
  'FETCH_ALL_ASSETS_IMX_USER_FULFILLED';
export const FETCH_ALL_ASSETS_IMX_USER_ERROR =
  'FETCH_ALL_ASSETS_IMX_USER_ERROR';

// Sell an item
export const SELL_ITEM = 'SELL_ITEM';
export const SELL_ITEM_FULFILLED = 'SELL_ITEM_FULFILLED';
export const SELL_ITEM_ERROR = 'SELL_ITEM_ERROR';

export const CANCEL_SELL_ORDER_FULFILLED = 'CANCEL_SELL_ORDER_FULFILLED';
export const CANCEL_SELL_ORDER = 'CANCEL_SELL_ORDER';

// Buy item
export const BUY_ITEM = 'BUY_ITEM';
export const BUY_ITEM_FULFILLED = 'BUY_ITEM_FULFILLED';
export const BUY_ITEM_ERROR = 'BUY_ITEM_ERROR';

// Get orders
export const FETCH_ORDERS_BULDINGS = 'FETCH_ORDERS_BULDINGS';
export const FETCH_ORDERS_BULDINGS_FULFILLED =
  'FETCH_ORDERS_BULDINGS_FULFILLED';
export const FETCH_ORDERS_BULDINGS_ERROR = 'FETCH_ORDERS_BULDINGS_ERROR';

// Get previous orders
export const FETCH_PREVIOUS_ORDERS_BULDINGS = 'FETCH_PREVIOUS_ORDERS_BULDINGS';
export const FETCH_PREVIOUS_ORDERS_BULDINGS_FULFILLED =
  'FETCH_PREVIOUS_ORDERS_BULDINGS_FULFILLED';
export const FETCH_PREVIOUS_ORDERS_BULDINGS_ERROR =
  'FETCH_PREVIOUS_ORDERS_BULDINGS_ERROR';

export const FETCH_PREVIOUS_ORDERS_WEAPONS = 'FETCH_PREVIOUS_ORDERS_WEAPONS';
export const FETCH_PREVIOUS_ORDERS_WEAPONS_FULFILLED =
  'FETCH_PREVIOUS_ORDERS_WEAPONS_FULFILLED';
export const FETCH_PREVIOUS_ORDERS_WEAPONS_ERROR =
  'FETCH_PREVIOUS_ORDERS_WEAPONS_ERROR';

export const FETCH_ORDERS_WEAPONS = 'FETCH_ORDERS_WEAPONS';
export const FETCH_ORDERS_WEAPONS_FULFILLED = 'FETCH_ORDERS_WEAPONS_FULFILLED';
export const FETCH_ORDERS_WEAPONS_ERROR = 'FETCH_ORDERS_WEAPONS_ERROR';

// Collaborate
export const FETCH_COLLABORATIVE_BUILDINGS = 'FETCH_COLLABORATIVE_BUILDINGS';
export const FETCH_COLLABORATIVE_BUILDINGS_FULFILLED =
  'FETCH_COLLABORATIVE_BUILDINGS_FULFILLED';
export const FETCH_COLLABORATIVE_BUILDINGS_ERROR =
  'FETCH_COLLABORATIVE_BUILDINGS_ERROR';

export const CRAFT_ASSET_COLLABORATE = 'CRAFT_ASSET_COLLABORATE';
export const CRAFT_ASSET_COLLABORATE_FULFILLED =
  'CRAFT_ASSET_COLLABORATE_FULFILLED';
export const CRAFT_ASSET_COLLABORATE_ERROR = 'CRAFT_ASSET_COLLABORATE_ERROR';

export const UPGRADE_WEAPON_COLLABORATE = 'UPGRADE_WEAPON_COLLABORATE';
export const UPGRADE_WEAPON_COLLABORATE_FULFILLED =
  'UPGRADE_WEAPON_COLLABORATE_FULFILLED';
export const UPGRADE_WEAPON_COLLABORATE_ERROR =
  'UPGRADE_WEAPON_COLLABORATE_ERROR';

export const UPDATE_COLLABORATIVE_BUILDING = 'UPDATE_COLLABORATIVE_BUILDING';
export const ADD_COLLABORATE = 'ADD_COLLABORATE';
export const REMOVE_COLLABORATE = 'REMOVE_COLLABORATE';
export const UPDATE_COLLABORATE = 'UPDATE_COLLABORATE';

// Leaderboard
export const FETCH_LEADERBOARD_GENESIS = 'FETCH_LEADERBOARD_GENESIS';
export const FETCH_LEADERBOARD_GENESIS_FULFILLED =
  'FETCH_LEADERBOARD_GENESIS_FULFILLED';
export const FETCH_LEADERBOARD_GENESIS_ERROR =
  'FETCH_LEADERBOARD_GENESIS_ERROR';

export const FETCH_LEADERBOARD_KEYS = 'FETCH_LEADERBOARD_KEYS';
export const FETCH_LEADERBOARD_KEYS_FULFILLED =
  'FETCH_LEADERBOARD_KEYS_FULFILLED';
export const FETCH_LEADERBOARD_KEYS_ERROR = 'FETCH_LEADERBOARD_KEYS_ERROR';

export const FETCH_LEADERBOARD_CITIZEN = 'FETCH_LEADERBOARD_CITIZEN';
export const FETCH_LEADERBOARD_CITIZEN_FULFILLED =
  'FETCH_LEADERBOARD_CITIZEN_FULFILLED';
export const FETCH_LEADERBOARD_CITIZEN_ERROR =
  'FETCH_LEADERBOARD_CITIZEN_ERROR';

export const FETCH_LEADERBOARD_GENESIS_USER = 'FETCH_LEADERBOARD_GENESIS_USER';
export const FETCH_LEADERBOARD_GENESIS_USER_FULFILLED =
  'FETCH_LEADERBOARD_GENESIS_USER_FULFILLED';
export const FETCH_LEADERBOARD_GENESIS_USER_ERROR =
  'FETCH_LEADERBOARD_GENESIS_USER_ERROR';

export const FETCH_LEADERBOARD_KEYS_USER = 'FETCH_LEADERBOARD_KEYS_USER';
export const FETCH_LEADERBOARD_KEYS_USER_FULFILLED =
  'FETCH_LEADERBOARD_KEYS_USER_FULFILLED';
export const FETCH_LEADERBOARD_KEYS_USER_ERROR =
  'FETCH_LEADERBOARD_KEYS_USER_ERROR';

export const FETCH_LEADERBOARD_CITIZEN_USER = 'FETCH_LEADERBOARD_CITIZEN_USER';
export const FETCH_LEADERBOARD_CITIZEN_USER_FULFILLED =
  'FETCH_LEADERBOARD_CITIZEN_USER_FULFILLED';
export const FETCH_LEADERBOARD_CITIZEN_USER_ERROR =
  'FETCH_LEADERBOARD_CITIZEN_USER_ERROR';

// Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Loading
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
