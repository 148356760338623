import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

export default function HomePlaceholder({ currentAccount, onLogin, text }) {
  const classes = useStyle();

  return (
    <div className={classes.global}>
      <div className={classes.container}>
        <div className={classes.topRightBox} />
        <div className={classes.topLeftBox} />
        <div className={classes.bottomRightBox} />
        <div className={classes.bottomLeftBox} />
        <Typography color="textPrimary" align="center" variant="h4">
          CONNECT YOUR WALLET TO SEE YOUR
          <Typography component="span" color="primary" variant="h4">
            {' '}
            {text}
          </Typography>
        </Typography>
        {!currentAccount && (
          <Box mt={3} display="flex" justifyContent="center">
            <ButtonPerso shrink onClick={onLogin}>
              LOGIN
            </ButtonPerso>
          </Box>
        )}
      </div>
    </div>
  );
}

HomePlaceholder.propTypes = {
  onLogin: Proptypes.func,
  currentAccount: Proptypes.string,
  text: Proptypes.string,
};

const useStyle = makeStyles((theme) => ({
  global: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90%',
    width: '95%',
    margin: 'auto',
  },
  container: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    position: 'relative',
    textTransform: 'uppercase',
    color: theme.palette.primary.light,
  },
  topRightBox: {
    position: 'absolute',
    top: -1,
    right: -1,
    borderTop: `2px solid ${theme.palette.primary.light}`,
    borderRight: `2px solid ${theme.palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  topLeftBox: {
    position: 'absolute',
    top: -1,
    left: -1,
    borderTop: `2px solid ${theme.palette.primary.light}`,
    borderLeft: `2px solid ${theme.palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  bottomRightBox: {
    position: 'absolute',
    bottom: -1,
    right: -1,
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    borderRight: `2px solid ${theme.palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  bottomLeftBox: {
    position: 'absolute',
    bottom: -1,
    left: -1,
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    borderLeft: `2px solid ${theme.palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
}));
