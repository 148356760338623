import React from 'react';
import Proptypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import BlueBox from '../../_atoms/BlueBox/blueBox';
import PanelTitle from '../../_atoms/PanelTitle/panelTitle';
import LeaderboardList from '../../_molecules/LeaderboardList/leaderboardList';
import { UserProptypes } from '../../../constants/propTypes';
import { isNotEmptyArray } from '../../../utils/array';

export default function Leaderboard({
  list,
  icon,
  title,
  description,
  className,
  user,
  rankUser,
  scoreUser,
  classNamePanelTitle,
}) {
  return (
    <BlueBox className={className}>
      <PanelTitle iconPath={icon} label={title} className={classNamePanelTitle}>
        {user && isNotEmptyArray(user.otherWalletsLinked) && (
          <Box>
            <Typography>{`My Rank ${rankUser}`}</Typography>
            <Typography>{`My Score ${scoreUser}`}</Typography>
          </Box>
        )}
      </PanelTitle>
      <Box sx={{ p: 3 }}>
        <Typography
          align="left"
          style={{
            fontFamily: 'Montserrat',
          }}
        >
          {description}
        </Typography>
        <Box mt={3} />

        <LeaderboardList list={list} />
      </Box>
    </BlueBox>
  );
}

Leaderboard.propTypes = {
  list: Proptypes.arrayOf(
    Proptypes.shape({
      userName: Proptypes.string,
      email: Proptypes.string,
      playfabId: Proptypes.string,
      rank: Proptypes.number,
      score: Proptypes.number,
    }),
  ),
  user: UserProptypes,
  title: Proptypes.string,
  icon: Proptypes.node,
  className: Proptypes.string,
  classNamePanelTitle: Proptypes.string,
  description: Proptypes.string,
  rankUser: Proptypes.number,
  scoreUser: Proptypes.number,
};

Leaderboard.defaultProps = {
  list: [],
  title: '',
  className: null,
  classNamePanelTitle: null,
  icon: null,
  description: '',
  user: null,
  rankUser: 0,
  scoreUser: 0,
};
