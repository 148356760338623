import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { LandProptypes } from '../../../constants/propTypes';
import BlueBox from '../../_atoms/BlueBox/blueBox';

export default function LandSummaryPanel({ land }) {
  const classes = useStyle();

  return (
    <BlueBox className={classes.properties}>
      <Typography variant="h2" align="left">
        Properties
      </Typography>
      <Box className={classes.wrapperContentProperties}>
        {land && (
          <>
            <Box className={classes.propertie}>
              <Typography
                align="left"
                variant="body1"
                className={classes.propertieTitle}
              >
                Planet
              </Typography>
              <Typography
                align="left"
                variant="body1"
                className={classes.propertieValue}
              >
                {land.planetName}
              </Typography>
            </Box>
            <Box className={classes.propertie}>
              <Typography
                align="left"
                variant="body1"
                className={classes.propertieTitle}
              >
                Size
              </Typography>
              <Typography
                align="left"
                variant="body1"
                className={classes.propertieValue}
              >
                {land.size}
              </Typography>
            </Box>
          </>
        )}
        {land &&
          Object.entries(land?.attributes).map(([key, value]) => (
            <Box className={classes.propertie}>
              <Typography
                align="left"
                variant="body1"
                className={classes.propertieTitle}
              >
                {key}
              </Typography>
              <Typography
                align="left"
                variant="body1"
                className={classes.propertieValue}
              >
                {value}
              </Typography>
            </Box>
          ))}
      </Box>
    </BlueBox>
  );
}
LandSummaryPanel.propTypes = {
  land: LandProptypes,
};
const useStyle = makeStyles(({ palette, breakpoints }) => ({
  title: {
    color: 'white',
  },
  properties: {
    height: 'initial',
    marginTop: '2rem',
    padding: '2rem 1rem',
    '& h2': {
      textTransform: 'capitalize',
      fontWeight: 700,
      color: palette.primary.light,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.6rem',
    },
    [breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  wrapperContentProperties: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2rem',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  propertie: {
    width: '45%',
  },
  propertieTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    color: palette.primary.light,
    textTransform: 'uppercase',
  },
  propertieValue: {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
  },
}));
