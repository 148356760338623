import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Buffer } from 'buffer';

import '@rainbow-me/rainbowkit/styles.css';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';

import merge from 'lodash.merge';

import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme,
} from '@rainbow-me/rainbowkit';

import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';

import { mainnet, bsc } from 'wagmi/chains';

import Navigation from '../../components/_organisms/Navigation/navigation';
import bg from '../../assets/stars.jpg';
import Header from '../../components/_organisms/Header/header';
import NavBar from '../../components/_organisms/NavBar/navBar';
import SnackBarBottom from '../../components/_atoms/SnackBarBottom/snack-bar-bottom';
import { clearErrors } from '../../redux/actions/errorAction';
import { logout } from '../../redux/actions/user';
import NavSocialMedia from '../../components/_molecules/NavSocialMedia/nav-social-media';
import {
  listHomeHeader,
  listInventoryHeader,
  listMarketplaceBuy,
  listMarketplaceSell,
} from '../../constants/header';
import { clearSuccess } from '../../redux/actions/successAction';
import { useQueryWithQueryString } from '../../hooks/useQuery';
import { saveState } from '../../store/localStorage';

const TRACKIG_GA4ID = 'G-DN716NQJPC';
ReactGA.initialize(TRACKIG_GA4ID);

window.Buffer = Buffer;

export default function DashboardContainer({ children, loadUser }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error.msg);
  const success = useSelector((state) => state.success.msg);
  const queryParams = useQueryWithQueryString();

  const accessToken = useSelector((state) => state.user.accessToken);

  const projectId = process.env.REACT_APP_WALLETCONNECT_ID;

  const { chains, publicClient } = configureChains(
    [mainnet, bsc],
    [
      infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID }),
      jsonRpcProvider({
        rpc: () => ({
          http: 'https://warmhearted-prettiest-sheet.bsc-testnet.quiknode.pro/c9beb5c9875c059953cb43c7395e904c20253263',
        }),
      }),
    ],
  );

  const { connectors } = getDefaultWallets({
    appName: 'Oxya Origin Dashboard',
    projectId,
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: false,
    connectors,
    publicClient,
  });

  const myTheme = merge(midnightTheme(), {
    colors: {
      accentColor: '#0fbeff',
      accentColorForeground: '#ffffff',
    },
    fonts: {
      body: 'Montserrat, sans-serif',
    },
  });

  const history = useHistory();
  useEffect(() => {
    let timer = null;
    if (error) {
      timer = setTimeout(() => dispatch(clearErrors()), 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(async () => {
    const accessTokenParams = queryParams?.accessToken;
    if (accessToken) {
      await loadUser();
    } else if (accessTokenParams) {
      saveState('accessToken', accessTokenParams);
      saveState('roles', ['User']);
      loadUser(
        () => {},
        () => {
          logout();
        },
      );
    }
  }, [accessToken, queryParams]);

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} theme={myTheme}>
        <div className={classes.app}>
          <Header />
          <div className={classes.inner}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{ borderRight: '0.5px solid #19283B' }}
            >
              <Navigation />
              <NavSocialMedia />
            </Box>

            <div className={classes.right}>
              {(history.location.pathname === '/' ||
                // history.location.pathname === '/faq' ||
                history.location.pathname === '/leaderboard') && (
                <NavBar listHeader={listHomeHeader} />
              )}
              {history.location.pathname.includes('inventory') && (
                <NavBar listHeader={listInventoryHeader} />
              )}
              {history.location.pathname.includes('marketplace/sell') && (
                <NavBar listHeader={listMarketplaceSell} />
              )}
              {history.location.pathname.includes('marketplace') && (
                <NavBar listHeader={listMarketplaceBuy} />
              )}
              <div className={classes.content}>{children}</div>
            </div>
          </div>
          <SnackBarBottom
            text={error}
            open={!!error}
            variant="error"
            onClose={() => dispatch(clearErrors())}
          />
          <SnackBarBottom
            text={success}
            open={!!success}
            variant="success"
            onClose={() => dispatch(clearSuccess())}
          />
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

DashboardContainer.propTypes = {
  children: Proptypes.node,
  loadUser: Proptypes.func,
};

DashboardContainer.defaultProps = {
  children: null,
  loadUser: () => {},
};

const useStyle = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.2em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: 10,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  content: {
    flex: 2,
    overflowY: 'scroll',
    marginBottom: theme.spacing(3),
  },
  right: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inner: {
    display: 'flex',
    height: 'calc(100% - 80px)',
    maxHeight: 'calc(100% - 80px)',
  },
  app: {
    overflow: 'hidden',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    backgroundColor: 'black',
    position: 'relative',
    zIndex: 1,
  },
}));
