import React from 'react';
import { makeStyles, Link, Box, Typography } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { heightNavBar, widthElemNavBar } from '../../../constants/size';

import { listNavigationHeader } from '../../../constants/header';

export default function Navigation() {
  const classes = useStyle();
  const history = useHistory();

  return (
    <nav className={cx(classes.container, classes.rootDesktop)}>
      {listNavigationHeader.map((item) => {
        const isCurrentPath =
          item.to === '/'
            ? history.location.pathname === item.to ||
              // history.location.pathname === '/faq' ||
              history.location.pathname === '/leaderboard'
            : history.location.pathname.includes(item.key.toLocaleLowerCase());

        return (
          <Link
            onClick={() => {
              if (item.key === 'Marketplace') {
                window.open(item.to, '_blank');
              }
            }}
            component={RouterLink}
            to={item.key !== 'Marketplace' && item.to}
            key={item.to}
            variant="h5"
            underline="none"
            className={cx([
              classes.link,
              isCurrentPath ? classes.linkSelected : null,
            ])}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              className={cx(
                classes.item,
                isCurrentPath ? classes.selected : null,
              )}
            >
              {isCurrentPath && <div className={classes.backLight} />}
              <img
                src={isCurrentPath ? item.iconPath : item.iconPathNonSelected}
                alt="icon"
              />
              <Typography variant="h5" style={{ marginLeft: 8 }}>
                {item.text}
              </Typography>
            </Box>
          </Link>
        );
      })}
    </nav>
  );
}

const useStyle = makeStyles((theme) => ({
  iconPath: {
    marginRight: theme.spacing(),
  },
  container: {
    borderTop: `0.5px solid ${theme.palette.text.blueDark}`,
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    height: `calc(7 * ${heightNavBar})`,
    '& h5': {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  rootDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    },
  },
  item: {
    color: theme.palette.primary.main,
    marginRight: 'auto',
  },
  link: {
    borderBottom: `0.5px solid ${theme.palette.text.blueDark}`,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    position: 'relative',
    '&:hover': {
      '& h5': {
        color: theme.palette.primary.light,
      },
      '& svg': {
        color: theme.palette.primary.light,
      },
    },
  },
  linkSelected: {
    borderTop: '0.5px solid',
    borderColor: theme.palette.primary.light,
  },
  selected: {
    color: theme.palette.primary.light,
  },
  backLight: {
    background: `transparent linear-gradient(90deg, ${theme.palette.primary.light} 0%, #00324F00 100%) 0% 0% no-repeat padding-box`,
    boxShadow: '0px 12px 9px #000000',
    mixBlendMode: 'lighten',
    opacity: 0.3,
    width: widthElemNavBar,
    position: 'absolute',
    height: heightNavBar,
    top: 0,
    left: 0,
  },
}));
